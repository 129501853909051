import React from 'react'
import { BACWARD_ARROW } from '../Assets/icons/icons';
import { useNavigate } from 'react-router-dom';

export const CustomInput = (props) => {
  const { id, name, type, value, placeholder, ...attributes } = props;
  return (
    <input
      id={id}
      type={type}
      value={value}
      name={name}
      placeholder={placeholder}
      className="w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow placholder:text-slate-400 text-sm h-9"
      {...attributes}
    />
  )
}

export const CustomDropDown = (props) => {
  const { id, name, type, value, options, children, ...attributes } = props;
  return (
    <select
      id={id}
      value={value}
      name={name}
      className='w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow placholder:text-slate-400 text-sm h-9'
      {...attributes}>
      {children}
      {/* {options.length > 0 && options.map((opt) => (
        <option value={opt.value}>{opt.label}</option>
      ))} */}
    </select>
  )
}

export const CustomLabel = (props) => {
  const { htmlFor, required, subHeading, children } = props
  return (
    <label htmlFor={htmlFor} className='text-sm font-semibold'>
      {children}{!!required && <span className='text-red-500 text-sm'>*</span>}
      {!!subHeading && <span className='text-xs font-light'>{subHeading}</span>}
    </label>
  )
}

export const ErrorState = (props) => {
  const { err, errMsg } = props;
  return (
    <>
      {!!err &&
        <span className="text-red-600 text-sm">
          {errMsg}
        </span>}
    </>
  )
}

export const BackButton = ({ path }) => {
  const navigate = useNavigate();
  return (
    <div className="w-[36px] h-[36px] mr-8 rounded-[4px] items-center border border-[#BABFC3] flex justify-center cursor-pointer" onClick={() => navigate(path)}>
      <BACWARD_ARROW />
    </div>
  )
}