import React, { useContext } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { DataContext } from '../../context/Datastate';

const EnableModal = ({ item, setEnableModal, apiFunction }) => {
    const { updateVendoEnableApi } = useContext(DataContext)
    const handleClose = () => {
        setEnableModal(false);
    }

    return (
        <div className="absolute w-full h-screen bg-[#00000090] z-[5]">

            <div className='p-6 absolute top-1/2 left-1/2 -translate-x-1/2 z-[6] -translate-y-1/2 rounded-md bg-white'>

                <div className="bg-white p-4 w-[400px] rounded z-10">
                    <AiOutlineCloseCircle className='close cursor-pointer absolute right-5 top-5 text-[20px] text-red-600 z-[99]' onClick={() => handleClose()} />
                    <div className=" flex gap-4">
                        <span className="font-bold text-[18px] md:text-[17px] sm:text-[17px] pb-1">
                            Confirm?
                        </span>
                    </div>
                    <div className="py-3">
                        <span className="text-[#475467] text-[14px] sm:text-[13px] xs:text-[12px]">
                            Are you sure you want to {item?.isActive ? "disable" : "enable"}?
                        </span>
                    </div>
                    <div className="buttons flex gap-3 mt-6 xs:mt-4 sm:mt-5  w-full">
                        <button
                            className="rounded-md border border-[#D0D5DD] font-medium md:text-[13px] sm:text-[12px] xs:text-[11px] flex-1 p-[.4rem_1rem]"
                            onClick={() => {
                                setEnableModal(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="bg-theme-color flex-1 rounded-md font-medium md:text-[13px] sm:text-[12px] xs:text-[11px] text-white p-[.4rem_1rem]"
                            onClick={() => {
                                // handleEnable(item)
                                apiFunction(item)
                            }}
                        >
                            {item?.isActive ? "Disable" : "Enable"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnableModal
