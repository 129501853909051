import React, { useContext, useEffect, useState } from "react";
import {
  FaThLarge,
  FaBlackTie,
  FaUsers,
  FaCaretUp,
  FaSuperpowers,
  FaCircle,
  FaCity,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import Logo from "../Assets/logo.png";
import { DataContext } from "../../context/Datastate";
import LogoutModal from "../LogoutModal/LogoutModal";
import { FiLogOut, FiUser } from "react-icons/fi";
import { FaCaretDown, FaHouseUser,FaEnvelope } from "react-icons/fa";
// import { FaEnvelope } from "react-icons/fa";

function Sidebar() {
  const {
    logout,
    setLogout,
    checkLogin,
    setCheckLogin,
    addPublishApi,
    allCount,
    disableSave,
    AllCountApi,
    isLogin,
    setisLogin,
    vendor,
    job,
    candidate,
  } = useContext(DataContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const location = useLocation();
  useEffect(() => { }, [location]);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  useEffect(() => {
    isLogin && AllCountApi();
  }, []);

  return (
    <>
      {logout ? <LogoutModal /> : ""}

      {checkLogin ? (
        // <div className="w-1/6 max-w-[210px] h-[100vh] shrink-0 bg-white border-r-2 ">
        <div className="w-1/7 shrink-0 bg-white border-r-2 ">
          <div className="px-4">
            <img className="w-[180px] py-5 " src={Logo} alt="" />
          </div>
          {/* <div className="flex items-center justify-center  gap-4 pl- pr-5 pt-5 pb-6">
            <img className="w-[25px] " src={Sidebar1} alt="sidebar"></img>
            <h2 className="text-black font-bold font-poppins lg:text-lg 2xl:text-2xl text-xl ">Event Web</h2>
            <span ><FaArrowCircleLeft/></span>
          </div> */}
          {/* <ul className=" flex flex-col gap-[4px] scrollbar-none px-4"> */}
          <ul className="flex flex-col gap-[4px] h-[85vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-white  px-4">
            <li
              className={`flex items-center hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl ${location.pathname === "/dashboard"
                ? "bg-theme-color text-white active"
                : "text-black"
                } `}
            >
              <Link className="w-full  p-3" to="/dashboard">
                <div className="flex items-center text-sm">
                  <FaThLarge />
                  <h2 className=" text-md font-normal pl-2 ">Dashboard</h2>
                </div>
              </Link>
            </li>


            <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/vendors/list" ||
                location.pathname ==
                "/vendors/update/" + location.pathname.split("/").pop() ||
                location.pathname === "/vendor_detail" ||
                //  location.pathname==="/import_vendors" ||
                location.pathname === "/vendors/add"
                ? "bg-theme-color text-white active"
                : "text-black"
                }   `}
            >
              <Link className="w-full p-3" to="/vendors/list">
                <div className="flex items-center  text-sm">
                  <FiUser
                    className={`text-xl transition-all rounded-xl group-hover:text-theme-color ${location.pathname === "/vendors/list" ||
                      location.pathname ==
                      "/vendors/update/" +
                      location.pathname.split("/").pop() ||
                      location.pathname === "/vendor_detail" ||
                      // ||  location.pathname==="/import_vendors"
                      location.pathname === "/vendors/add"
                      ? "bg-theme-color text-white active group-hover:text-white"
                      : "text-black"
                      } `}
                  />
                  <h2 className=" text-md font-normal pl-2">
                    Vendors<span>({allCount?.totalVendor})</span>
                  </h2>
                </div>
              </Link>
            </li>
            <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname.startsWith("/jobs")
                ? "bg-theme-color text-white active"
                : "text-black"
                }   `}
            >
              <Link className="w-full p-3" to="/jobs/list">
                <div className="flex items-center  text-sm">
                  <FaBlackTie
                    className={`text-xl transition-all rounded-xl group-hover:text-theme-color ${location.pathname.startsWith("/jobs")
                      ? "theme-color text-white active group-hover:text-theme-color"
                      : "text-black"
                      } `}
                  />
                  <h2 className=" text-md font-normal pl-2">
                    Jobs<span>({allCount?.totalJob})</span>
                  </h2>
                </div>
              </Link>
            </li>
            <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname.startsWith("/all_candidates")
                ? "bg-theme-color text-white active"
                : "text-black"
                }   `}
            >
              <Link className="w-full p-3" to="/all_candidates/list">
                <div className="flex items-center  text-sm">
                  <FaUsers
                    className={`text-xl transition-all rounded-xl group-hover:text-theme-color ${location.pathname.startsWith("/all_candidates")
                      ? "theme-color text-white active group-hover:text-theme-color"
                      : "text-black"
                      } `}
                  />
                  <h2 className=" text-md pl-2 font-normal ">
                    All Candidates<span>({allCount?.totalCandidate})</span>
                  </h2>
                </div>
              </Link>
            </li>

            <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/applied-jobs"
                ? "bg-theme-color text-white active"
                : "text-black"
                }   `}
            >
              <Link className="w-full p-3" to="/applied-jobs">
                <div className="flex items-center  text-sm">
                  <FaUsers
                    className={`text-xl transition-all rounded-xl group-hover:text-theme-color ${location.pathname === "/applied-jobs"
                      ? "theme-color text-white active group-hover:text-theme-color"
                      : "text-black"
                      } `}
                  />
                  <h2 className=" text-md pl-2 font-normal ">
                    Applied Jobs<span>({allCount?.totalAppliedJob})</span>
                  </h2>
                </div>
              </Link>
            </li>

            {/* <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/recruiterlist"
                ? "bg-theme-color text-white active"
                : "text-black"
                }   `}
            >
              <Link className="w-full p-3" to="/recruiterlist">
                <div className="flex items-center  text-sm">
                  <FaUsers
                    className={`text-xl transition-all rounded-xl group-hover:text-theme-color ${location.pathname === "/recruiterlist"
                      ? "theme-color text-white active group-hover:text-theme-color"
                      : "text-black"
                      } `}
                  />
                  <h2 className=" text-md pl-2 font-normal ">
                    Recruiter<span>({allCount?.totalRecruiter})</span>
                  </h2>
                </div>
              </Link>
            </li> */}
            {/* <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/inhouselist"
                ? "bg-theme-color text-white active"
                : "text-black"
                }   `}
            >
              <Link className="w-full p-3" to="/inhouselist">
                <div className="flex items-center  text-sm">
                  <FaHouseUser
                    className={`text-xl transition-all rounded-xl group-hover:text-theme-color ${location.pathname === "/inhouselist"
                      ? "theme-color text-white active group-hover:text-theme-color"
                      : "text-black"
                      } `}
                  />
                  <h2 className=" text-md pl-2 font-normal ">
                    In House Jobs<span>({allCount?.totalInHouseJobs})</span>
                  </h2>
                </div>
              </Link>
            </li> */}
            <li
              className={`relative group ${dropdownOpen ? "active" : ""}`}
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <div className="flex items-center p-3 hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-xl cursor-pointer">
                <div className="flex items-center  text-sm">
                  <FaSuperpowers
                    className={`text-xl transition-all rounded-xl ${location.pathname === "/technology_list" ||
                      location.pathname ===
                      "/update_technology/" +
                      location.pathname.split("/").pop() ||
                      location.pathname === "/technology_detail" ||
                      // location.pathname === "/import_job" ||
                      location.pathname === "/add_technology"
                      ? "theme-color text-black active group-hover:text-black"
                      : "text-black"
                      } `}
                  />
                  <h2 className=" text-md font-normal pl-2">Master</h2>
                </div>
                {!dropdownOpen ? (
                  <FaCaretDown className="text-sm" />
                ) : (
                  <FaCaretUp className="text-sm" />
                )}
              </div>
            </li>
            {dropdownOpen && (
              <ul className="  flex flex-col w-full pl-6 gap-y-2">
                <li
                  className={`text-black pl-2 flex items-center rounded-md w-full ${location.pathname === "/technology_list"
                    ? "bg-theme-color text-white hover:bg-theme-color hover:text-white"
                    : "hover:bg-theme-light-color hover:text-theme-color"
                    }`}
                >
                  <FaCircle
                    className={`pl-1 text-[12px] transition-all rounded-xl group-hover:text-theme-color ${location.pathname === "/technology_list"
                      ? "theme-color text-white active group-hover:text-white"
                      : "text-black"
                      } `}
                  />
                  <Link to="technology_list" className="w-full text-sm p-2">
                    Technology
                  </Link>
                </li>
                <li
                  className={`text-black pl-2 flex items-center rounded-md  w-full  ${location.pathname === "/status-list"
                    ? "bg-theme-color text-white hover:bg-theme-color hover:text-white"
                    : "hover:bg-theme-light-color hover:text-theme-color"
                    }`}
                >
                  <FaCircle className="pl-1 text-[12px] " />
                  <Link className="w-full text-sm p-2" to="/status-list">
                    Status
                  </Link>
                </li>
                <li
                  className={`text-black pl-2 flex items-center rounded-md  w-full  ${location.pathname === "/city_list"
                    ? "bg-theme-color text-white hover:bg-theme-color hover:text-white"
                    : "hover:bg-theme-light-color hover:text-theme-color"
                    }`}
                >
                  <FaCircle className="pl-1 text-[12px] " />
                  <Link className="w-full text-sm p-2" to="/city_list">
                    Cities
                  </Link>
                </li>
                <li
                  className={`text-black pl-2 flex items-center rounded-md  w-full  ${location.pathname === "/client_list"
                    ? "bg-theme-color text-white hover:bg-theme-color hover:text-white"
                    : "hover:bg-theme-light-color hover:text-theme-color"
                    }`}
                >
                  <FaCircle className="pl-1 text-[12px] " />
                  <Link className="w-full text-sm p-2" to="/client_list">
                    Client
                  </Link>
                </li>
                <li
                  className={`text-black pl-2 flex items-center rounded-md  w-full  ${location.pathname === "/subadmin-list"
                    ? "bg-theme-color text-white hover:bg-theme-color hover:text-white"
                    : "hover:bg-theme-light-color hover:text-theme-color"
                    }`}
                >
                  <FaCircle className="pl-1 text-[12px] " />
                  <Link className="w-full text-sm p-2" to="/subadmin-list">
                    Sub-Admin
                  </Link>
                </li>
              </ul>
            )}
            {/* / */}
            <li
              className={`flex items-center hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl ${location.pathname === "/email"
                ? "bg-theme-color text-white active"
                : "text-black"
                } `}
            >
              <Link className="w-full  p-3" to="/email">
                <div className="flex items-center text-sm">
                  <FaEnvelope />
                  <h2 className=" text-md font-normal pl-2 ">Send Emails</h2>
                </div>
              </Link>
            </li>
            {/*  */}
            {/* <li
              className={` mt-auto flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                location.pathname === "/user-list"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              }   `}
            >
              <Link className="w-full p-3" to="/user-list">
                <div className="flex items-center  text-sm">
                  <FiUser
                    className={`text-xl transition-all rounded-xl group-hover:text-theme-color ${
                      location.pathname === "/user-list"
                        ? "theme-color text-white active group-hover:text-white"
                        : "text-black"
                    } `}
                  />
                  <h2 className=" text-md pl-2 font-normal ">
                    Create User<span>({allCount?.totalInHouseJobs})</span>
                  </h2>
                </div>
              </Link>
            </li> */}
            <li
              className={`flex items-center  hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/logout"
                ? "bg-theme-color text-white active"
                : "text-black"
                }   `}
            >
              <Link
                className="w-full p-3"
                onClick={() => {
                  setLogout(true);
                }}
              >
                <div className="flex items-center w-full  text-sm">
                  <FiLogOut className="text-xl" />
                  <h2 className=" text-md text- px-5">Logout</h2>
                </div>
              </Link>
            </li>
            {/* <li className="text-center py-2">
              <button className="text-sm py-3 text-white px-14 rounded-3xl bg-theme-color font-normal font-theme-font" onClick={()=>{ if (!disableSave) addPublishApi(true)}}>Publish</button>
            </li> */}
          </ul>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Sidebar;
