import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { FiTrash, FiEdit2, FiEye } from "react-icons/fi";
import { DataContext } from "../../../context/Datastate";
import GlobalLoader from "../../GlobalLoader/GlobalLoader";
import DeleteModal from "../../DeleteModal/DeleteModal";
// import Pagination from "../Pagination/Pagination";
import Loader from "../../Loader/Loader";
import NoData from "../../NoData/NoData";

const SubadminList = () => {
  const {
    loader,
    setSubAdmin,
    subAdmin,
    globalLoader,
    subAdminPaginationApi,
    totalCount,
    pageCount,
    deleteBtn,
    setDeleteBtn,
    setDeleteBtnId,
    deleteBtnId,
    deleteSubAdminApi,
  } = useContext(DataContext);

  const limit = 50;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);

  useEffect(() => {
    subAdminPaginationApi();
  }, []);

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...subAdmin].sort((a, b) => {
      const valueA = (a[property] || "").toString();
      const valueB = (b[property] || "").toString();
      const comparison = valueA.localeCompare(valueB);
      return isAscending ? comparison : -comparison;
    });

    setSubAdmin(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };

  return (
    <>
      {deleteBtn ? (
        <DeleteModal deleteApi={deleteSubAdminApi} deleteId={deleteBtnId} />
      ) : (
        ""
      )}
      <div className="flex w-full">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
          <div className="pt-3 flex justify-between itms-start">
            <h1 className="text-xl text-[#202223] pt-2 font-semibold">
              Sub-Admin List
            </h1>
            <div className="flex space-x-2 justify-between pt-2 items-start flex-wrap">
              <div className="flex gap-1">
                <Link to="/add_subadmin">
                  <button className="border shadow-btnshd bg-theme-color text-white   px-4 py-2 rounded text-sm">
                    Add New SubAdmin
                  </button>
                </Link>
              </div>
              {/* <div className="float-right flex">
                <FaSearch className="search-icon relative top-[0.7rem] left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div> */}
            </div>
          </div>
          {/*--------- table list--------- */}

          {loader ? (
            <Loader />
          ) : subAdmin?.length > 0 ? (
            <>
              <div className="h-[75vh] relative overflow-y-auto">
                <table className="w-full table-auto overflow-hidden border-collapse bg-white shadow-md  rounded-lg  ">
                  <thead className="cursor-pointer sticky top-0 z-[4]">
                    <tr className="  bg-theme-light text-theme-color text-xs font-normal ">
                      <th className=" px-4 py-2 flex items-center">
                        <div className="flex items-center gap-4">
                          <p>Name</p>
                          <div className="px-2 ">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "name",
                                  "asc"
                                )}`}
                                onClick={() => sortData("name")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "name",
                                  "desc"
                                )}`}
                                onClick={() => sortData("name")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Email</p>
                          <div className=" ">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "email",
                                  "asc"
                                )}`}
                                onClick={() => sortData("email")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "email",
                                  "desc"
                                )}`}
                                onClick={() => sortData("email")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4">
                        <div className="flex items-center gap-4">
                          <p>Phone number</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "phone",
                                  "asc"
                                )}`}
                                onClick={() => sortData("phone")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "phone",
                                  "desc"
                                )}`}
                                onClick={() => sortData("phone")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th className=" px-4 text-center rounded-tr-lg">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                    {subAdmin?.map((item) => (
                      <tr className="border-t text-left text-[14px] text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  cursor-pointer">
                        <td className="px-4 py-2 text-start">{item?.name}</td>
                        <td className="px-4 py-2">{item?.email}</td>
                        <td className="px-4 py-2">{item?.phone}</td>
                        <td className="px-4 py-2">
                          <div className="flex items-center  justify-center  gap-2 text-black ">
                            <Link to={`/update_subadmin/${item?._id}`}>
                              <FiEdit2 className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] " />
                            </Link>
                            <FiTrash
                              className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                              onClick={() => {
                                setDeleteBtn(true);
                                setDeleteBtnId(item?._id);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* )} */}
              {/* <div className="flex justify-between items-center ">
            <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${pageCount === 1 ? totalCount : secondResultCount} of ${totalCount} results`}</span>
            <Pagination
              firstResultCount={firstResultCount}
              setFirstResultCount={setFirstResultCount}
              secondResultCount={secondResultCount}
              setSecondResultCount={setSecondResultCount}
              limit={limit}
              api={subAdminPaginationApi}
            />
          </div> */}

              <div>
                <span className="font-[150px"></span>
              </div>
            </>
          ) : null}

          {!loader && !subAdmin && <NoData />}
        </div>
      </div>
    </>
  );
};

export default SubadminList;
