import React from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';



const VendorModal = ({ item, setVendorModal }) => {
   
    const handleClose = () => {
        setVendorModal(false);
    }

    return (
        <div className="absolute w-full h-screen bg-[#00000090] z-[5]">

            <div className='w-2/3 p-12 absolute top-1/2 left-1/2 -translate-x-1/2 z-[6] -translate-y-1/2 rounded-md bg-white'>

                <AiOutlineCloseCircle className='close cursor-pointer absolute right-5 top-5 text-[20px] text-red-600 z-[99]' onClick={() => handleClose()} />
                <div className="flex flex-col gap-3">
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Vendor Name</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.vendorname}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Address</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.address}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Website</p>
                        <span>:</span>
                        <a href={item.website} target='_blank' className='text-theme-color underline text-base'>{item.website}</a>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Contact Person Name </p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.contactpersonname}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Contact Person Phone</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.contactpersonphone}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Alternate Person Name</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.alternatepersonname}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Alternate Person Phone</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.alternatepersonphone}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Email</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.email}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Password</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.password}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VendorModal
