const admin = "admin";
const candidate = "candidate";
const job = "job";

export const LOGIN = `${admin}/login`;
export const ADD_CANDIDATE = `${admin}/addcandidate`;
export const GET_CANDIDATE_BY_ID = `${admin}/getbyid`;
export const UPDATE_CANDIDATE_BY_ID = `${admin}/comment`;
export const GET_ALL_CANDIDATES = `${admin}/getcandidates`;
export const GET_ALL_STATUS = `status/getallstatus`;
export const GET_ALL_TECHNOLOGIES = `technology/alltechnology`;
export const GET_ALL_VENDORS = `vendor/allvendor`;
export const GET_ALL_SUB_ADMIN = `${admin}/getadmin`;
export const DELETE_CANDIDATE_BY_ID = `${admin}/deletebyadmin`;
export const GET_ALL_JOBS = `${job}/alljob`;
export const ADD_JOB = `${job}/addJob`;
export const GET_JOB_BY_ID = `${job}/getjob`;
export const UPDATE_JOB_BY_ID = `${job}/updateJob`;
export const DELETE_JOB_BY_ID = `${job}/deleteJob`;
export const CHANGE_JOB_STATUS = `${job}/enable`;
export const JOB_CANDIDATES = `${job}/jobCandidates`;
export const JOB_GRAPHS = `${job}/graphs`;
export const GET_ALL_CITIES = "city/getallcity";
export const GET_ALL_CLIENTS = "client/getallclient";
