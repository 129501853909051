import React, { useState } from 'react'
import { FILTER_ICON } from '../Assets/icons/icons'

const CustomFilter = ({ filterCount, onSubmit, onReset, children }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const toggleFilter = () => setIsShowModal(!isShowModal);

  const handleSubmit = () => {
    onSubmit();
    toggleFilter();
  };
  const handleReset = () => {
    onReset();
    toggleFilter();
  };

  return (
    <>
      <FILTER_ICON onClick={toggleFilter} />
      {!!filterCount > 0 && <div className="absolute bg-theme-color w-4 h-4 rounded-full text-center left-[70%] top-[1%] text-[11px] text-white">
        {filterCount}
      </div>}
      {!!isShowModal &&
        <div className="absolute top-10 right-[calc(100%-265px)] z-[1000]">
          <div className="bg-[#f4f0f0] p-4 w-[55vw] max-w-[42vw] rounded z-11 shadow-xl">
            <div className="grid grid-cols-1 md:grid-cols-2 w-full p-4">
              {children}
            </div>
            <div class="flex">
              <button className="bg-theme-color mx-2 border text-white p-2 text-sm" onClick={handleSubmit}>Submit</button>
              <button className="p-2 bg-theme-color border text-white text-sm" onClick={handleReset}>Reset</button>
            </div>
          </div>
        </div>}
    </>
  )
}

export default CustomFilter
