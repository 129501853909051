import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { FiTrash, FiEdit2, FiEye } from "react-icons/fi";
import { DataContext } from "../../context/Datastate";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
// import DeleteModal from "../DeleteModal/DeleteModal";
import Pagination from "../Pagination/Pagination";
// import CandidateModal from "./CandidateModal";
import nodata from "../Assets/nodata.png";
import Loader from "../Loader/Loader";
import NoData from "../NoData/NoData";

const AppliedJobsList = () => {
  const {
    loader,
    setAppliedJobs,
    appliedJobs,
    globalLoader,
    appliedJobsPaginationApi,
    totalCount,
    pageCount,
    setCurrentPage,
  } = useContext(DataContext);

  const limit = 10;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  // const [searchInput, setSearchInput] = useState("");
  // const [data, setData] = useState([]);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);

  useEffect(() => {
    setCurrentPage(1);
    appliedJobsPaginationApi(1, limit);
  }, []);

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...appliedJobs].sort((a, b) => {
      const valueA = (a[property] || "").toString();
      const valueB = (b[property] || "").toString();
      const comparison = valueA.localeCompare(valueB);
      return isAscending ? comparison : -comparison;
    });

    setAppliedJobs(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };

  return (
    <>
      <div className="flex w-full">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
          <div className="pt-3 flex justify-between items-start">
            <h1 className="text-xl text-[#202223] pt-2 font-semibold">
              Applied Jobs List
            </h1>
            <div className="flex space-x-2 justify-between pt-2 items-start flex-wrap"></div>
          </div>
          {/*--------- table list--------- */}
          {loader && <Loader />}
          {/* {loader ? (
            <Loader /> */}
          {appliedJobs?.length > 0 ? (
            <>
              <div className="h-[80vh] relative overflow-y-auto">
                <table className="w-full table-auto overflow-hidden border-collapse bg-white shadow-md  rounded-lg  ">
                  <thead className="cursor-pointer sticky top-0 z-[4]">
                    <tr className="  bg-theme-light text-theme-color text-xs font-normal ">
                      <th className=" px-4 py-2 flex items-center">
                        <div className="flex items-center gap-4">
                          <p>Job Title</p>
                          <div className="px-2 ">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "jobtitle",
                                  "asc"
                                )}`}
                                onClick={() => sortData("jobtitle")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "jobtitle",
                                  "desc"
                                )}`}
                                onClick={() => sortData("jobtitle")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 py-2 relative">
                        <div className="flex items-center gap-4">
                          <p>Experience</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`${getSortArrowClassName(
                                  "exp_min_max",
                                  "asc"
                                )}`}
                                onClick={() => sortData("exp_min_max")}
                              />
                              <FaSortDown
                                className={`mt-[-8px] ${getSortArrowClassName(
                                  "exp_min_max",
                                  "desc"
                                )}`}
                                onClick={() => sortData("exp_min_max")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4">
                        <div className="flex items-center gap-4">
                          <p>Position</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "positions",
                                  "asc"
                                )}`}
                                onClick={() => sortData("positions")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "positions",
                                  "desc"
                                )}`}
                                onClick={() => sortData("positions")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Work Nature</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "worknature",
                                  "asc"
                                )}`}
                                onClick={() => sortData("worknature")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "worknature",
                                  "desc"
                                )}`}
                                onClick={() => sortData("worknature")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4">
                        <div className="flex items-center gap-4">
                          <p>Location</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "location",
                                  "asc"
                                )}`}
                                onClick={() => sortData("location")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "location",
                                  "desc"
                                )}`}
                                onClick={() => sortData("location")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className="text-center rounded-tr-lg">Applied</th>
                      <th className=" px-4 text-center rounded-tr-lg">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                    {appliedJobs?.map((item) => (
                      <tr className="border-t text-left text-[14px] text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  cursor-pointer">
                        <td className="px-4 py-2 text-start">
                          {item?.jobtitle}
                        </td>
                        <td className="px-9 py-2">
                          {item.hasOwnProperty("totalExp")
                            ? `${item?.totalExp?.years}. ${item?.totalExp?.months}`
                            : "0.0"}
                        </td>
                        <td className="px-9 py-2">{item?.positions}</td>
                        <td className="px-4 py-2">{item?.worknature}</td>
                        <td className="px-4 py-2">{`${item?.location}`}</td>
                        <td className="px-4 py-2">
                          {item?.appliedcandidates?.length}
                        </td>
                        <td className="px-4 py-2">
                          <div className="flex items-center  justify-center  gap-2 text-black ">
                            <Link
                              to={`/viewcandidate-list/${item._id}`}
                              state={item?.jobtitle}
                            >
                              <span className="text-theme-color hover:underline text-sm">
                                View Candidates{" "}
                              </span>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {/*<tr className=" border-t text-left text-sm text-[#202223] font-normal group hover:shadow-tableRowsShadow cursor-pointer">
                  <td className=" px-4 py-2 rounded-bl-lg"><img src={profileImg} className="w-16 h-16 rounded-[2px]"/></td>
                  <td className=" px-4 py-2">Mr.</td>
                  <td   className="px-4 py-2">James Devine</td>
                  <td   className="px-4 py-2 ">Principal Solutions Architect</td>
                  <td   className="px-4 py-2 ">Amazon Web Services</td>
                  <td className='rounded-br-lg'> <div className="flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity text-[18px] gap-4 text-theme-color"> <FiTrash/> <FiEdit2/> </div></td>
                 
                    </tr>*/}
                  </tbody>
                </table>
              </div>
              {/* )} */}

              <div className="flex justify-between items-center ">
                <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${
                  pageCount === 1 ? totalCount : secondResultCount
                } of ${totalCount && totalCount} results`}</span>
                <Pagination
                  firstResultCount={firstResultCount}
                  setFirstResultCount={setFirstResultCount}
                  secondResultCount={secondResultCount}
                  setSecondResultCount={setSecondResultCount}
                  limit={limit}
                  api={appliedJobsPaginationApi}
                />
              </div>

              <div>
                <span className="font-[150px"></span>
              </div>
            </>
          ) : (
            <NoData />
          )}

          {/* {!loader && !appliedJobs && <NoData />} */}
        </div>
      </div>
    </>
  );
};

export default AppliedJobsList;
