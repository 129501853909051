import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import TermsCondition from "./components/TermsAndCondition/TermsCondition";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import DataProvider from "./context/Datastate";
import Dashboard from "./components/Dashboard/Dashboard";
import Sidebar from "./components/Sidebar/Sidebar";
import CreatePassword from "./components/CreatePassword/CreatePassword";
import VendorList from "./components/Vendor/VendorList";
import AddVendor from "./components/Vendor/AddVendor";
import UpdateVendor from "./components/Vendor/UpdateVendor";
import AddJob from "./components/Jobs/AddJob";
import JobList from "./components/Jobs/JobList";
import UpdateJob from "./components/Jobs/UpdateJob";
import ImportJob from "./components/Jobs/ImportJob";
import AllCandidateList from "./components/Candidates/AllCandidateList";
import AddCandidate from "./components/Candidates/AddCandidate";
import Candidates from "./components/Candidates/Candidates";
import Technology from "./components/Master/Technology/TechnologyList";
import StatusList from "./components/Master/Status/StatusList";
import City from "./components/Master/City/CityList";
import ClientList from "./components/Master/Client/ClientList";
import AddClient from "./components/Master/Client/AddClient";
import UpdateClient from "./components/Master/Client/UpdateClient";
import UpdateCandidate from "./components/Candidates/UpdateCandidate";
import AppliedJobsList from "./components/AppliedJob/AppliedJobList";
import ViewCandidateList from "./components/AppliedJob/ViewCandidateList";
import CandidateList from "./components/CandidateList/CandidateList";
import SubadminList from "./components/Master/SubAdmin/SubadminList";
import LogsList from "./components/Jobs/LogsList";
import AddSubAdmin from "./components/Master/SubAdmin/AddSubAdmin";
import UpdateSubAdmin from "./components/Master/SubAdmin/UpdateSubAdmin";
import UpdateCandidateList from "./components/Vendor/UpdateCandidateList";
import Recruiter from "./components/Recruiter/RecruiterList";
import InhouseJobs from "./components/InHouseJobs/InHouseJobs";
import AllCandidates from "./components/Candidates";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import Auth from "./components/auth";
import ComponentsWithSideBar from "./components";
import Vendors from "./components/Vendor";
import Jobs from "./components/Jobs";
import EmailEditor from "./components/EmailEditor/EmailEditor"
// import AddUser from "./components/User/AddUser";
// import UserList from "./components/User/UserList";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        theme="light"
      />
      <Router>
        <DataProvider>
          <div className="flex w-full">
            <Routes>
              <Route exact path="/" element={<Auth />}>
                <Route exact path="login" element={<Login />} />
                <Route
                  exact
                  path="forgot-password"
                  element={<ForgotPassword />}
                />
                <Route
                  exact
                  path="create-password"
                  element={<CreatePassword />}
                />
              </Route>
              <Route exact path="/signup" element={<SignUp />} />
              <Route
                exact
                path="/developers/candidates"
                element={<CandidateList />}
              />
              <Route element={<ComponentsWithSideBar />}>
                <Route
                  exact
                  path="/reset-password/:userId"
                  element={<ResetPassword />}
                />
                <Route
                  exact
                  path="/terms-conditions"
                  element={<TermsCondition />}
                />
                <Route
                  exact
                  path="/privacy-policy"
                  element={<PrivacyPolicy />}
                />
             
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route exact path="/recruiterlist" element={<Recruiter />} />
                <Route exact path="/inhouselist" element={<InhouseJobs />} />
                <Route exact path="vendors" element={<Vendors />}>
                  <Route path="list" element={<VendorList />} />
                  <Route path="add" element={<AddVendor />} />
                  <Route path="update/:id" element={<UpdateVendor />} />
                </Route>
                <Route exact path="jobs" element={<Jobs />}>
                  <Route path="add" element={<AddJob />} />
                  <Route path="list" element={<JobList />} />
                  {/* <Route path="logs/:id" element={<LogsList />} /> */}
                  <Route path="logs/:id" element={<LogsList />} />
                  <Route path="update/:jobID" element={<AddJob />} />
                </Route>
                <Route exact path="import_job" element={<ImportJob />} />
                <Route exact path="all_candidates" element={<AllCandidates />}>
                  <Route path="list" element={<AllCandidateList />} />
                  <Route path="add" element={<AddCandidate />} />
                  <Route
                    path="update/:vendorId/:candidateId"
                    element={<AddCandidate />}
                  />
                </Route>
                <Route
                  exact
                  path="cadidatelist-vendor/:id"
                  element={<Candidates />}
                />
                <Route
                  exact
                  path="/viewcandidate-list/:id"
                  element={<ViewCandidateList />}
                />
                <Route exact path="/email" element={<EmailEditor />} />

                <Route exact path="/technology_list" element={<Technology />} />
                <Route exact path="status-list" element={<StatusList />} />
                <Route exact path="city_list" element={<City />} />
                <Route exact path="/client_list" element={<ClientList />} />
                <Route exact path="/add_client" element={<AddClient />} />
                <Route
                  exact
                  path="/update_client/:id"
                  element={<UpdateClient />}
                />
                <Route
                  exact
                  path="/applied-jobs"
                  element={<AppliedJobsList />}
                />
                <Route exact path="/subadmin-list" element={<SubadminList />} />
                <Route exact path="/add_subadmin" element={<AddSubAdmin />} />
                <Route
                  exact
                  path="/update_subadmin/:id"
                  element={<UpdateSubAdmin />}
                />
                {/* <Route exact path="user-list" element={<UserList/>}/>  */}
                {/* <Route exact path="/add-user" element={<AddUser/>} /> */}
              </Route>
            </Routes>
          </div>
        </DataProvider>
      </Router>
    </div>
  );
}
export default App;
