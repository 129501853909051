import React, { useContext, useEffect, useState,useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown, FaPencilAlt } from "react-icons/fa";
import { FiTrash, FiEdit2, FiEye } from "react-icons/fi";
import { DataContext } from "../../context/Datastate";
import Pagination from "../Pagination/Pagination";
// import nodata from "../Assets/nodata.png";
// import DeleteModal from "../DeleteModal/DeleteModal";
import Loader from "../Loader/Loader";
import NoData from "../NoData/NoData";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";


const RecruiterList = () => {
  const limit = 100;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [searchInput, setSearchInput] = useState("");
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const {
    loader,
    recruiter,
    recruiterPaginationApi,
    searchRecruiterApi,
    setRecruiter,
    pageCount,
    totalCount,
  } = useContext(DataContext);

  const onKeyUpHandler = (e) => {
    const inputValue = e.target.value;
    // Update search input state
    setSearchInput(inputValue);
    // Check if the length of the input is at least 4 characters or it's empty
    if (inputValue.length >= 4 || inputValue.length === 0) {
        searchRecruiterApi(inputValue);
    }
  };

  const validateUrl = (link) => {
    const validUrlPattern =
      /^(https?|http):\/\/[^\s/$?#]+(\.[^\s/$?#]+)*\.[^\s$]{2,}$/;

    return validUrlPattern.test(link);
  };

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...recruiter].sort((a, b) => {
      const valueA = String(a[property]); // Convert to string
      const valueB = String(b[property]); // Convert to string

      const comparison = valueA.localeCompare(valueB);
      return isAscending ? comparison : -comparison;
    });

    setRecruiter(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };

  useEffect(() => {
    recruiterPaginationApi(1, limit);
  }, []);

  return (
    <>
      <div className="flex w-[90vw]">
        <div className="w-full pl-[0.75rem] pr-[4.75rem] py-2 overflow-y-scroll h-screen space-y-4">
          <div className="pt-3 ">
            <div className="flex justify-between items-center">
              <h1 className="text-xl text-[#202223] font-semibold">
                Recruiter List
              </h1>
              <div className="float-right flex ">
                <FaSearch className="search-icon relative top-[0.7rem] left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div>
            {/* <div className="flex space-x-2 justify-between pt-2 items-start ">
              <div className="float-right flex ">
                <FaSearch className="search-icon relative top-[0.7rem] left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div> */}
          </div>

          {/*--------- table list--------- */}
          {loader ? (
            <Loader />
          ) : recruiter.length > 0 ? (
            <>
              <div className="h-[80vh] relative overflow-y-auto">
                <table className="w-full table-auto relative bg-white border-collapse shadow-md rounded-lg  ">
                  <thead className="cursor-pointer sticky top-0 z-[4]">
                    <tr className=" text-left bg-theme-light text-theme-color text-xs font-normal  ">
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Vendor Name</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "vendorname",
                                  "asc"
                                )}`}
                                onClick={() => sortData("vendorname")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "vendorname",
                                  "desc"
                                )}`}
                                onClick={() => sortData("vendorname")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className="px-4">
                        <div className="flex items-center gap-4">
                          <p>Candidate Assigned</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "candidateAssigned",
                                  "asc"
                                )}`}
                                onClick={() => sortData("candidateAssigned")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "candidateAssigned",
                                  "desc"
                                )}`}
                                onClick={() => sortData("candidateAssigned")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 py-3 flex items-center">
                        <div className="flex items-center gap-4">
                          <p>Name</p>
                          <div className="px-2">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "name",
                                  "asc"
                                )}`}
                                onClick={() => sortData("name")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "name",
                                  "desc"
                                )}`}
                                onClick={() => sortData("name")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p className="text-">Linkedin Profile</p>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Resume Link</p>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Primary Technology</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "primarytechnology",
                                  "asc"
                                )}`}
                                onClick={() => sortData("primarytechnology")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "primarytechnology",
                                  "desc"
                                )}`}
                                onClick={() => sortData("primarytechnology")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>
                            Rel <br />
                            Exp.
                          </p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "relevantExp",
                                  "asc"
                                )}`}
                                onClick={() => sortData("relevantExp")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "relevantExp",
                                  "desc"
                                )}`}
                                onClick={() => sortData("relevantExp")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Phone Number</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "phonenumber",
                                  "asc"
                                )}`}
                                onClick={() => sortData("phonenumber")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "phonenumber",
                                  "desc"
                                )}`}
                                onClick={() => sortData("phonenumber")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Date of Submission</p>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Rate</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "rate",
                                  "asc"
                                )}`}
                                onClick={() => sortData("rate")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "rate",
                                  "desc"
                                )}`}
                                onClick={() => sortData("rate")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Comm.</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "comments",
                                  "asc"
                                )}`}
                                onClick={() => sortData("comments")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "comments",
                                  "desc"
                                )}`}
                                onClick={() => sortData("comments")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Joining Time</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "joiningtime",
                                  "asc"
                                )}`}
                                onClick={() => sortData("joiningtime")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "joiningtime",
                                  "desc"
                                )}`}
                                onClick={() => sortData("joiningtime")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 py-2 text-center rounded-tr-lg">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB] overflow-y-scroll">
                    {recruiter?.map((item) => (
                      <tr className="border-t text-left  text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  cursor-pointer break-word text-[14px]">
                        <td className="px-2 py-2">
                          {item?.vendor_id?.vendorname}
                        </td>
                        <td className="px-2 py-2">
                          {item?.candidateAssigned?.name}
                        </td>
                        <td className="px-2 py-2">{item?.name}</td>
                        <td className="px-2 py-2  ">
                          {validateUrl(item.linkedinprofile) ? (
                            <a
                              href={item.linkedinprofile}
                              className="text-theme-color underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Linkedin
                            </a>
                          ) : (
                            <p>

                            </p>
                          )}
                        </td>
                        <td className="px-2 py-2 ">
                          {validateUrl(item.resumelink) ? (
                            <a
                              href={item.resumelink}
                              className="text-theme-color underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Resume
                            </a>
                          ) : (
                            <p>
                            </p>
                          )}
                        </td>
                        <td className="px-2 py-2">
                          {item?.primarytechnology?.technologyname}
                        </td>
                        <td className="px-2 py-2">
                          {item.hasOwnProperty("relevantExp")
                            ? `${item?.relevantExp?.years}. ${item?.relevantExp?.months}`
                            : "0.0"}
                        </td>
                        <td className="px-1 py-2">{item?.phonenumber}</td>
                        <td className="px-2 py-2 ">
                          {item?.createdAt
                            ? new Date(item?.createdAt).toLocaleDateString(
                                "en-GB"
                              )
                            : "N/A"}
                        </td>
                        <td className="px-2 py-2">{item?.rate}</td>
                        <td className="px-2 py-2">{item?.comments?.length}</td>
                        <td className="px-2 py-2">{item?.joiningtime}</td>
                        <td className="px-2 py-2">
                          <div className="flex items-center  justify-center text-black ">
                          <Link
                        to={`/update-candidate/${item.vendor_id?._id}/${item?._id}`}
                        state={{ item, from: "recruiter_list" }}
                      >
                              <FiEdit2
                                title="view details"
                                className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]"
                              />
                            </Link>
                          </div>
                        </td>
                        {/* <td className="px-2 py-2">
                          <div className="flex items-center  justify-center text-black ">
                            <Link
                              to={`/update-candidate/${item.vendor_id?._id}/${item?._id}`}
                              state={item}
                            >
                              <FiEdit2
                                title="view details"
                                className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]"
                              />
                            </Link>
                            <FiTrash
                              className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]"
                              title="delete candidate"
                              onClick={() => {
                                setDeleteBtn(true);
                                setDeleteBtnId(item?._id);
                              }}
                            />
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-between items-center ">
                <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${
                  pageCount === 1 ? totalCount : secondResultCount
                } of ${totalCount && totalCount } results`}</span>
                <Pagination
                  firstResultCount={firstResultCount}
                  setFirstResultCount={setFirstResultCount}
                  secondResultCount={secondResultCount}
                  setSecondResultCount={setSecondResultCount}
                  limit={limit}
                  api={recruiterPaginationApi}
                />
              </div>
            </>
          ) : null}
          {((!loader && !recruiter) ||
            recruiter.length == 0) && <NoData />}
          <div>
            <span className="font-[150px"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecruiterList;
