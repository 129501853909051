import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { DataContext } from "../../../context/Datastate";

const UpdateClient = () => {
  const {
    // getVendorApi,
    client,
    updateClientApi,
    // countryList,
    // getCountryApi,
    disableSave,
  } = useContext(DataContext);
  const [count, setCount] = useState(0);
  const [editClient, setEditClient] = useState({});
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  const [websiteError, setWebsiteError] = useState(false);
  const { id } = useParams();
  const [emailError, setEmailError] = useState(false);
  // const [comment, setComment] = useState("");

  const handleUpdate = (e) => {
    // if (!disableSave)
    updateClientApi(editClient, id);
  };

  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const validateEmail = () => {
    if (!validEmail.test(editClient.email) && editClient.email !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const validUrl = new RegExp(
    /^https?:\/\/(www\.)?([^\s/?\.#]+\.?)+(\/[^\s]*)?(\.[a-zA-Z]{2,})?$/
  );

  // const validUrl = new RegExp(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/);
  const validateUrl = () => {
    if (!validUrl.test(editClient.website) && editClient.website !== "") {
      setWebsiteError(true);
    } else {
      setWebsiteError(false);
    }
  };
  const validatePhoneno = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setPhoneFormatTest(false);
    } else if (phoneData == "") {
      return setPhoneFormatTest(false);
    } else {
      return setPhoneFormatTest(true);
    }
  };

  const buttonDisabled = editClient.name !== "";

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }
  useEffect(() => {
    const newArr = client.filter((element) => {
      return element?._id === id;
    });

    setEditClient({ ...newArr[0] });
  }, []);

  return (
    <>
      <div className="flex w-full bg-white">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex">
            <Link to={"/client_list"}>
              <div className="w-8 ">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-1">
              <h1 className=" text-xl hover:bg-orange-400xl px-2">
                Client info{" "}
              </h1>
            </div>
          </div>
          <div className="flex space-x-10">
            <div className="w-1/3 space-y-3">
              <h2 className="font-semibold text-lg">Client Details</h2>
              <hr />
              <div className="">
                <div>
                  <label htmlFor="name" className="text-sm px-1 font-semibold ">
                    Name *
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your name"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editClient.name}
                    onChange={(e) => {
                      setEditClient({
                        ...editClient,
                        name: toTitleCase(e.target.value),
                      });
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="website"
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                    Website <small>(https://website.com)</small>
                  </label>
                  <input
                    id="website"
                    type="text"
                    name="website"
                    placeholder="https://website.com"
                    className="w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editClient?.website}
                    onChange={(e) => {
                      setEditClient({ ...editClient, website: e.target.value });
                    }}
                    onKeyUp={(e) => validateUrl(e.target.value)}
                  />
                  {websiteError && (
                    <span className="text-left text-red-600 text-sm">
                      Please enter a valid url
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/3 pt-14">
              <div>
                <label
                  htmlFor="last-name"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Contact Person Name
                </label>
                <input
                  id="contactpersonname"
                  type="text"
                  name="contactpersonname"
                  placeholder="Enter your contact person name"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={editClient.contactpersonname}
                  onChange={(e) => {
                    setEditClient({
                      ...editClient,
                      contactpersonname: toTitleCase(e.target.value),
                    });
                  }}
                />
              </div>
              <div className="pb-2">
                <label
                  htmlFor="phone"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Phone
                </label>
                <input
                  type="text"
                  maxlength={10}
                  pattern="[0-9]{10}"
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9"
                  value={editClient.phonenumber}
                  onChange={(e) => {
                    setEditClient({
                      ...editClient,
                      phonenumber: e.target.value,
                    });
                  }}
                  onKeyUp={() => validatePhoneno(editClient.phonenumber)}
                />
                {phoneFormatTest ? (
                  <span className="text-red-600 text-sm">
                    Please enter a valid 10 digit Number.
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="w-1/3 pt-14">
              <div>
                <label
                  htmlFor="email"
                  className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
                >
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  autoComplete="off"
                  placeholder="Enter your email"
                  className={`w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9 ${
                    emailError ? "" : "mb-4"
                  } `}
                  value={editClient.email}
                  onChange={(e) => {
                    setEditClient({ ...editClient, email: e.target.value });
                  }}
                  onKeyUp={() => validateEmail()}
                />
                {emailError && (
                  <span className="text-left text-red-600 text-sm ">
                    Please enter a valid Email
                  </span>
                )}
              </div>
              <div>
                <label
                  htmlFor="address"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Address
                </label>
                <input
                  id="address"
                  type="text"
                  name="address"
                  placeholder="Enter your address"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={editClient.address}
                  onChange={(e) => {
                    setEditClient({ ...editClient, address: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="">
              <label
                htmlFor="positiontype"
                className="text-sm px-1 font-semibold "
              >
                Add Comments
              </label>
              <textarea
                type="text"
                id="positiontype"
                name="positiontype"
                placeholder="Enter your comments here"
                className="w-full border py-1 px-3 mb-1 border-gray-300 shadow-sm rounded-md  mt-1 focus-visible:border-[#fb923c] h-44 resize-none  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm "
                value={editClient?.comment}
                onChange={(e) => {
                  setEditClient({ ...editClient, comment: e.target.value });
                }}
                // onChange={(e) =>
                //   e.target.value !== "" && setComment(e.target.value)
                // }
                // onKeyDown={(e) => {
                //   if (e.key === "Backspace" && comment.length === 1) {
                //     // Clear the input when backspace is pressed and only one character is present
                //     setComment("");
                //   }
                // }}
              />
            </div>
          </div>
          <div className="">
            <Link to="/client_list">
              <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>
            <button
              type="button"
              disabled={!buttonDisabled}
              className="bg-theme-color disabled:opacity-50 px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3"
              onClick={() => {
                handleUpdate();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateClient;
