export const UPLOAD_ICON=({className: classes})=>(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes}>
    <path d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM13 9V16H11V9H6L12 3L18 9H13Z" fill="currentColor"/>
  </svg>
);

export const BACWARD_ARROW=({ className: classes })=>(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes}>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.0002 9.00025H5.41425L8.70725 5.70725C9.09825 5.31625 9.09825 4.68425 8.70725 4.29325C8.31625 3.90225 7.68425 3.90225 7.29325 4.29325L2.29325 9.29325C1.90225 9.68425 1.90225 10.3163 2.29325 10.7073L7.29325 15.7072C7.48825 15.9022 7.74425 16.0002 8.00025 16.0002C8.25625 16.0002 8.51225 15.9022 8.70725 15.7072C9.09825 15.3162 9.09825 14.6842 8.70725 14.2933L5.41425 11.0002H17.0002C17.5532 11.0002 18.0002 10.5522 18.0002 10.0002C18.0002 9.44825 17.5532 9.00025 17.0002 9.00025Z" fill="#5C5F62"/>
  </svg>
  );

export const CALENDER_ICON=()=>(
  <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
  </svg>
)

export const FILTER_ICON=({...attributes})=>(
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" id="filter" className="cursor-pointer" {...attributes}><g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"><g stroke="#000" strokeWidth="2" transform="translate(-1614 -1629)"><g transform="translate(1615 1630)"><path d="M20 0H0l8 9.46V16l4 2V9.46z"></path></g></g></g></svg>
)

export const COPY_ICON=({className:classes})=>(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className={classes}><path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" fill="currentColor"/></svg>
)