import React from "react";
import Sidebar from "./Sidebar/Sidebar";
import { Navigate, Outlet } from "react-router-dom";

const ComponentsWithSideBar = () => {
  const token = localStorage.getItem("adminToken");
  if (token) {
    return (
      <>
        <Sidebar />
        <Outlet />
      </>
    );
  } else return <Navigate to="/" />;
};

export default ComponentsWithSideBar;
