import React from 'react'
import { BackButton } from '../atoms/Utility'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader/Loader';

const AddFormLayout = (props) => {
  const { pageTitle, heading, navigationPath, loadingState, buttonDisabled, onFormSubmit, children } = props;
  const navigate = useNavigate();
  return (
    <div className="w-full bg-white">
      <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
        <div className="my-[20px] flex" >
          <BackButton path={navigationPath} />
          <div className="flex items-center justify-start">
            <h1 className="text-xl font-semibold font-sans tracking-wide">{pageTitle}</h1>
          </div>
        </div>
        <h2 className="font-semibold text-lg">{heading}</h2>
        <hr />
        {loadingState ? <Loader /> :
          <>
            {/* --------------FORM ELEMENT-------------- */}
            <div className="w-full container mx-[auto]">
              {children}
            </div>
            {/* ---------------BUTTONS--------------- */}
            <div className="">
              <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded" onClick={() => navigate(navigationPath)}>
                Back
              </button>
              <button
                type="button"
                disabled={buttonDisabled}
                className="bg-theme-color disabled:opacity-50 px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3"
                onClick={onFormSubmit}
              >
                Save
              </button>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default AddFormLayout
