import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEnvelope, FaLock, FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { DataContext } from "../../context/Datastate";
import { CustomLabel, ErrorState } from "../atoms/Utility";
import { isEmailValid } from "../../utils";
import fetchUtil from "../../utils/fetch.util";
import { LOGIN } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";


const Login = () => {
  const navigate = useNavigate();
  const [passwordIcon, setPasswordIcon] = useState(true);
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [apiError, setApiError] = useState('');
  const [error, setError] = useState({
    email: false,
    password: false
  })

  const INPUT_FIELDS = [
    { id: 'email', label: 'Email Address', name: 'email', type: 'email', placeholder: 'Enter your email', value: state.email, required: true, errState: error.email, errMsg: 'Enter valid email', leftIcon: <FaEnvelope />, },
    { id: 'pswrd', label: 'Password', name: 'password', type: 'password', placeholder: 'Password', value: state.password, required: true, errState: error.password, errMsg: 'Password length must be in between 6 to 20', leftIcon: <FaLock />, rightIconOne: <FaRegEye />, rightIconTwo: <FaRegEyeSlash /> }
  ]

  const handleInputs = (e) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value.trim() }));

    if (e.target.name === 'email') {
      if (e.target.value.trim() === '') setError((prev) => ({ ...prev, email: false }));
      else if (!isEmailValid(e.target.value)) setError((prev) => ({ ...prev, email: true }));
      else setError((prev) => ({ ...prev, email: false }))
    }
    if (e.target.name === 'password') {
      if (e.target.value.trim() === '') setError((prev) => ({ ...prev, password: false }));
      else if (e.target.value.length < 6 || e.target.value.length > 20) setError((prev) => ({ ...prev, password: true }));
      else setError((prev) => ({ ...prev, password: false }))
    }
  }

  const toggleIcon = () => {
    setPasswordIcon(!passwordIcon);
  }
  const handleInputType = (inputType) => {
    if (inputType === 'password') {
      if (!passwordIcon) return inputType = 'text';
      else return inputType;
    } else return inputType;
  }

  const isFormValid = () => {
    return state.email?.trim() !== "" && state.password?.trim() !== "" && !error.email && !error.password;
  }

  const handleLogin = (e) => {
    e.preventDefault();
    setLoader(true);
    fetchUtil.POST(LOGIN, state)
      .then((resp) => {
        toast.success(resp.message);
        localStorage.setItem("adminToken", JSON.stringify(resp.token));
        setTimeout(() => {
          // navigate("/dashboard");
          navigate("/all_candidates/add");

        }, 1000);
      })
      .catch(err => {
        console.error(err);
        setApiError(err);
      })
      .finally(() => setLoader(false));
  };

  return (
    <>
      <div className=" py-4 rounded-t-md">
        <h1 className="text-center text-[20px] font-semibold">
          Sign in
        </h1>
      </div>
      <hr />
      <div className="p-9 md:p-8">
        <form onSubmit={handleLogin}>
          {INPUT_FIELDS.map((field) => (
            <>
              <div className="mb-5">
                <CustomLabel htmlFor={field.id} required={field.required}>{field.label}</CustomLabel>
                <div className="relative">
                  {!!field.leftIcon && <div className="absolute top-[33%] left-2 h-4 w-4 text-theme-color">{field.leftIcon}</div>}
                  <input
                    id={field.id}
                    name={field.name}
                    type={handleInputType(field.type)}
                    className="mt-1 block w-full px-3 pl-8 py-3 bg-white border rounded-md text-sm shadow-sm placeholder-slate-400  focus-visible:border-inputBorder focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-1"
                    placeholder={field.placeholder}
                    value={field.value}
                    onChange={handleInputs}
                  />
                  {!!field.rightIconOne &&
                    <div
                      className="absolute top-[33%] right-0 pr-2 cursor-pointer text-theme-color"
                      onClick={toggleIcon}
                    >
                      {!passwordIcon ? field.rightIconOne : field.rightIconTwo}
                    </div>}
                </div>
                <ErrorState err={field.errState} errMsg={field.errMsg} />
              </div>
            </>
          ))}
          <ErrorState err={apiError} errMsg={apiError} />
          <Link
            to="/forgot-password"
            className="text-theme-color font-medium underline text-right block text-sm mb-5"
            rel="noreferrer"
          >
            Forgot Password
          </Link>
          <button
            type="submit"
            disabled={!isFormValid() || loader}
            className="bg-theme-color w-full text-center disabled:opacity-40 flex items-center justify-center text-white transition ease-in-out delay-150 duration-300 py-[.5rem] rounded-lg font-bold cursor-pointer"
          >
            <span
              className={` ${!!loader && "border-t-transparent border-2 border-white animate-spin rounded-full w-5 h-5 mr-2"} `}
            ></span>
            Login
          </button>
        </form>
      </div>
    </>
  );
};

export default Login;
