import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import AllCandidateList from './AllCandidateList';
import AddCandidate from './AddCandidate';

const initialStateOfFilters = {
  vendor_id: '',
  status: '',
  primarytechnology: '',
  candidateAssigned: '',
  startTime: null,
  endTime: null,
  yearofexperience: '',
  operator: '',
  linkedinprofile: null,
  resume: null,
  videoIntro: false,
}
const AllCandidates = () => {
  const { pathname } = useLocation();
  const [filters, setFilters] = useState(initialStateOfFilters);

  if (pathname.includes('list')) {
    return (
      <>
        <AllCandidateList filters={filters} setFilters={setFilters} initialStateOfFilters={initialStateOfFilters} />
      </>
    )
  }
  if ((pathname.includes('add') || pathname.includes('update'))) {
    return (
      <>
        <AddCandidate />
      </>
    )
  }
}

export default AllCandidates
