export const logsTableHeader = [
  { label: "Name", value: "name", sorting: true },
  { label: "LinkedIn", value: "linkedinprofile" },
  { label: "Resume", value: "resume" },
  { label: "Primary Tech.", value: "primarytechnology", sorting: true },
  // { label: "Relevant Exp.", value: "relevantExp", sorting: true },
  { label: "Mobile", value: "phonenumber", sorting: true },
  // { label: "Submission", value: "updatedAt" },
  // { label: "Comments", value: "commentsHistory", sorting: true },
  { label: "Availability", value: "joiningtime", sorting: true },
  { label: "Actions", value: "actions" },
];
