import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import { CgPlayButtonO } from "react-icons/cg";
import ConfirmModal from "../atoms/ConfirmModal";
import fetchUtil, { BASE_URL } from "../../utils/fetch.util";
import { convertISODateToFormattedDate, countNonEmptyValues, filteredData, formattedDate, generateOptions, isURLVaid, mergeYearAndMonth, toTitleCase } from "../../utils";
import CustomTable from "../atoms/CustomTable";
import CustomFilter from "../atoms/CustomFilter";
import "./candidates.css";
import { candidateTableHeader } from "./tableHeadData";
import { DELETE_CANDIDATE_BY_ID, GET_ALL_CANDIDATES, GET_ALL_STATUS, GET_ALL_SUB_ADMIN, GET_ALL_TECHNOLOGIES, GET_ALL_VENDORS } from "../../constants/apiEndPoints";
import RightSideLayout from "../Layout/RightSideLayout";
import VideoModal from "../atoms/VideoModal";

const AllCandidateList = (props) => {
  const limit = 100;
  const firstResultCount = 1;
  const { filters, setFilters, initialStateOfFilters } = props;
  const [videoUrl, setVideoUrl] = useState(``)
  const [videoModal, setVideoModal] = useState(false);
  const startDateRef = useRef();
  const endDateRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [candidatesData, setCandidatesData] = useState({
    pageCount: '',
    totalCount: '',
    list: []
  });
  const [status, setStatus] = useState([]);
  const [technologies, setTechnnologies] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowID, setRowID] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value.length >= 4 || e.target.value.length === 0) {
      setLoading(true);
      setTimeout(() => {
        fetchUtil.GET(GET_ALL_CANDIDATES, { key: e.target.value })
          .then((response) => {
            setCandidatesData((prev) => ({
              ...prev,
              pageCount: Math.ceil(response.result.length / limit),
              totalCount: response.result.length,
              list: response.result,
            }))
          })
          .catch(err => console.error(err))
          .finally(() => setLoading(false));
      }, 1000);
    }
  }

  const handlePageClick = (e) => {
    queryParams.page = e.selected + 1;
    fetchCandidates(GET_ALL_CANDIDATES, queryParams);
  }

  let queryParams = {
    page: firstResultCount,
    limit: limit,
    ...filters,
  }
  const fetchCandidates = (apiEndPoint, query) => {
    setLoading(true);
    fetchUtil.GET(apiEndPoint, query)
      .then((response) => {
        setCandidatesData((prev) => ({
          ...prev,
          pageCount: response.pageCount,
          totalCount: response.totalCount,
          list: response.result,
        }))
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  const dateRangeForToday = () => {
    const tomorrow = new Date(new Date());
    tomorrow.setDate(new Date().getDate() + 1);
    return tomorrow;
  };

  useEffect(() => {
    if (location.state === "today") {
      setFilters((prev) => ({
        ...prev,
        startTime: formattedDate(new Date()),
        endTime: formattedDate(dateRangeForToday()),
      }));
      queryParams.startTime = formattedDate(new Date());
      queryParams.endTime = formattedDate(dateRangeForToday());
      fetchCandidates(GET_ALL_CANDIDATES, queryParams);

    } else if (location.state === "submitToClient") {
      setFilters((prev) => ({ ...prev, status: "6524e3c2d7dd9e3c9165ae51" }));
      queryParams.status = "6524e3c2d7dd9e3c9165ae51";
      fetchCandidates(GET_ALL_CANDIDATES, queryParams);

    } else if (location.state === "hotAndImmediate") {
      setFilters((prev) => ({ ...prev, status: "65c9e631837d62ba49404f0d" }));
      queryParams.status = "65c9e631837d62ba49404f0d";
      fetchCandidates(GET_ALL_CANDIDATES, queryParams);

    } else if (location.state === 'withVideo') {
      setFilters((prev) => ({ ...prev, videoIntro: true }));
      queryParams.videoIntro = true;
      fetchCandidates(GET_ALL_CANDIDATES, queryParams);
    } else {
      fetchCandidates(GET_ALL_CANDIDATES, queryParams);
    }
  }, [location.state]);

  const fetchAllData = async () => {
    try {
      const [response1, response2, response3, response4] = await Promise.all([
        fetchUtil.GET(GET_ALL_STATUS),
        fetchUtil.GET(GET_ALL_TECHNOLOGIES),
        fetchUtil.GET(GET_ALL_VENDORS),
        fetchUtil.GET(GET_ALL_SUB_ADMIN),
      ]);
      setStatus(filteredData(response1.result));
      setTechnnologies(filteredData(response2.result));
      setVendors(filteredData(response3.result));
      setAssignees(response4.result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    if (countNonEmptyValues(filters)) {
      setFilterCount(countNonEmptyValues(filters));
    }
  }, [filters]);

  const filterData = () => {
    fetchCandidates(GET_ALL_CANDIDATES, queryParams);
  };

  const handleClear = () => {
    startDateRef.current.value = "";
    endDateRef.current.value = "";
    setFilters(initialStateOfFilters);
    fetchCandidates(GET_ALL_CANDIDATES, { page: 1, limit: limit });
    setFilterCount(0);
  };

  const renderURL = (link, name) => {
    if (link) return <a href={link} className="text-theme-color underline" target="_blank" rel="noopener noreferrer">{name}</a>
  }
  const renderFilePath = (filePath, name) => {
    return <a href={BASE_URL + filePath} className="text-theme-color underline" target="_blank" rel="noopener noreferrer">{name}</a>
  }

  const handleResume = (item) => {
    if (item.resumelink && isURLVaid(item.resumelink)) {
      return renderURL(item.resumelink, 'Resume');
    } else if (item.resume && (item.resume !== 'null' || item.resume !== 'undefined')) {
      return renderFilePath(item.resume, 'Resume');
    } else return;
  };

  const handleVideoModal = (url) => {
    const URL = `${BASE_URL}/${url}`
    setVideoUrl(URL);
    setVideoModal(true);
  }

  const handleAction = (e) => {
    setIsModalOpen(false);
    if (e.target.name === 'delete') {
      setLoading(true);
      fetchUtil.DELETE(`${DELETE_CANDIDATE_BY_ID}/${rowID}`)
        .then(() => fetchCandidates(GET_ALL_CANDIDATES, queryParams))
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    }
  }

  const renderAction = (rowData) => {
    return (
      <div className="flex items-center text-black ">
        {!!rowData?.videoIntro && <CgPlayButtonO
          title="play video"
          className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] cursor-pointer"
          onClick={() => handleVideoModal(rowData?.videoIntro)}
        />}
        <FiEdit2
          title="view details"
          className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] cursor-pointer"
          onClick={() => navigate(`/all_candidates/update/${rowData.vendor_id?._id}/${rowData?._id}`)}
        />
        <FiTrash
          className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] cursor-pointer"
          title="delete candidate"
          onClick={() => { setIsModalOpen(true); setRowID(rowData?._id); }}
        />
      </div>
    )

  }
  const modifiedData = candidatesData.list.length > 0 && candidatesData.list?.map((item) => ({
   
    ...item,
    vendor_name: item.vendor_id?.vendorname,
    status: item.status?.statusname,
    candidateAssigned: item.candidateAssigned?.name,
    primarytechnology: item.primarytechnology?.technologyname,
    relevantExp: mergeYearAndMonth(item?.relevantExp),
    updatedAt: convertISODateToFormattedDate(item?.updatedAt).slice(0, 10),
    commentsHistory: item.commentsHistory?.length,
    resume: handleResume(item),
    linkedinprofile: renderURL(item.linkedinprofile, 'LinkedIn'),
    actions: renderAction(item),
  }))

  return (
    <>
      <RightSideLayout pageTitle={'Candidates List'}>
        <RightSideLayout.TopSectionLayout>
          <button className="border shadow-btnshd bg-theme-color text-white px-4 py-2 rounded text-sm"
            onClick={() => navigate("/all_candidates/add")}>
            Add Candidate
          </button>
          <div className="flex">
            <div className="flex items-center relative">
              <CustomFilter filterCount={filterCount} onSubmit={filterData} onReset={handleClear}>
                <FilterModal filters={filters} setFilters={setFilters} startDateRef={startDateRef} endDateRef={endDateRef} setFilterCount={setFilterCount} status={status} technologies={technologies} vendors={vendors} assignees={assignees} />
              </CustomFilter>
            </div>
            <div className="relative ml-5">
              <FaSearch className="search-icon absolute top-[0.7rem] left-2 text-gray-500 " />
              <input
                type="text"
                placeholder="Search"
                className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                value={searchInput}
                onChange={handleSearch}
              />
            </div>
          </div>
        </RightSideLayout.TopSectionLayout>
        {/*--------- table list--------- */}
        <CustomTable data={modifiedData} columns={candidateTableHeader} totalPageCount={candidatesData.pageCount} totalCount={candidatesData.totalCount} handlePageClick={handlePageClick} loading={loading} />

        <ConfirmModal isModalOpen={isModalOpen} handleAction={handleAction} action={'delete'} />
        <VideoModal isOpen={videoModal} videoUrl={videoUrl} closeModal={() => setVideoModal(false)} />


      </RightSideLayout>
    </>
  );
};

export default AllCandidateList;

const FilterModal = (props) => {
  const { filters, setFilters, startDateRef, endDateRef, setFilterCount, status, technologies, vendors, assignees } = props;

  const handleChange = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setFilterCount((prev) => prev + 1);
  };
  const handleCheckbox = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
    setFilterCount((prev) => prev + 1);
  }
  const expOperator = [
    { id: 1, label: ">=", value: "greater" },
    { id: 2, label: "<=", value: "lesser" },
  ];
  const checkBoxes = [
    { id: 1, label: 'LinkedIn', name: "linkedinprofile" },
    { id: 2, label: 'Resume', name: "resume" },
  ]
  const filterArr = [
    { id: 1, name: 'vendor_id', label: 'vendor', options: vendors, optionName: 'vendorname', count: 'candidatecount' },
    { id: 2, name: 'status', label: 'status', options: status, optionName: 'statusname', },
    { id: 3, name: 'primarytechnology', label: 'technology', options: technologies, optionName: 'technologyname' },
    { id: 4, name: 'candidateAssigned', label: 'candidate', options: assignees, optionName: 'name' },
  ]
  const selectFilterCSS = "p-2 text-sm bg-white border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded outline-none mr-2 mb-2";

  return (
    <>
      {console.log(filters)}
      {filterArr.map((dropdown) => (
        <select
          name={dropdown.name}
          className={selectFilterCSS}
          value={filters[dropdown.name]}
          onChange={handleChange}
        >
          <option value="" disabled>Select {toTitleCase(dropdown.label)}</option>
          {dropdown.options?.map((opt) => (
            <option value={opt._id}>
              {opt[dropdown.optionName]}
              {!!dropdown.count && <span>({opt[dropdown.count]})</span>}
            </option>
          ))}
        </select>

      ))}
      <div className="flex items-center mr-2 mb-2">
        <label>From</label>
        <input
          name="startTime"
          className="p-2 text-black border ml-3 w-full"
          type="date"
          ref={startDateRef}
          value={filters.startTime}
          onChange={handleChange}
        />
      </div>
      <div className="flex items-center mr-2 mb-2">
        <label>To</label>
        <input
          name="endTime"
          className="p-2 text-black border ml-3 w-full"
          type="date"
          ref={endDateRef}
          value={filters.endTime}
          onChange={handleChange}
        />
      </div>
      <div className="flex items-center mr-2 mb-2">
        <label>Exp</label>
        <select
          name="operator"
          className="w-full mx-4"
          value={filters.operator}
          onChange={handleChange}
        >
          <option value="" selected disabled>operator</option>
          {expOperator.map((opt) => (
            <option key={opt.id} value={opt.value}>{opt.label}</option>
          ))}
        </select>
        <select
          name="yearofexperience"
          className="w-full"
          value={filters.yearofexperience}
          onChange={handleChange}
        >
          {generateOptions(1, 30)}
        </select>
      </div>
      <div className="flex items-center justify-around mb-2">
        <label>Missing:</label>
        <div className="ml-2 flex flex-col">
          {checkBoxes.map((item) => (
            <div key={item.id}>
              <input type="checkbox" name={item.name} checked={filters[item.name]} onChange={handleCheckbox} />
              <label className="ml-2">{item.label}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center gap-2 mb-2">
        <label>Includes:</label>
        <div className="ml-2 flex flex-col">
          <div>
            <input type="checkbox" name="videoIntro" checked={filters["videoIntro"]} onChange={handleCheckbox} />
            <label className="ml-2">Video</label>
          </div>
        </div>
      </div>
    </>
  )
}
