import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown ,FaArrowLeft} from "react-icons/fa";
import { DataContext } from "../../context/Datastate";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import Pagination from "../Pagination/Pagination";
import NoData from "../NoData/NoData";
import Loader from "../Loader/Loader";

const ViewCandidateList = () => {
  // const params = useParams();
  const { id } = useParams();
  const location = useLocation();
  const jobname = location.state;
  const {
    loader,
    setViewCandidate,
    viewcandidate,
    globalLoader,
    searchViewCandidateApi,
    viewCandidatePaginationApi,
    totalCount,
    pageCount,
  } = useContext(DataContext);
  const limit = 50;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);

  useEffect(() => {
    viewCandidatePaginationApi(1, id, limit);
  }, []);

  // const onKeyUpHandler = (e) => {
  //   searchViewCandidateApi(e.target.value);
  // };
  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...viewcandidate].sort((a, b) => {
      const valueA = (a[property] || "").toString();
      const valueB = (b[property] || "").toString();
      const comparison = valueA.localeCompare(valueB);
      return isAscending ? comparison : -comparison;
    });

    setViewCandidate(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };

  const validateUrl = (link) => {
    const validUrlPattern =
      /^(https?|http):\/\/[^\s/$?#]+(\.[^\s/$?#]+)*\.[^\s$]{2,}$/;

    return validUrlPattern.test(link);
  };

  return (
    <>
      {/* {deleteBtn ? (
        <DeleteModal deleteApi={deleteCandidateApi} deleteId={deleteBtnId} />
      ) : (
        ""
      )} */}

      <div className="flex w-full">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4 pt-4">
          <div className="flex">
            <Link to={"/applied-jobs"}>
              <div className="w-8  ">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            {/* <div className="pt-3 flex justify-between itms-start"> */}
            <div className="p-1">
              <h1 className="text-xl text-[#202223] font-semibold px-2">
                Applicants for {jobname}
              </h1>
            </div>
            {/* <div className="flex space-x-2 justify-between pt-2 items-start flex-wrap">
              <div className="flex gap-1"> */}
            {/* <Link to="/import-candidates">
                <button className="border border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                    Import Candidates</button>
                    </Link> */}
            {/* <Link to="/add_candidate">
                  <button className="border shadow-btnshd bg-theme-color text-white   px-4 py-2 rounded text-sm">
                    Add Candidate
                  </button>
                </Link> */}

            {/* <button className="border border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                  Export XLS
                </button>
                <Link to="/import_speak">
                  <button className="border border-[#BABFC3]   px-4 py-2 text-[#202223] font-medium bg-white  rounded  text-sm">
                    Import XLS
                  </button>
                </Link> */}
            {/* </div> */}
            {/* <div className="float-right flex">
                <FaSearch className="search-icon relative top-[0.7rem] left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div> */}
            {/* </div> */}
          </div>
          {/*--------- table list--------- */}
          {loader ? (
            <Loader />
          ) : viewcandidate?.length > 0 ? (
            <>
              <div className="h-[75vh] relative overflow-y-auto">
                <table className="w-full table-fixed bg-white border-collapse shadow-md rounded-lg  ">
                  <thead className="cursor-pointer sticky top-0 z-[4]">
                    <tr className=" text-left bg-theme-light text-theme-color text-xs font-normal  ">
                      <th className=" px-4 py-3 flex items-center">
                        <div className="flex items-center gap-4 w-[300px] ">
                          <p>Name</p>
                          <div className="px-2">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "name",
                                  "asc"
                                )}`}
                                onClick={() => sortData("name")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "name",
                                  "desc"
                                )}`}
                                onClick={() => sortData("name")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Linkedin Profile</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "linkedinprofile",
                                  "asc"
                                )}`}
                                onClick={() => sortData("linkedinprofile")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "linkedinprofile",
                                  "desc"
                                )}`}
                                onClick={() => sortData("linkedinprofile")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Resume Link</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "resumelink",
                                  "asc"
                                )}`}
                                onClick={() => sortData("resumelink")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "resumelink",
                                  "desc"
                                )}`}
                                onClick={() => sortData("resumelink")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      {/* <th className=" px-4 ">
                  <div className="flex items-center gap-4">
                    <p>Primary Technology</p>
                    <div className="">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%] ${getSortArrowClassName(
                            "primarytechnology",
                            "asc"
                          )}`}
                          onClick={() => sortData("primarytechnology")}
                        />
                        <FaSortDown
                          className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                            "primarytechnology",
                            "desc"
                          )}`}
                          onClick={() => sortData("primarytechnology")}
                        />
                      </span>
                    </div>
                  </div>
                </th> */}
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Experience</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "totalExp",
                                  "asc"
                                )}`}
                                onClick={() => sortData("totalExp")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "totalExp",
                                  "desc"
                                )}`}
                                onClick={() => sortData("totalExp")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>
                            Rel <br />
                            Exp.
                          </p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "relevantExp",
                                  "asc"
                                )}`}
                                onClick={() => sortData("relevantExp")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "relevantExp",
                                  "desc"
                                )}`}
                                onClick={() => sortData("relevantExp")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Rate</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "rate",
                                  "asc"
                                )}`}
                                onClick={() => sortData("rate")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "rate",
                                  "desc"
                                )}`}
                                onClick={() => sortData("rate")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Joining Time</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "joiningtime",
                                  "asc"
                                )}`}
                                onClick={() => sortData("joiningtime")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "joiningtime",
                                  "desc"
                                )}`}
                                onClick={() => sortData("joiningtime")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      {/* <th className=" px-4 py-2 text-center rounded-tr-lg">
                  Comments
                </th> */}
                      {/* <th className=" px-4 py-2 text-center rounded-tr-lg">
                  Action
                </th> */}
                    </tr>
                  </thead>
                  <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                    {viewcandidate?.map((item) => (
                      <tr className="border-t text-left text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  cursor-pointer break-word text-[16px]">
                        <td className="px-4 py-2">{item?.name}</td>
                        {/* <td className="px-4 py-2 ">
                        <a
                          className="underline cursor-pointer text-theme-color"
                          href={item?.linkedinprofile}
                          target="_blank"
                        >
                          Linkedin URL
                        </a>
                      </td> */}
                        <td className="px-4 py-2  ">
                          {validateUrl(item.linkedinprofile) ? (
                            <a
                              href={item.linkedinprofile}
                              className="text-theme-color underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Linkedin
                            </a>
                          ) : (
                            <p

                            // href=""
                            // onClick={() => toast("invalid url")}
                            // className="text-theme-color underline"
                            >
                              {/* Linkedin */}
                            </p>
                          )}
                        </td>
                        {/* <td className="px-4 py-2">
                        <a
                          className="underline cursor-pointer text-theme-color "
                          download
                          href={item?.resumelink}
                          target="_blank"
                        >
                          {" "}
                         Resume Link
                        </a>
                      </td> */}
                        <td className="px-4 py-2 ">
                          {validateUrl(item.resumelink) ? (
                            <a
                              href={item.resumelink}
                              className="text-theme-color underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Resume
                            </a>
                          ) : (
                            <p
                            // href=""
                            // onClick={() => toast("invalid url")}
                            // className="text-theme-color underline"
                            >
                              {/* Resume */}
                            </p>
                          )}
                        </td>
                        {/* <td className="px-4 py-2">{item?.primarytechnology?.technologyname}</td> */}
                        {/* <td className="px-4 py-2">{item?.yearofexperience}</td> */}
                        <td className="px-4 py-2">
                          {item.hasOwnProperty("totalExp")
                            ? `${item?.totalExp?.years}. ${item?.totalExp?.months}`
                            : "0.0"}
                        </td>
                        <td className="px-4 py-2">
                          {item.hasOwnProperty("relevantExp")
                            ? `${item?.relevantExp?.years}. ${item?.relevantExp?.months}`
                            : "0.0"}
                        </td>
                        <td className="px-4 py-2">{item?.rate}</td>
                        <td className="px-4 py-2">{item?.joiningtime}</td>
                        {/* <td className="px-4 py-2">{item?.comment}</td> */}
                        {/* <td className="px-4 py-2">
                    <div className="flex items-center  justify-center  gap-3 text-black ">
                    <FiEye className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full py-0 text-[17px]" onClick={()=> handleCandidateDialog(item)}/>
                      <Link to={`/update_candidate/${item?._id}`}>
                        <FiEdit2 className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-fullpy-0 text-[17px] " />
                      </Link>
                      <FiTrash
                        className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full py-0 text-[17px] "
                        onClick={() => {
                          setDeleteBtn(true);
                          setDeleteBtnId(item?._id);
                        }}
                      />
                    </div>
                  </td> */}
                      </tr>
                    ))}
                    {/*<tr className=" border-t text-left text-sm text-[#202223] font-normal group hover:shadow-tableRowsShadow cursor-pointer">
                  <td className=" px-4 py-2 rounded-bl-lg"><img src={profileImg} className="w-16 h-16 rounded-[2px]"/></td>
                  <td className=" px-4 py-2">Mr.</td>
                  <td   className="px-4 py-2">James Devine</td>
                  <td   className="px-4 py-2 ">Principal Solutions Architect</td>
                  <td   className="px-4 py-2 ">Amazon Web Services</td>
                  <td className='rounded-br-lg'> <div className="flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity text-[18px] gap-4 text-theme-color"> <FiTrash/> <FiEdit2/> </div></td>
                 
                    </tr>*/}
                  </tbody>
                </table>
              </div>
              {/* )} */}
              <div className="flex justify-between items-center ">
                <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${
                  pageCount === 1 ? totalCount : secondResultCount
                } of ${totalCount && totalCount} results`}</span>
                <Pagination
                  firstResultCount={firstResultCount}
                  setFirstResultCount={setFirstResultCount}
                  secondResultCount={secondResultCount}
                  setSecondResultCount={setSecondResultCount}
                  limit={limit}
                  api={viewCandidatePaginationApi}
                  id={id}
                />
              </div>
            </>
          ) : null}

          {((!loader && !viewcandidate) || viewcandidate.length == 0) && (
            <NoData />
          )}
        </div>
      </div>
    </>
  );
};

export default ViewCandidateList;
