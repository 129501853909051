import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { FaArrowLeft ,FaRegEye, FaRegEyeSlash} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { DataContext } from "../../../context/Datastate";

const UpdateSubAdmin = () => {
  const {
    // getVendorApi,
    subAdmin,
    updateSubAdminApi,
    // countryList,
    // getCountryApi,
    disableSave,
  } = useContext(DataContext);
  const [count, setCount] = useState(0);
  const [editSubAdmin, setEditSubAdmin] = useState({});
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  const { id } = useParams();
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleUpdate = (e) => {
    // if (!disableSave) 
    updateSubAdminApi(editSubAdmin, id);  
  };

  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const validateEmail = () => {
    if (!validEmail.test(editSubAdmin.email) && editSubAdmin.email !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const validatePhoneno = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setPhoneFormatTest(false);
    } else if (phoneData == "") {
      return setPhoneFormatTest(false);
    } else {
      return setPhoneFormatTest(true);
    }
  };

  const buttonDisabled =
  editSubAdmin.name !== "" &&
  editSubAdmin.phone !== "" && 
  editSubAdmin.email !== "" &&
  editSubAdmin.password !== "";


    function toTitleCase(str) {
      return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
      }).join(' ');
    }
    const validatePassword = () => {
        if (editSubAdmin.password === "") {
          setPasswordError(true);
        } else {
          setPasswordError(false);
        }
      };
  useEffect(() => {
    const newArr = subAdmin?.filter((element) => {
      return element?._id === id;
    });

    setEditSubAdmin({ ...newArr[0] });
  }, []);
  return (
    <>
      <div className="flex w-full bg-white">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex">
            <Link to={"/subadmin-list"}>
              <div className="w-8 ">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-1">
              <h1 className=" text-xl hover:bg-orange-400xl px-2">
               SubAdmin Info{" "}
              </h1>
            </div>
          </div>
          <div className="flex space-x-10">
            <div className="w-1/3 space-y-3">
              <h2 className="font-semibold text-lg">SubAdmin Details</h2>
              <hr />
              <div className="">
                <div>
                  <label htmlFor="name" className="text-sm px-1 font-semibold ">
                    Name *
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your name"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editSubAdmin.name}
                    onChange={(e) => {
                        setEditSubAdmin({ ...editSubAdmin, name:toTitleCase( e.target.value) });
                    }}
                  />
                </div>
                <div className="">
                <label
                  htmlFor="password"
                  className="text-sm px-1 font-semibold pb-1 relative block"
                >
                  Password *
                  <input
                    type={password === true ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Enter your password"
                    className="w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editSubAdmin.password}
                    onChange={(e) => {
                      setEditSubAdmin({
                        ...editSubAdmin,
                        password: e.target.value,
                      });
                    }}
                    onKeyUp={() => validatePassword()}
                  />
                  <span
                    className="absolute top-7 right-0 flex items-center pr-2 cursor-pointer"
                    onClick={() => setPassword(!password)}
                  >
                    {password === true ? (
                      <FaRegEye className="h-5 w-5 fill-theme-color" />
                    ) : (
                      <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                    )}
                  </span>
                </label>
              </div>

              {passwordError && (
                <small className="text-left text-red-600">Enter Password</small>
              )}
              </div>
            </div>
            <div className="w-1/3 pt-14">
              <div className="pb-2">
                <label
                  htmlFor="phone"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Phone *
                </label>
                <input
                  type="text"
                  maxlength={10}
                  pattern="[0-9]{10}"
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9"
                  value={editSubAdmin.phone}
                  onChange={(e) => {
                    setEditSubAdmin({
                      ...editSubAdmin,
                      phone: e.target.value,
                    });
                  }}
                  onKeyUp={() => validatePhoneno(editSubAdmin.phone)}
                />
                {phoneFormatTest ? (
                  <span className="text-red-600 text-sm">
                    Please enter a valid 10 digit Number.
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="w-1/3 pt-14">
              <div>
                <label
                  htmlFor="email"
                  className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
                >
                  Email *
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  autoComplete="off"
                  placeholder="Enter your email"
                  className={`w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9 ${
                    emailError ? "" : "mb-4"
                  } `}
                  value={editSubAdmin.email}
                  onChange={(e) => {
                    setEditSubAdmin({ ...editSubAdmin, email: e.target.value });
                  }}
                  onKeyUp={() => validateEmail()}
                />
                {emailError && (
                  <span className="text-left text-red-600 text-sm ">
                    Please enter a valid Email
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <Link to="/subadmin-list">
              <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>
            <button
              type="button"
              disabled={!buttonDisabled}
              className="bg-theme-color disabled:opacity-50 px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3"
              onClick={() => {
                handleUpdate();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateSubAdmin;
