import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { FiTrash, FiEdit2, FiEye } from "react-icons/fi";
import { DataContext } from "../../context/Datastate";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import DeleteModal from "../DeleteModal/DeleteModal";
import { ToastContainer,toast } from "react-toastify";
import Pagination from "../Pagination/Pagination";
import nodata from "../Assets/nodata.png";
import NoData from "../NoData/NoData";
import Loader from "../Loader/Loader";

const CandidateList = () => {
  const {
    loader,
    allTechnology,
    totalCount,
    pageCount,
    openCandidate,
    setOpenCandidate,
    getAllOpenCandidateApi,
    getAllTechnologyWithoutTokenApi
  } = useContext(DataContext);
  const limit = 300;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const [selectedTechnology, setSelectedTechnology] = useState("");

  const filteredCandidate = openCandidate
    .filter(
      (item) =>
        selectedTechnology === "" ||
        item?.primarytechnology?.technologyname === selectedTechnology
    );

  useEffect(() => {
    getAllOpenCandidateApi(1, limit);
    getAllTechnologyWithoutTokenApi();
  }, []);

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...openCandidate].sort((a, b) => {
      const valueA = (a[property] || "").toString();
      const valueB = (b[property] || "").toString();
      const comparison = valueA.localeCompare(valueB);
      return isAscending ? comparison : -comparison;
    });
    setOpenCandidate(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };

  const validateUrl = (link) => {
    const validUrlPattern =
      /^(https?|http):\/\/[^\s/$?#]+(\.[^\s/$?#]+)*\.[^\s$]{2,}$/;

    return validUrlPattern.test(link);
  };

  return (
    <>
      <div className="flex w-full">
        <div className="w-full px-28 py-2 overflow-y-scroll h-screen space-y-4">
          <div className="pt-3 flex justify-between itms-start">
            <h1 className="text-xl text-[#202223] pt-2 font-semibold">
              Active Candidates List
            </h1>
            <div className="flex space-x-2 justify-between pt-2 items-start flex-wrap">
              <div className="flex gap-1">
              <select
                  className="p-2 text-sm bg-transparent border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded outline-none"
                  value={selectedTechnology}
                  onChange={(e) => setSelectedTechnology(e.target.value)}
                >
                  <option value="">Select technology</option>
                  {allTechnology.map((item) => (
                    <option value={item.technologyname}>
                      {item.technologyname}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/*--------- table list--------- */}
          {loader ? (
            <Loader />
          ) : openCandidate.length > 0 ? (
            <>
              <div className="h-[75vh] relative overflow-y-auto">
                <table className="w-full table-auto bg-white border-collapse text-center shadow-md rounded-lg  ">
                  <thead className="cursor-pointer sticky top-0 z-[4]">
                    <tr className=" text-left bg-theme-light text-theme-color text-xs font-normal  ">
                      <th className=" px-4 py-3 flex items-center">
                        <div className="flex items-center gap-4">
                          <p>Name</p>
                          <div className="px-2">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "name",
                                  "asc"
                                )}`}
                                onClick={() => sortData("name")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "name",
                                  "desc"
                                )}`}
                                onClick={() => sortData("name")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      {/* <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Linkedin Profile</p>
                        </div>
                      </th> */}
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Resume Link</p>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Primary Technology</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "primarytechnology",
                                  "asc"
                                )}`}
                                onClick={() => sortData("primarytechnology")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "primarytechnology",
                                  "desc"
                                )}`}
                                onClick={() => sortData("primarytechnology")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Relevant Experience</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "relevantExp",
                                  "asc"
                                )}`}
                                onClick={() => sortData("relevantExp")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "relevantExp",
                                  "desc"
                                )}`}
                                onClick={() => sortData("relevantExp")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Joining Time</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "joiningtime",
                                  "asc"
                                )}`}
                                onClick={() => sortData("joiningtime")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "joiningtime",
                                  "desc"
                                )}`}
                                onClick={() => sortData("joiningtime")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                    {filteredCandidate?.map((item) => (
                      <tr className="border-t text-left text-sm text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  cursor-pointer break-word text-[16px]">
                        <td className="px-4 py-2">{item?.name}</td>
                        {/* <td className="px-4 py-2 ">
                          {validateUrl(item.linkedinprofile) ? (
                            <a
                              href={item.linkedinprofile}
                              className="text-theme-color underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              URL
                            </a>
                          ) : (
                            <p
                              href=""
                              onClick={() => toast("invalid url")}
                              className="text-theme-color underline"
                            >
                              URL
                            </p>
                          )}
                        </td> */}
                        {/* <td className="px-4 py-2">
                          {validateUrl(item.resumelink) ? (
                            <a
                              href={item.resumelink}
                              className="text-theme-color underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Resume
                            </a>
                          ) : (
                            <p
                              href=""
                              onClick={() => toast("invalid url")}
                              className="text-theme-color underline"
                            >
                              Resume
                            </p>
                          )}
                        </td> */}
                         <td className="px-4 py-2 ">
                          {validateUrl(item.resumelink) ? (
                            <a
                              href={item.resumelink}
                              className="text-theme-color underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Resume
                            </a>
                          ) : (
                            <p
                            // href=""
                            // onClick={() => toast("invalid url")}
                            // className="text-theme-color underline"
                            >
                            </p>
                          )}
                        </td>
                        <td className="px-4 py-2">{item?.primarytechnology?.technologyname}</td>
                        <td className="px-16 py-2">
                          {item.hasOwnProperty("relevantExp")
                            ? `${item?.relevantExp?.years}. ${item?.relevantExp?.months}`
                            : "0.0"}
                        </td>
                        <td className="px-4 py-2">{item?.joiningtime}</td>
                      </tr>
                    ))}
                    {/*<tr className=" border-t text-left text-sm text-[#202223] font-normal group hover:shadow-tableRowsShadow cursor-pointer">
              <td className=" px-4 py-2 rounded-bl-lg"><img src={profileImg} className="w-16 h-16 rounded-[2px]"/></td>
              <td className=" px-4 py-2">Mr.</td>
              <td   className="px-4 py-2">James Devine</td>
              <td   className="px-4 py-2 ">Principal Solutions Architect</td>
              <td   className="px-4 py-2 ">Amazon Web Services</td>
              <td className='rounded-br-lg'> <div className="flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity text-[18px] gap-4 text-theme-color"> <FiTrash/> <FiEdit2/> </div></td>
             
                </tr>*/}
                  </tbody>
                </table>
              </div>
              {/* )} */}
              <div className="flex justify-between items-center ">
                <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${
                  pageCount === 1 ? totalCount : secondResultCount
                } of ${totalCount && totalCount} results`}</span>
                <Pagination
                  firstResultCount={firstResultCount}
                  setFirstResultCount={setFirstResultCount}
                  secondResultCount={secondResultCount}
                  setSecondResultCount={setSecondResultCount}
                  limit={limit}
                  api={getAllOpenCandidateApi}
                />
              </div>

              <div>
                <span className="font-[150px"></span>
              </div>
            </>
          ) : null}
          {((!loader && !openCandidate) ||
            openCandidate.length == 0) && <NoData />}
        </div>
      </div>
    </>
  );
};

export default CandidateList;
