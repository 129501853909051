import React, { useEffect, useState } from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { motion } from "framer-motion";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import Loader from "../Loader/Loader";
import NoData from "../NoData/NoData";
import TableNoData from "../TableNoData/TableNodata";
import { truncateText } from "../../utils";

const CustomTable = (props) => {
  const {
    data,
    columns,
    totalPageCount,
    totalCount,
    handlePageClick,
    loading,
  } = props;

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 100; // Number of items per page
  const [startItem, setStartItem] = useState(0);
  const [endItem, setEndItem] = useState(0);

  //for showing the number of data in pagination
  const calculateRange = (pageNumber) => {
    const start = pageNumber * itemsPerPage + 1;
    const end = Math.min((pageNumber + 1) * itemsPerPage, totalCount);
    setStartItem(start);
    setEndItem(end);
  };

  useEffect(() => {
    calculateRange(currentPage);
  }, [currentPage, totalCount]); //initially total count is 0, therefore need to put it in dependency

  const handlePageChange = (pageNumber) => {
    handlePageClick(pageNumber);
    setCurrentPage(pageNumber.selected);
  };

  const sortedData = sortBy
    ? data.sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];
      if (sortOrder === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    })
    : data;

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const getSortArrowClassName = (property, direction) => {
    if (sortBy === property) {
      return direction === "asc" ? "text-black" : "text-gray-500";
    }
    return direction === "desc" ? "text-black" : "text-gray-500";
  };

  const paginationVariants = {
    hidden: {
      opacity: 0,
      y: 200,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        duration: 5,
      },
    },
  };

  return (
    <>
      <div className="h-[calc(100vh-28vh)] overflow-x-scroll">
        <table className="w-full overflow-y-auto table-auto bg-white border-collapse shadow-md rounded-lg">
          <thead className="sticky top-0 z-[4]">
            <tr className="bg-theme-light text-theme-color text-[13px] font-normal"> {/* [&_th]:whitespace-nowrap */}
              {columns.map((column, colIndex) => (
                <th
                  key={colIndex}
                  className={`p-3 first:rounded-tl-lg last:rounded-tr-lg ${!!column.sorting && "relative"}`}
                >
                  <div className="flex items-center gap-1">
                    <div>{column.label}</div>
                    {!!column.sorting && (
                      <div className="px-2 w-fit">
                        <FaSortUp
                          className={`top-[30%] absolute cursor-pointer ${getSortArrowClassName(
                            column.value,
                            "asc"
                          )}`}
                          onClick={() => handleSort(column.value)}
                        />
                        <FaSortDown
                          className={`top-[40%] absolute cursor-pointer ${getSortArrowClassName(
                            column.value,
                            "desc"
                          )}`}
                          onClick={() => handleSort(column.value)}
                        />
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB] overflow-y-scroll">
            {loading ? (
              <Loader />
            ) : !sortedData.length ? (
              <TableNoData />
            ) : (
              sortedData?.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className="border-t text-left text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow break-word text-[14px]"
                >
                  {columns.map((column, colIndex) => (
                    <td key={colIndex} className={`px-3 py-1 whitespace-nowrap w-fit`}>
                      {truncateText(row[column.value])}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination controls */}
      {!!totalPageCount && (
        <div className="flex justify-between items-center py-[10px]">
          <span className="font-[150] text-sm">
            showing {startItem} to {endItem} of {totalCount} results
          </span>
          <motion.div
            variants={paginationVariants}
            initial="hidden"
            animate="visible"
          >
            <ReactPaginate
              breakLabel={<span className="mr-4">...</span>}
              nextLabel={
                <span className="w-10 h-10 flex items-center justify-center bg-lightGray rounded-md">
                  <BsChevronRight />
                </span>
              }
              onPageChange={handlePageChange}
              pageRangeDisplayed={1}
              marginPagesDisplayed={2}
              pageCount={totalPageCount}
              previousLabel={
                <span className="w-10 h-10 flex items-center justify-center bg-lightGray rounded-md mr-4">
                  <BsChevronLeft />
                </span>
              }
              // forcePage={currentPage - 1}
              renderOnZeroPageCount={null}
              containerClassName="flex items-center justify-center "
              pageClassName="block border- border-solid border-lightGray   w-6 h-6 text-sm flex items-center justify-center rounded-full mr-4"
              activeClassName="bg-theme-color text-white"
            />
          </motion.div>
        </div>
      )}
    </>
  );
};

export default CustomTable;
