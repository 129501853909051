import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import { DataContext } from "../../../context/Datastate";
import GlobalLoader from "../../GlobalLoader/GlobalLoader";
import DeleteModal from "../../DeleteModal/DeleteModal";
import Pagination from "../../Pagination/Pagination";
import AddTechnologyModal from "./AddTechnologyModal";
import UpdateTechnologyModal from "./UpdateTechnologyModal";
import EnableModal from "../../EnableDisableModal/EnableModal";
// import nodata from "../../Assets/nodata.png" ;
import Loader from "../../Loader/Loader";
import NoData from "../../NoData/NoData";

const TechnologyList = () => {
  const {
    addtechModal,
    setAddTechModal,
    updatetechModal,
    setUpdateTechModal,
    setTechnology,
    technology,
    loader,
    globalLoader,
    deleteBtn,
    setDeleteBtn,
    setDeleteBtnId,
    deleteBtnId,
    deleteTechnologyApi,
    searchTechnologyApi,
    technologyPaginationApi,
    totalCount,
    pageCount,
    updateTechnologyEnableApi,
    singleTechnology, setSingleTechnology,

  } = useContext(DataContext);
  
  const limit = 15;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const [enableTechnologyId,setEnableTechnologyId] = useState("");
  const [updateId, setUpdateId] = useState({})
  const [enableModal, setEnableModal] = useState(false);
  
  
  useEffect(() => {
    technologyPaginationApi(1, limit);
  }, []);

  const onKeyUpHandler = (e) => {
    const inputValue = e.target.value;

    // Update search input state
    setSearchInput(inputValue);

    // Check if the length of the input is at least 4 characters or it's empty
    if (inputValue.length >= 4 || inputValue.length === 0) {
      searchTechnologyApi(inputValue);
    }
  };
  // const onKeyUpHandler = (e) => {
  //   searchTechnologyApi(e.target.value);
  // };

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

      const sortedData = [...technology].sort((a, b) => {
      const comparison = a[property].localeCompare(b[property]);
      return isAscending ? comparison : -comparison;
    });

    setTechnology(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };
  const handleUpdateTechnology=(item) =>{
    setUpdateTechModal(true);
    setSingleTechnology(item);

  }

  const handleTechnologyDisabled = (item)=>{
    updateTechnologyEnableApi(item?._id);
    setEnableModal(false);
  }
  
  
  return (
    <>
      {deleteBtn ? (
        <DeleteModal deleteApi={deleteTechnologyApi} deleteId={deleteBtnId} />
        
      ) : (
        ""
      )}
      {addtechModal ? <AddTechnologyModal  setAddTechModal={setAddTechModal } addtechModal={addtechModal}/>  : null}
      {updatetechModal ? <UpdateTechnologyModal
        setUpdateTechModal={setUpdateTechModal }
         updatetechModal={updatetechModal}
         setUpdateId={setUpdateId}
          updateId={updateId}
         />  : null}
         {enableModal ? <EnableModal 
      item={singleTechnology} setEnableModal={setEnableModal} apiFunction= {handleTechnologyDisabled} 
       /> : null}
    
      <div className="flex w-full">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
        <div className="pt-3 flex justify-between items-start">
            <h1 className="text-xl text-[#202223] font-semibold">
              Technology List
            </h1>
            <div className=" flex justify-between space-x-2  items-start flex-wrap">
              <div className="flex gap-1">
                
                  <button className="border shadow-btnshd bg-theme-color text-white   px-4 py-2 rounded text-sm" onClick={()=> setAddTechModal(true)}>
                    Add New Technology
                  </button>
                

                {/* <button className="border border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                  Export XLS
                </button>
                <Link to="/import_speak">
                  <button className="border border-[#BABFC3]   px-4 py-2 text-[#202223] font-medium bg-white  rounded  text-sm">
                    Import XLS
                  </button>
                </Link> */}
              </div>
              <div className="float-right flex">
                <FaSearch className="search-icon relative top-[0.7rem] left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div>
          </div>
          {/*--------- table list--------- */}
          {/* {globalLoader ? (
            <GlobalLoader />
          ) : ( */}
          {loader ? <Loader/> : technology.length > 0 ? (
            <>
          <div className="h-[80vh] relative overflow-y-auto">
          <table className="w-full table-auto border-collapse bg-white shadow-md rounded-lg  ">
          <thead className="cursor-pointer sticky top-0 z-[4]">
              <tr className="text-left bg-theme-light text-theme-color text-xs font-normal ">
              {/* <th className=" px-4 py-2 relative flex items-center">
                  <div className="flex items-center gap-4">
                  <p>Technology Code</p>
                    <div className="">
                      <span className="">
                        <FaSortUp
                          className={`${getSortArrowClassName(
                            "technologycode",
                            "asc"
                          )}`}
                          onClick={() => sortData("technologycode")}
                        />
                        <FaSortDown
                          className={`mt-[-8px] ${getSortArrowClassName(
                            "technologycode",
                            "desc"
                          )}`}
                          onClick={() => sortData("technologycode")}
                        />
                      </span>
                    </div>
                  </div>
                </th> */}
                <th className=" px-4 py-2 relative flex items-center">
                  <div className="flex items-center gap-4">
                    <p>Technology Name</p>
                    <div className="">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%]  ${getSortArrowClassName(
                            "technologyname",
                            "asc"
                          )}`}
                          onClick={() => sortData("technologyname")}
                        />
                        <FaSortDown
                          className={`mt-[-8px] ${getSortArrowClassName(
                            "technologyname",
                            "desc"
                          )}`}
                          onClick={() => sortData("technologyname")}
                        />
                      </span>
                    </div>
                  </div>
                </th>
                
                <th className=" px-4 py-2 relative">
                  <div className="flex items-center gap-4">
                    <p>Created At</p>
                    <div className="">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%]  ${getSortArrowClassName(
                            "createdAt",
                            "asc"
                          )}`}
                          onClick={() => sortData("createdAt")}
                        />
                        <FaSortDown
                          className={`mt-[-8px] ${getSortArrowClassName(
                            "createdAt",
                            "desc"
                          )}`}
                          onClick={() => sortData("createdAt")}
                        />
                      </span>
                    </div>
                  </div>
                </th>
                <th className=" px-4 py-2 relative">
                  <div className="flex items-center gap-4">
                    <p>Updated At</p>
                    <div className="">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%]  ${getSortArrowClassName(
                            "updatedAt",
                            "asc"
                          )}`}
                          onClick={() => sortData("updatedAt")}
                        />
                        <FaSortDown
                          className={`mt-[-8px] ${getSortArrowClassName(  
                            "updatedAt",
                            "desc"
                          )}`}
                          onClick={() => sortData("updatedAt")}
                        />
                      </span>
                    </div>
                  </div>
                </th>

                <th className=" px-4 py-2 text-center rounded-tr-lg">Action</th>
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
              {technology?.map((item) => (
                <tr className="border-t text-left text-[14px] text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  cursor-pointer">
                  {/* <td className="px-4 py-2">{item?.technologycode}</td> */}
                  <td className="px-4 py-2">{item?.technologyname}</td>
                  <td className="px-4 py-2">{new Date(item?.createdAt).toLocaleDateString("en-GB")}</td>
                  <td className="px-4 py-2">{new Date(item?.updatedAt).toLocaleDateString("en-GB")}</td>
                  <td className="px-4 py-2">
                    <div className="flex items-center  justify-center  gap-2 text-black ">
                        <FiEdit2 className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] " onClick={()=>{handleUpdateTechnology(item)}}/>
                        
                      <FiTrash
                        className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                        onClick={() => {
                          setDeleteBtn(true);
                          setDeleteBtnId(item?._id);
                        }}
                      />
                      <span>
                        <label title={item.isActive ? "disable" : "enable"} class="flex items-center cursor-pointer">
                          <div class="relative" >
                            <input type="checkbox" title={item.isActive ? "disable" : "enable"} className="sr-only dot"
                            checked = {item?.isActive}
                            onClick={()=>{ 
                              setEnableModal(true)
                              setSingleTechnology(item);
                             
                              
                            }}
                            />
                            <div class="w-[24px] h-[11px] bg-gray-400 rounded-full shadow-inner"></div>
                            <div class="dot vend_act absolute w-[18px] h-[18px] bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                          </div>
                        </label>
                      </span>
                      
                    </div>
                  </td>
                  
                </tr>
              ))}
              
            </tbody>
          </table>
          </div>
          <div className="flex justify-between items-center ">
            <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${
              pageCount === 1 ? totalCount : secondResultCount
            } of ${totalCount} results`}</span>
            <Pagination
              firstResultCount={firstResultCount}
              setFirstResultCount={setFirstResultCount}
              secondResultCount={secondResultCount}
              setSecondResultCount={setSecondResultCount}
              limit={limit}
              api={technologyPaginationApi}
            />
          </div>
          </>
              ): null }
           
           
          {(!loader && !technology || technology.length == 0) && <NoData/>}
          {/* )} */}
          
        </div>
      </div>
    </>
  );
};

export default TechnologyList;
