export const candidateTableHeader = [
  // { label: "Vendor", value: "vendor_name", sorting: true },
  // { label: "Candidate assigned", value: "candidateAssigned", sorting: true },
  { label: "Name", value: "name", sorting: true },
  { label: "LinkedIn", value: "linkedinprofile" },
  { label: "Resume", value: "resume" },
  { label: "Primary Tech.", value: "primarytechnology", sorting: true },
  { label: "RelativeExp.", value: "relevantExp", sorting: true },
  // { label: "Relevant Exp.", value: "relevantExp", sorting: true },

  { label: "Mobile", value: "phonenumber", sorting: true },
  { label: "Location", value: "location", sorting: true },

  // { label: "Submission", value: "updatedAt" },
  { label: "Cmts", value: "commentsHistory", sorting: true },
  { label: "Cctc", value: "ctc", sorting: true },
  { label: "Ectc", value: "ectc", sorting: true },


  { label: "Availability", value: "joiningtime", sorting: true },
  { label: "Actions", value: "actions" },
];
