import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import { DataContext } from "../../context/Datastate";
import Pagination from "../Pagination/Pagination";
// import { useParams } from 'react-router-dom';

const Candidates = () => {
  const limit = 100;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const [currentVendor, setCurrentVendor] = useState({});
  const {
    candidate,
    candidatePaginationApi,
    searchCandidateApi,
    setCandidate,
    pageCount,
    totalCount,
    vendorCandidateApi,
    candidatebyvendor,
    searchCandidateByVendorApi,
    vendor,
    setCurrentPage,
  } = useContext(DataContext);
  const { id } = useParams();

  useEffect(() => {
    setCurrentPage(1);
    vendorCandidateApi(1, id, limit);
  }, []);

  const onKeyUpHandler = (e) => {
    const inputValue = e.target.value;

    // Update search input state
    setSearchInput(inputValue);

    // Check if the length of the input is at least 4 characters or it's empty
    if (inputValue.length >= 4 || inputValue.length === 0) {
      searchCandidateByVendorApi(inputValue, id);
    }
  };

  // const onKeyUpHandler = (e) => {
  //   searchCandidateByVendorApi(e.target.value, id);
  // };

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...candidate].sort((a, b) => {
      const comparison = a[property].localeCompare(b[property]);
      return isAscending ? comparison : -comparison;
    });

    setCandidate(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };

  const validateUrl = (link) => {
    const validUrlPattern =
      /^(https?|http):\/\/[^\s/$?#]+(\.[^\s/$?#]+)*\.[^\s$]{2,}$/;

    return validUrlPattern.test(link);
  };

  return (
    <div className="flex w-full">
      <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
        <div className="pt-3 ">
          <div className=" flex justify-between space-x-2 pt-4 items-start flex-wrap">
            <h1 className="text-xl text-[#202223] font-semibold">
              <span className="">
                {candidatebyvendor[0]?.vendor_id?.vendorname}
              </span>{" "}
              Candidates
            </h1>
            <div className="flex ml-auto">
              <FaSearch className="search-icon relative top-[0.7rem] left-6 text-gray-500 " />
              <input
                type="text"
                placeholder="Search"
                className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                onKeyUp={(e) => {
                  onKeyUpHandler(e);
                }}
              />
            </div>
          </div>
        </div>
        {/*--------- table list--------- */}
        {/* {globalLoader ? (
        <GlobalLoader />
      ) : ( */}
        <div className="h-[75vh] relative overflow-y-auto">
          <table className="w-full table-auto bg-white  border-collapse shadow-md rounded-lg  ">
            <thead className="cursor-pointer sticky top-0 z-[5]">
              <tr className=" text-left bg-theme-light text-theme-color text-xs font-normal  ">
                <th className=" px-4 py-2 flex items-center">
                  <div className="flex items-center gap-4">
                    <p>Name</p>
                    <div className="px-2">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%] ${getSortArrowClassName(
                            "name",
                            "asc"
                          )}`}
                          onClick={() => sortData("name")}
                        />
                        <FaSortDown
                          className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                            "name",
                            "desc"
                          )}`}
                          onClick={() => sortData("name")}
                        />
                      </span>
                    </div>
                  </div>
                </th>
                <th className=" px-4 ">
                  <div className="flex items-center gap-4">
                    <p>Linkedin Profile</p>
                    {/* <div className="">
                    <span className="">
                      <FaSortUp
                        className={`top-[30%] ${getSortArrowClassName(
                          "linkedinprofile",
                          "asc"
                        )}`}
                        onClick={() => sortData("linkedinprofile")}
                      />
                      <FaSortDown
                        className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                          "linkedinprofile",
                          "desc"
                        )}`}
                        onClick={() => sortData("linkedinprofile")}
                      />
                    </span>
                  </div> */}
                  </div>
                </th>
                <th className=" px-4 ">
                  <div className="flex items-center gap-4">
                    <p>Resume Link</p>
                    {/* <div className="">
                    <span className="">
                      <FaSortUp
                        className={`top-[30%] ${getSortArrowClassName(
                          "resumelink",
                          "asc"
                        )}`}
                        onClick={() => sortData("resumelink")}
                      />
                      <FaSortDown
                        className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                          "resumelink",
                          "desc"
                        )}`}
                        onClick={() => sortData("resumelink")}
                      />
                    </span>
                  </div> */}
                  </div>
                </th>
                <th className=" px-4 ">
                  <div className="flex items-center gap-4">
                    <p>Primary Technology</p>
                    <div className="">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%] ${getSortArrowClassName(
                            "primarytechnology",
                            "asc"
                          )}`}
                          onClick={() => sortData("primarytechnology")}
                        />
                        <FaSortDown
                          className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                            "primarytechnology",
                            "desc"
                          )}`}
                          onClick={() => sortData("primarytechnology")}
                        />
                      </span>
                    </div>
                  </div>
                </th>

                {/* <th className=" px-4 py-2 relative">
              Year of experience
                <div className="px-[92px] ">
                  <span className="">
                    <FaSortUp
                      className={`top-[30%] ${getSortArrowClassName(
                        "yearofexperience",
                        "asc"
                      )}`}
                      onClick={() => sortData("yearofexperience")}
                    />
                    <FaSortDown
                      className={`top-[40%] ${getSortArrowClassName(
                        "yearofexperience",
                        "desc"
                      )}`}
                      onClick={() => sortData("yearofexperience")}
                    />
                  </span>
                </div>
              </th> */}
                <th className=" px-4 ">
                  <div className="flex items-center gap-4">
                    <p>Phone Number</p>
                    <div className="">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%] ${getSortArrowClassName(
                            "phonenumber",
                            "asc"
                          )}`}
                          onClick={() => sortData("phonenumber")}
                        />
                        <FaSortDown
                          className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                            "phonenumber",
                            "desc"
                          )}`}
                          onClick={() => sortData("phonenumber")}
                        />
                      </span>
                    </div>
                  </div>
                </th>
                <th className=" px-4 ">
                  <div className="flex items-center gap-4">
                    <p>Rate</p>
                    <div className="">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%] ${getSortArrowClassName(
                            "rate",
                            "asc"
                          )}`}
                          onClick={() => sortData("rate")}
                        />
                        <FaSortDown
                          className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                            "rate",
                            "desc"
                          )}`}
                          onClick={() => sortData("rate")}
                        />
                      </span>
                    </div>
                  </div>
                </th>
                <th className=" px-4 ">
                  <div className="flex items-center gap-4">
                    <p>Joining Time</p>
                    <div className="">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%] ${getSortArrowClassName(
                            "joiningtime",
                            "asc"
                          )}`}
                          onClick={() => sortData("joiningtime")}
                        />
                        <FaSortDown
                          className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                            "joiningtime",
                            "desc"
                          )}`}
                          onClick={() => sortData("joiningtime")}
                        />
                      </span>
                    </div>
                  </div>
                </th>
                <th className=" px-4 py-2 text-center rounded-tr-lg">Action</th>
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
              {candidatebyvendor?.map((item) => (
                <tr className="border-t text-left text-sm text-[#202223] relative hover:z-[4] font-normal hover:shadow-tableRowsShadow  cursor-pointer break-word text-[16px]">
                  <td className="px-4 py-2">{item?.name}</td>
                  {/* <td className="px-4 py-2">{item?.linkedinprofile}</td> */}
                  <td className="px-4 py-2  ">
                    {validateUrl(item.linkedinprofile) ? (
                      <a
                        href={item.linkedinprofile}
                        className="text-theme-color underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Linkedin
                      </a>
                    ) : (
                      <p

                      // href=""
                      // onClick={() => toast("invalid url")}
                      // className="text-theme-color underline"
                      >
                        {/* Linkedin */}
                      </p>
                    )}
                  </td>
                  {/* <td className="px-4 py-2">{item?.resumelink}</td> */}
                  <td className="px-4 py-2 ">
                    {validateUrl(item.resumelink) ? (
                      <a
                        href={item.resumelink}
                        className="text-theme-color underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Resume
                      </a>
                    ) : (
                      <p
                      // href=""
                      // onClick={() => toast("invalid url")}
                      // className="text-theme-color underline"
                      >
                        {/* Resume */}
                      </p>
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {item?.primarytechnology?.technologyname}
                  </td>
                  {/* <td className="px-4 py-2">{item?.yearofexperience}</td> */}
                  <td className="px-4 py-2">{item?.phonenumber}</td>
                  <td className="px-4 py-2">{item?.rate}</td>
                  <td className="px-4 py-2">{item?.joiningtime}</td>
                  <td className="px-2 py-2">
                    <div className="flex items-center  justify-center text-black ">
                      <Link
                        to={`/update-candidate/${item.vendor_id?._id}/${item?._id}`}
                        state={{ item, from: "vendor_candidatelist" }}
                      >
                        <FiEdit2
                          title="view details"
                          className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]"
                        />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* )} */}
        <div className="flex justify-between items-center ">
          <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${
            pageCount === 1 ? totalCount : secondResultCount
          } of ${totalCount} results`}</span>
          <Pagination
            firstResultCount={firstResultCount}
            setFirstResultCount={setFirstResultCount}
            secondResultCount={secondResultCount}
            setSecondResultCount={setSecondResultCount}
            limit={limit}
            api={vendorCandidateApi}
            id={id}
          />
        </div>
        <div>
          <span className="font-[150px"></span>
        </div>
      </div>
    </div>
  );
};

export default Candidates;
