export const jobsTableHeader = [
  { label: "Job title", value: "jobtitle", sorting: true },
  { label: "Experience", value: "totalExp", sorting: true },
  { label: "Position", value: "positions", sorting: true },
  { label: "Work nature", value: "worknature", sorting: true },
  { label: "Location", value: "location", sorting: true },
  { label: "Rate", value: "rate", sorting: true },
  // { label: "Assigned to", value: "assignedTo", sorting: true },
  { label: "Priority", value: "priority", sorting: true },
  { label: "Client", value: "client", sorting: true },
  { label: "Actions", value: "actions" },
];
