import { toast } from "react-toastify";

export let BASE_URL = ``

const unauthorisedError = "Session timed out";
// const token = JSON.parse(localStorage.getItem("adminToken")) || "";
let url = window.location.hostname;
console.log("+++",url)
if(url.includes("admin")){
   BASE_URL = "https://api.getdeveloper.in/";
}
else if (url.includes("localhost")){
  BASE_URL="http://localhost:4000/"
  console.log("---+++++=",BASE_URL)
}

else{
  BASE_URL=`http://184.72.222.95:4000/`
  
}

const getToken = () => {
  if (localStorage.getItem("adminToken")) {
    return JSON.parse(localStorage.getItem("adminToken"));
  }
};
//change 
const mergeQuery = (queryParams) => {
  if (queryParams) {
    const queryString = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        queryString.append(key, value);
      }
    });
    return `?${queryString.toString()}`;
  }
  return "";
};

const fetchUtil = {

  GET: (endPoint, query) => {
    return new Promise((res, rej) => {
      const url = BASE_URL + endPoint + mergeQuery(query);
      // const url = BASE_URL + endPoint;
      const headers = {
        "Content-Type": "application/json",
        token: getToken(),
      };
      fetch(url, {
        method: "GET",
        mode: "cors",
        headers: headers,
        redirect: "follow",
      })
        .then((response) => {
          if (response.statusCode === 401) {
            toast.error(unauthorisedError);
          } else return response.json();
        })
        .then((data) => {
          if (data.statusCode === 200) {
            res(data);
          } else {
            rej(data.message);
          }
        })
        .catch((err) => {
          rej(err.message);
        });
    });
  },
  POST: (endPoint, payload, payloadType, query) => {
    return new Promise((res, rej) => {
      // const url = BASE_URL + endPoint + JSON.stringify(query) || "";
      const url = BASE_URL + endPoint;
      const modifiedPayload =
        payloadType === "formData" ? payload : JSON.stringify(payload);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        token: getToken(),
      };
      const formHeaders = {
        token: getToken(),
      };
      const modifiedHeaders =
        payloadType === "formData" ? formHeaders : headers;
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: modifiedHeaders,
        body: modifiedPayload,
        redirect: "follow",
      };
      fetch(url, requestOptions)
        .then((response) => {
          if (response.statusCode === 401) {
            toast.error(unauthorisedError);
          } else return response.json();
        })
        .then((data) => {
          if (data.statusCode === 200) {
            res(data);
          } else {
            rej(data.message);
          }
        })
        .catch((err) => {
          rej(err.message);
        });
    });
  },
  PUT: (endPoint, payload, payloadType, query) => {
    return new Promise((res, rej) => {
      // const url = BASE_URL + endPoint + JSON.stringify(query) || "";
      const url = BASE_URL + endPoint;
      const modifiedPayload =
        payloadType === "formData" ? payload : JSON.stringify(payload);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        token: getToken(),
      };
      const formHeaders = {
        token: getToken(),
      };
      const modifiedHeaders =
        payloadType === "formData" ? formHeaders : headers;
      const requestOptions = {
        method: "PUT",
        mode: "cors",
        headers: modifiedHeaders,
        body: modifiedPayload,
        redirect: "follow",
      };
      fetch(url, requestOptions)
        .then((response) => {
          if (response.statusCode === 401) {
            toast.error(unauthorisedError);
          } else return response.json();
        })
        .then((data) => {
          if (data.statusCode === 200) {
            res(data);
          } else {
            rej(data.message);
          }
        })
        .catch((err) => {
          rej(err.message);
        });
    });
  },
  DELETE: (endPoint, query) => {
    return new Promise((res, rej) => {
      // const url = BASE_URL + endPoint + JSON.stringify(query) || "";
      const url = BASE_URL + endPoint;
      const headers = {
        "Content-Type": "application/json",
        token: getToken(),
      };
      fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers: headers,
        redirect: "follow",
      })
        .then((response) => {
          if (response.statusCode === 401) {
            toast.error(unauthorisedError);
          } else return response.json();
        })
        .then((data) => {
          if (data.statusCode === 200) {
            res(data);
          } else {
            rej(data.message);
          }
        })
        .catch((err) => {
          rej(err.message);
        });
    });
  },
};

export default fetchUtil;
