import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FiTrash, FiEdit2, FiEye } from "react-icons/fi";
import { DataContext } from "../../context/Datastate";
import EnableModal from "../EnableDisableModal/EnableModal";
import CustomTable from "../atoms/CustomTable";
import {
  CHANGE_JOB_STATUS,
  DELETE_JOB_BY_ID,
  GET_ALL_CITIES,
  GET_ALL_CLIENTS,
  GET_ALL_JOBS,
  GET_ALL_TECHNOLOGIES,
} from "../../constants/apiEndPoints";
import fetchUtil from "../../utils/fetch.util";
import {
  copyTextToClipboard,
  countNonEmptyValues,
  filteredData,
  mergeYearAndMonth,
  toTitleCase,
} from "../../utils";
import { jobsTableHeader } from "./tableHeader";
import RightSideLayout from "../Layout/RightSideLayout";
import CustomFilter from "../atoms/CustomFilter";
import ConfirmModal from "../atoms/ConfirmModal";
import { toast } from "react-toastify";
import { CiLink } from "react-icons/ci";
import { FiLink } from "react-icons/fi";
import { WhatsappShareButton } from "react-share";
import { WhatsappIcon } from "react-share";

const workNature = [
  { _id: "work from office", value: "work from office", label: "WFO" },
  { _id: "work from home", value: "work from home", label: "WFH" },
  { _id: "hybrid", value: "hybrid", label: "Hybrid" },
];
const priorities = [
  { _id: "low", value: "low", label: "Low" },
  { _id: "medium", value: "medium", label: "Medium" },
  { _id: "high", value: "high", label: "High" },
  { _id: "hot", value: "hot", label: "Hot" },
];
const statuss = [
  { _id: true, value: "enabled", label: "Enabled jobs" },
  // { _id: "", value: "showAll", label: 'Show all' },
  { _id: false, value: "disabled", label: "Disabled jobs" },
];

const JobList = (props) => {
  const { filters, setFilters, initialStateOfFilters } = props;
  const recordsPerPage = 100;
  const firstPage = 1;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowID, setRowID] = useState("");
  const [actionName, setActionName] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [jobsData, setJobsData] = useState({
    pageCount: "",
    totalCount: "",
    list: [],
  });

  const [locations, setLocations] = useState([]);
  const [technologies, setTechnnologies] = useState([]);
  const [clients, setClients] = useState([]);

  let queryParams = {
    page: firstPage,
    limit: recordsPerPage,
    ...filters,
  };

  const fetchAllData = async () => {
    try {
      const [response1, response2, response3] = await Promise.all([
        fetchUtil.GET(GET_ALL_CITIES),
        fetchUtil.GET(GET_ALL_TECHNOLOGIES),
        fetchUtil.GET(GET_ALL_CLIENTS),
      ]);
      setLocations(filteredData(response1.result));
      setTechnnologies(filteredData(response2.result));
      setClients(filteredData(response3.result));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchJobs = (apiEndPoint, query) => {
    setLoading(true);
    fetchUtil
      .GET(apiEndPoint, query)
      .then((response) => {
        setJobsData((prev) => ({
          ...prev,
          pageCount: response.pageCount,
          totalCount: response.totalCount,
          list: response.result,
        }));
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const handleAction = (e) => {
    setIsModalOpen(false);
    if (e.target.name === "delete") {
      setLoading(true);
      fetchUtil
        .DELETE(`${DELETE_JOB_BY_ID}/${rowID}`)
        .then(() => {
          toast.success("Job deleted");
          fetchJobs(GET_ALL_JOBS, queryParams);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    } else if (e.target.name === "enable" || e.target.name === "disable") {
      setLoading(true);
      fetchUtil
        .PUT(`${CHANGE_JOB_STATUS}/${rowID}`)
        .then(() => {
          toast.success("Job status changed");
          fetchJobs(GET_ALL_JOBS, queryParams);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchJobs(GET_ALL_JOBS, queryParams);
    fetchAllData();
  }, []);

  useEffect(() => {
    if (countNonEmptyValues(filters)) {
      setFilterCount(countNonEmptyValues(filters));
    }
  }, [filters]);

  const handlePageClick = (e) => {
    queryParams.page = e.selected + 1;
    fetchJobs(GET_ALL_JOBS, queryParams);
  };

  const filterData = () => {
    fetchJobs(GET_ALL_JOBS, queryParams);
  };

  const handleClear = () => {
    setFilters(initialStateOfFilters);
    fetchJobs(GET_ALL_JOBS, { page: firstPage, limit: recordsPerPage });
    setFilterCount(0);
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value.length >= 4 || e.target.value.length === 0) {
      queryParams.key = e.target.value;
      setLoading(true);
      setTimeout(() => {
        fetchUtil
          .GET(GET_ALL_JOBS, queryParams)
          .then((response) => {
            console.log(response.result);
            setJobsData((prev) => ({
              ...prev,
              pageCount: response.pageCount,
              totalCount: response.totalCount,
              list: response.result,
            }));
          })
          .catch((err) => console.error(err))
          .finally(() => setLoading(false));
      }, 1000);
    }
  };
  const handleModal = (id, name) => {
    setIsModalOpen(true);
    setRowID(id);
    setActionName(name);
  };

  const jobUrl = (jobId) => {
    const job_url = `https://user.getdeveloper.in/apply/${jobId}`;
    return copyTextToClipboard(job_url);
  };

  const renderAction = (rowData) => {
    const iconsCss =
      "hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] cursor-pointer";
    return (
      <div className="flex items-center justify-center text-black ">
        <WhatsappShareButton
          url={`https://user.getdeveloper.in/apply/${rowData?._id}`}
        >
          <WhatsappIcon size={22} round />
        </WhatsappShareButton>
        <FiLink
          title="job link"
          className={iconsCss}
          onClick={() => jobUrl(rowData?._id)}
        />
        <FiEye
          title="view logs"
          className={iconsCss}
          onClick={() => navigate(`/jobs/logs/${rowData?._id}`)}
        />
        <FiEdit2
          title="view details"
          className={iconsCss}
          onClick={() => navigate(`/jobs/update/${rowData?._id}`)}
        />
        <FiTrash
          className={iconsCss}
          title="delete candidate"
          onClick={() => handleModal(rowData?._id, "delete")}
        />
        <label class="flex items-center cursor-pointer">
          <div class="relative" title="Active">
            <input
              type="checkbox"
              className="sr-only dot"
              checked={rowData?.isActive}
              onClick={() =>
                handleModal(
                  rowData?._id,
                  rowData?.isActive ? "disable" : "enable"
                )
              }
            />
            <div class="w-[24px] h-[11px] bg-gray-400 rounded-full shadow-inner"></div>
            <div class="dot vend_act absolute w-[18px] h-[18px] bg-white rounded-full shadow -left-1 -top-1 transition"></div>
          </div>
        </label>
      </div>
    );
  };

  const assignInitials = (worknature) => {
    switch (worknature.toLowerCase()) {
      case "work from office":
        return "WFO";
      case "work from home":
        return "WFH";
      case "hybrid":
        return "Hybrid";
      default:
        return "";
    }
  };
  const modifiedData =
    jobsData.list.length > 0 &&
    jobsData.list?.map((item) => ({
      ...item,
      worknature: assignInitials(item.worknature),
      assignedTo: item.assignedTo?.name,
      client: item.client?.name,
      technology: item.technology?.technologyname,
      location: item.location.join(", "),
      totalExp: mergeYearAndMonth(item?.totalExp),
      actions: renderAction(item),
    }));

  return (
    <>
      <RightSideLayout pageTitle={"Job List"}>
        <RightSideLayout.TopSectionLayout>
          <div className="flex gap-2">
            <button
              className="border shadow-btnshd bg-theme-color text-white px-4 py-2 rounded text-sm"
              onClick={() => navigate("/jobs/add")}
            >
              Add New Job
            </button>
            <button
              className="border shadow-btnshd px-4 py-2 rounded text-sm"
              onClick={() =>
                copyTextToClipboard(`https://user.getdeveloper.in/job_apply`)
              }
            >
              Copy open job url
            </button>
          </div>
          <div className="flex">
            <div className="flex items-center relative">
              <CustomFilter
                filterCount={filterCount}
                onSubmit={filterData}
                onReset={handleClear}
              >
                <FilterModal
                  filters={filters}
                  setFilters={setFilters}
                  setFilterCount={setFilterCount}
                  technologies={technologies}
                  locations={locations}
                  clients={clients}
                  worknature={workNature}
                  priorities={priorities}
                  statuss={statuss}
                />
              </CustomFilter>
            </div>
            <div className="relative ml-5">
              <FaSearch className="search-icon absolute top-[0.7rem] left-2 text-gray-500 " />
              <input
                type="text"
                placeholder="Search"
                className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                value={searchInput}
                onChange={handleSearch}
              />
            </div>
          </div>
        </RightSideLayout.TopSectionLayout>
        <CustomTable
          data={modifiedData}
          columns={jobsTableHeader}
          totalPageCount={jobsData.pageCount}
          totalCount={jobsData.totalCount}
          handlePageClick={handlePageClick}
          loading={loading}
        />
      </RightSideLayout>
      <ConfirmModal
        isModalOpen={isModalOpen}
        handleAction={handleAction}
        action={actionName}
      />
    </>
  );
};

export default JobList;

const FilterModal = (props) => {
  const {
    filters,
    setFilters,
    setFilterCount,
    locations,
    technologies,
    clients,
    worknature,
    priorities,
    statuss,
  } = props;

  const handleChange = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setFilterCount((prev) => prev + 1);
  };
  const modifiedLocations =
    locations.length > 0 &&
    locations?.map((item) => ({ ...item, _id: item?.cityname }));

  const filterArr = [
    {
      id: 1,
      name: "worknature",
      label: "work nature",
      options: worknature,
      optionLabel: "label",
    },
    {
      id: 2,
      name: "location",
      label: "location",
      options: modifiedLocations,
      optionLabel: "cityname",
    },
    {
      id: 3,
      name: "priority",
      label: "priority",
      options: priorities,
      optionLabel: "label",
    },
    {
      id: 4,
      name: "technology",
      label: "technology",
      options: technologies,
      optionLabel: "technologyname",
    },
    {
      id: 5,
      name: "client",
      label: "client",
      options: clients,
      optionLabel: "name",
    },
    {
      id: 6,
      name: "isActive",
      label: "status",
      options: statuss,
      optionLabel: "label",
    },
  ];
  const selectFilterCSS =
    "p-2 text-sm bg-white border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded outline-none mr-2 mb-2";

  return (
    <>
      {filterArr.map((dropdown) => (
        <select
          name={dropdown.name}
          className={selectFilterCSS}
          value={filters[dropdown.name]}
          onChange={handleChange}
        >
          <option value="" disabled>
            Select {toTitleCase(dropdown.label)}
          </option>
          {dropdown.options?.map((opt) => (
            <option value={opt._id}>
              {opt[dropdown.optionLabel]}
              {!!dropdown.count && <span>({opt[dropdown.count]})</span>}
            </option>
          ))}
        </select>
      ))}
    </>
  );
};
