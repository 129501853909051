import React, { useState } from 'react'
import VendorList from './VendorList';
import AddVendor from './AddVendor';
import { useLocation } from 'react-router-dom';
import UpdateVendor from './UpdateVendor';

const Vendors = () => {
  const initialStateOfFilters = {
    vendor_id: '',
    status: '',
    primarytechnology: '',
    candidateAssigned: '',
    startTime: null,
    endTime: null,
    yearofexperience: '',
    operator: ''
  }
  const { pathname } = useLocation();
  const [filters, setFilters] = useState(initialStateOfFilters);

  if (pathname.includes('list')) {
    return (
      <>
        <VendorList filters={filters} setFilters={setFilters} initialStateOfFilters={initialStateOfFilters} />
      </>
    )
  }
  if (pathname.includes('add')) {
    return (
      <>
        <AddVendor />
      </>
    )
  }
  if (pathname.includes('update')) {
    return <UpdateVendor />
  }
}

export default Vendors;
