import React from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";


const VideoModal = ({ isOpen, videoUrl, closeModal, ref }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-[999]" ref={ref}>
      <div className="bg-white p-6 rounded-lg max-w-md w-full h-[333px] relative">
        <div className="absolute top-1 right-2">
          <button
            className="text-gray-500 hover:text-gray-800"
            onClick={closeModal}
          >
            <IoIosCloseCircleOutline title='close' className="h-5 w-5"/>
          </button>
        </div>
        <div className="aspect-w-16 aspect-h-9 h-full">
          <iframe
            title="video"
            className="w-full h-full"
            src={videoUrl}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
