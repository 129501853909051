import React from 'react'

const RightSideLayout = (props) => {
  const { pageTitle, children } = props;
  return (
    <div className="w-full px-4 py-2 overflow-x-scroll no-scrollbar">
      <div className="pt-3 mb-4">
        <h1 className="text-xl text-[#202223] font-semibold mb-4">
          {pageTitle}
        </h1>
        {children}
      </div>
    </div>
  )
}

const TopSectionLayout = ({ children }) => {
  return (
    <div className="flex justify-between mb-4">
      {children}
    </div>
  )
}

RightSideLayout.TopSectionLayout = TopSectionLayout;

export default RightSideLayout;
