import React from "react";
import nodata from "../Assets/nodata.png";

const TableNoData = () => {
  return (
    <>
      <tr className="fixed top-[45%] left-[55%]">
        <td colSpan={7} rowSpan={10}>
          <img src={nodata} alt="nodata" />
          <span className="text-[#6D7175] text-sm font-normal">
            No Data Available
          </span>
        </td>
      </tr>
    </>
  );
};

export default TableNoData;
