import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import coverImage from "../Assets/Vector5.png";
import logo from "../Assets/logo.png";

const Auth = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log(pathname)
  useEffect(() => {
    if (pathname === '/') navigate('/login');
  }, [pathname]);

  return (
    <div
      className="w-full h-screen flex items-center justify-center flex-col bg-[length:100%_60%] bg-top bg-no-repeat"
      style={{ backgroundImage: `url(${coverImage})` }}
    >
      <div className="bg-whiterounded-md">
        <img
          src={logo}
          className=" w-[70%] m-auto pb-5 px-5 "
          alt="logo"
        ></img>
      </div>
      <div className="max-w-[400px] w-[calc(100%-20%)] bg-white shadow hover:shadow-lg rounded-md">
        <Outlet />
      </div>
      <small className=" mt-4">© {currentYear} Get Developers Admin</small>
    </div>
  )
}

export default Auth
