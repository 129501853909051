import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FiTrash } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../context/Datastate";
import { BACWARD_ARROW, COPY_ICON, UPLOAD_ICON } from "../Assets/icons/icons";
import { convertISODateToFormattedDate, copyTextToClipboard, generateOptions, isEmailValid, isPANValid, isPFValid, isPhoneValid, isURLVaid } from "../../utils";
import Loader from "../Loader/Loader";
import fetchUtil, { BASE_URL } from "../../utils/fetch.util";
import { ADD_CANDIDATE, GET_CANDIDATE_BY_ID, UPDATE_CANDIDATE_BY_ID } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";
import { CustomLabel, CustomInput, CustomDropDown, ErrorState, BackButton } from '../atoms/Utility';
import DeleteModal from "../DeleteModal/DeleteModal";
import DeleteCommentModal from "../DeleteModal/DeleteCommentModal";
import AddFormLayout from "../Layout/AddFormLayout";
import VideoModal from "../atoms/VideoModal";
import FileDragAndDrop from "../atoms/FileDragAndDrop";

const initialState = {
  name: "",
  linkedinprofile: "",
  resume: "",
  resumelink: "",
  primarytechnology: "",
  phonenumber: "",
  rate: "",
  joiningtime: "Immediate",
  totalExp: { years: "", months: "" },
  relevantExp: { years: "", months: "" },
  status: "64f997e6d3d3c1cadc894a75",
  pannumber: "",
  aadhar: "",
  pf: "",
  vendor_id: "650bdc9162d8c029f66b11bd",
  email: "",
  salarySlip: "",
  candidateAssigned: "",
  ctc: "",
  ectc: "",
  isSendEmail: "",
  comment: "",
  comments: [],
  fileFormat: "",
  videoIntro: '',
  location:""
};

const availabilityOptions = [
  { label: 'Immediate', value: 'Immediate', isSelected: true },
  { label: '15 days', value: '15 days' },
  { label: '30 days', value: '30 days' },
];

const AddCandidate = () => {
  const {
    loader,
    AddCandidateApi,
    disableSave,
    allTechnologyApi,
    technology,
    getallstatusApi,
    status,
    vendor,
    allVendorApi,
    subAdmin,
    subAdminPaginationApi,
    submitCandidateCommentApi,
    getCandidateDetailsApi,
    candidateView,
    deleteBtn,
    deleteBtnId,
    setDeleteBtn,
    deleteCommentApi,
    setDeleteBtnId,
  } = useContext(DataContext);


  const params = useParams();
  const navigate = useNavigate();

  const [resumeOption, setResumeOption] = useState("upload");
  const ref = useRef();
  const [videoModal, setVideoModal] = useState(false);
  const [error, setError] = useState({
    phonenumber: false,
    email: false,
    linkedinprofile: false,
    aadhar: false,
    pannumber: false,
    pf: false,
    resume: false,
    resumelink: false
  });

  const inputRef = useRef(null);
  const [state, setState] = useState(initialState);
  const [buttonDisable, setButtonDisable] = useState(false);

  // to close the video if clicked outside
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ref.current && ref.current.contains(e.target)) {
        setVideoModal(false);
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [videoModal]);

  //to get the details in case of edit the details of a candidate
  useEffect(() => {
    if (params.vendorId && params.candidateId) {
      fetchUtil.GET(`${GET_CANDIDATE_BY_ID}/${params.vendorId}/${params.candidateId}`)
        .then((response) => {
          setDataToState(response.result);
        }).catch((err) => {
          console.error(err);
          toast.error(err);
        })
    }
  }, [params.vendorId, params.candidateId]);

  const setDataToState = (data) => {
    if (data) {
      for (let key in initialState) {
        setState((prev) => ({
          ...prev,
          [key]: data[key]
        }))
      }
      if (data.resumelink) setResumeOption('drive');
    }
  }

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }
  const isFormValid = () => {
    return buttonDisable || (state?.name?.trim() !== "" && !error.phonenumber && !error.email && state?.email?.trim() !== "" && !error.linkedinprofile && !error.pannumber && !error.pf && !error.resumelink && !error.aadhar && !error.resume && state?.primarytechnology !== "" && state?.vendor_id !== "" && state?.location);
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (e.target.name === 'aadhar') {
      if (file?.size > 1024 * 1024) {
        setError((prev) => ({ ...prev, aadhar: true }))
        return;
      }
      setState((prev) => ({ ...prev, aadhar: file }));
    }
    else if (e.target.name === 'resume') {
      if (file?.size > 1024 * 1024) {
        setError((prev) => ({ ...prev, resume: true }))
        return;
      }
      setState((prev) => ({ ...prev, resume: file }));
    }
  };
  const handleResumeDoc = (file) => {
    const resumeFile = file[0];
    if (resumeFile?.size > 1024 * 1024) {
      setError((prev) => ({ ...prev, resume: true }))
      return;
    }
    setState((prev) => ({ ...prev, resume: resumeFile }));
  }

  const handleSubmit = () => {
    let newFormData = new FormData();
    Object.keys(state).forEach(key => {
      if (key === 'totalExp' || key === 'relevantExp') {
        Object.keys(state[key]).forEach(innerKey => {
          if (innerKey === 'years' || innerKey === 'months') {
            newFormData.append(`${key}[${innerKey}]`, state[key][innerKey] || 0)
          } else newFormData.append(`${key}[${innerKey}]`, state[key][innerKey])
        })
      } else if (key === "isSendEmail") {
        newFormData.append(key, state[key] || false);
      } else {
        newFormData.append(key, state[key])
      }
    });
    setButtonDisable(true);
    newFormData.delete('comments')

    if (newFormData.get('candidateAssigned') === 'undefined' || newFormData.get('candidateAssigned') === '') newFormData.delete('candidateAssigned');
    if (newFormData.get('comment') === 'undefined' || newFormData.get('comment').trim() === '') newFormData.delete('comment');

    if (params.vendorId && params.candidateId) {
      fetchUtil.PUT(`${UPDATE_CANDIDATE_BY_ID}/${params.candidateId}`, newFormData, 'formData')
        .then((response) => {
          toast.success(response.message);
          setTimeout(() => {
            navigate("/all_candidates/list");
          }, 500);
        })
        .catch(err => console.error(err))
        .finally(() => setButtonDisable(false));
    }
    else {
      newFormData.delete('comment');
      newFormData.delete('isSendEmail');

      fetchUtil.POST(`${ADD_CANDIDATE}/${state?.vendor_id}`, newFormData, 'formData')
        .then((response) => {
          toast.success(response.message);
          setTimeout(() => {
            navigate("/all_candidates/list");
          }, 500);
        }).catch(err => console.error(err))
        .finally(() => setButtonDisable(false));
    }
  };

  useEffect(() => {
    allTechnologyApi();
    getallstatusApi();
    allVendorApi();
    subAdminPaginationApi();
  }, []);

  const setErrorValueTrue = (errorKey) => setError((prev) => ({ ...prev, [errorKey]: true }));
  const setErrorValueFalse = (errorKey) => setError((prev) => ({ ...prev, [errorKey]: false }));

  const inputFields = ['pannumber', 'pf'];

  const handleChange = (e) => {
    const val = inputFields.includes(e.target.name) ? e.target.value.toUpperCase() : e.target.value;
    setState((prev) => ({ ...prev, [e.target.name]: val.trim() }));
    if (e.target.value !== "") {
      if (e.target.name === 'phonenumber') {
        if (!isPhoneValid(e.target.value)) setErrorValueTrue(e.target.name);
        else setErrorValueFalse(e.target.name);
      }
      if (e.target.name === 'email') {
        if (!isEmailValid(e.target.value)) setErrorValueTrue(e.target.name)
        else setErrorValueFalse(e.target.name)
      }
      if (e.target.name === 'linkedinprofile') {
        if (!isURLVaid(e.target.value)) setErrorValueTrue(e.target.name)
        else setErrorValueFalse(e.target.name);
      }
      if (e.target.name === 'pannumber') {
        if (!isPANValid(e.target.value)) setErrorValueTrue(e.target.name)
        else setErrorValueFalse(e.target.name);
      }
      if (e.target.name === 'pf') {
        if (!isPFValid(e.target.value)) setErrorValueTrue(e.target.name)
        else setErrorValueFalse(e.target.name);
      }
      if (e.target.name === 'resumelink') {
        if (!isURLVaid(e.target.value)) setErrorValueTrue(e.target.name)
        else setErrorValueFalse(e.target.name);
      }
    }
    else setErrorValueFalse(e.target.name);
  }


  const optionsFromAPI = (firstOption, options, value) => {
    return (
      <>
        {!!firstOption && <option value="" selected disabled> {firstOption} </option>}
        {options?.map((v) => {
          return (<option key={v?._id} value={v?._id}> {v[value]} </option>)
        })}
      </>
    )
  }
  const handleOptionChange = (e) => {
    setResumeOption(e.target.value);

    //TO DO---> there should be saparate keys for upload resume and resume link
    // if (e.target.value === 'upload') setState((prev) => ({ ...prev, resumelink: "" }));
  }
  const title = params.vendorId && params.candidateId ? 'Edit' : 'Add';

  const isUpdateCandidate = params.vendorId && params.candidateId ? true : false;
  const loadingState = params.vendorId && params.candidateId ? loader : false;
  const fileSizeError = 'Maximum file size: 1MB';

  const resumeFileName = () => {
    if (state?.resume && state?.resume !== 'null') {
      if (typeof state?.resume === 'string') {
        return <a href={BASE_URL + state?.resume} target="blank">Click here to open the file</a>
      } else { return state?.resume.name }
    }
  };
  const aadharFileName = () => {
    if (state?.aadhar) {
      if (typeof state?.aadhar == "string") {
        return <a href={BASE_URL + state?.aadhar} target="blank">Click here to open the file</a>
      } else { return state?.aadhar.name }
    }
  };
  const recordVideoLink = `https://user.getdeveloper.in/upload_video/${params.candidateId}`;

  const recordedLink = BASE_URL + state?.videoIntro;
  return (
    <>
      <AddFormLayout
        pageTitle={`${title} candidate info`}
        heading={'Details'}
        navigationPath={"/all_candidates/list"}
        loadingState={loadingState}
        buttonDisabled={!isFormValid()}
        onFormSubmit={handleSubmit}
      >
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/3 px-2">
            <CustomLabel htmlFor='name' required>Name</CustomLabel>
            <CustomInput type='text' id='name' name='name'
              placeholder="Please enter name" value={state?.name}
              onChange={(e) => {
                setState((prev) => ({ ...prev, name: toTitleCase(e.target.value.replace(/[^a-zA-Z\s]/g, '')) }))
              }}
            />
            <div className="my-3">
              <CustomLabel htmlFor='phone'>Phone</CustomLabel>
              <CustomInput type='number' id='phone' name='phonenumber'
                placeholder="Please enter phone number" value={state?.phonenumber}
                onChange={handleChange}
              />
              <ErrorState err={error.phonenumber} errMsg={"Please enter a valid phone number"} />
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor='email' required>Email</CustomLabel>
              <CustomInput type='email' id='email' name='email'
                autoComplete="off" placeholder="Please enter email" value={state?.email}
                onChange={handleChange}
              />
              <ErrorState err={error.email} errMsg={"Please enter a valid email"} />
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor='linkedinprofile'>LinkedIn profile</CustomLabel>
              <CustomInput type='text' id='linkedinprofile' name='linkedinprofile'
                autoComplete="off" placeholder="Please enter linkedin profile" value={state?.linkedinprofile}
                onChange={handleChange}
              />
              <ErrorState err={error.linkedinprofile} errMsg={"Please enter a valid url"} />
            </div>

            <div className="mb-3">
              <CustomLabel htmlFor='pannumber'>PAN</CustomLabel>
              <CustomInput type='text' id='pannumber' name='pannumber'
                autoComplete="off" placeholder="Please enter PAN" value={state?.pannumber}
                onChange={handleChange}
              />
              <ErrorState err={error.pannumber} errMsg={"Please enter a valid PAN"} />
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor='pf'>PF number</CustomLabel>
              <CustomInput type='text' id='pf' name='pf'
                autoComplete="off" placeholder="Please enter PF number" value={state?.pf}
                onChange={handleChange}
              />
              <ErrorState err={error.pf} errMsg={"Please enter a valid PF number"} />
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor='pf'required>Current Location</CustomLabel>
              <CustomInput type='text' id='location' name='location'
                autoComplete="off" placeholder="Please enter current location " value={state?.location}
                onChange={(e) => {
                  setState((prev) => ({ ...prev, location: toTitleCase(e.target.value.replace(/[^a-zA-Z\s]/g, '')) }))
                }}
              />
              {/* <ErrorState err={error.pf} errMsg={"Please enter a valid PF number"} /> */}
            </div>
            
            {!!params.candidateId &&
              <>
                <div className="mb-3">
                  <CustomLabel>Video record link</CustomLabel>
                  <div className="flex">
                    <div className="w-[80%]">
                      <input className="w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md rounded-r-none  focus-visible:border-[#fb923c] focus-visible:outline-none focus-visible:shadow-focusshadow placholder:text-slate-400 text-sm h-9" value={recordVideoLink} disabled />
                    </div>
                    <div className="w-[20%]">
                      <button className="bg-theme-color w-full text-center flex items-center justify-center text-white transition ease-in-out delay-150 duration-300 py-[.5rem] rounded-md rounded-l-none font-bold cursor-pointer" onClick={() => copyTextToClipboard(recordVideoLink)}><COPY_ICON className={'w-5 h-5'} /></button>
                    </div>
                  </div>
                </div>
                <VideoModal isOpen={videoModal} videoUrl={recordedLink} closeModal={() => setVideoModal(false)} ref={ref} />

                {!!state?.videoIntro && <div className="mb-3">
                  <CustomLabel>Recorded video link</CustomLabel>
                  <div className="flex gap-2">
                    <p className="text-theme-color cursor-pointer text-xs w-fit" onClick={() => setVideoModal(true)}>Click here to open</p>
                    <p className="text-theme-color cursor-pointer text-xs w-fit" onClick={() => copyTextToClipboard(recordedLink)}>Copy video link</p>
                  </div>
                  {/* <a href={recordedLink} target="_blank" className="text-theme-color cursor-pointer text-xs w-fit">Click here to open</a> */}
                </div>}
              </>}
          </div>
          <div className="w-full md:w-1/3 px-2">
            <CustomLabel htmlFor='cctc'>CCTC</CustomLabel>
            <CustomInput type='text' id='cctc' name='ctc'
              autoComplete="off" placeholder="Please enter current CTC" value={state?.ctc}
              onChange={(e) => {
                setState((prev) => ({ ...prev, ctc: e.target.value }))
              }}
            />
            <div className="my-3">
              <CustomLabel htmlFor='ectc'>ECTC</CustomLabel>
              <CustomInput type='text' id='ectc' name='ectc'
                autoComplete="off" placeholder="Please enter expected CTC" value={state?.ectc}
                onChange={(e) => {
                  setState((prev) => ({ ...prev, ectc: e.target.value }))
                }}
              />
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor='rate'>Rate given to client</CustomLabel>
              <CustomInput type='text' id='rate' name='rate'
                autoComplete="off" placeholder="Please enter rate given to client" value={state?.rate}
                onChange={(e) => {
                  setState((prev) => ({ ...prev, rate: e.target.value }))
                }}
              />
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor='primarytechnology' required>Primary technology</CustomLabel>
              <CustomDropDown
                id="primarytechnology"
                name="primarytechnology"
                value={state?.primarytechnology}
                onChange={(e) => {
                  setState((prev) => ({ ...prev, primarytechnology: e.target.value }))
                }}
              >
                {optionsFromAPI("Choose primary technology", technology, 'technologyname')}
              </CustomDropDown>
            </div>
            <div className="mb-3">
              <CustomLabel>Total experience</CustomLabel>
              <div className="flex w-full justify-between">
                <div className="w-1/2 pr-2">
                  <CustomDropDown
                    id='total_year'
                    value={state?.totalExp?.years}
                    onChange={(e) => {
                      setState((prev) => ({ ...prev, totalExp: { ...prev.totalExp, years: e.target.value } }))
                    }}
                  >
                    <option value="" selected disabled>years</option>
                    {generateOptions(0, 30, "year")}
                  </CustomDropDown>
                </div>
                <div className="w-1/2 pl-2">
                  <CustomDropDown
                    id='total_month'
                    value={state?.totalExp?.months}
                    onChange={(e) => {
                      setState((prev) => ({ ...prev, totalExp: { ...prev.totalExp, months: e.target.value } }))
                    }}
                  >
                    <option value="" selected disabled>months</option>
                    {generateOptions(0, 11, "months")}
                  </CustomDropDown>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <CustomLabel>Relevant experience</CustomLabel>
              <div className="flex w-full justify-between">
                <div className="w-1/2 pr-2">
                  <CustomDropDown
                    id='relevant_year'
                    value={state?.relevantExp?.years}
                    onChange={(e) => {
                      setState((prev) => ({ ...prev, relevantExp: { ...prev.relevantExp, years: e.target.value } }))
                    }}
                  >
                    <option value="" selected disabled>years</option>
                    {generateOptions(0, 30, "year")}
                  </CustomDropDown>
                </div>
                <div className="w-1/2 pl-2">
                  <CustomDropDown
                    id='relevant_month'
                    value={state?.relevantExp?.months}
                    onChange={(e) => {
                      setState((prev) => ({ ...prev, relevantExp: { ...prev.relevantExp, months: e.target.value } }))
                    }}
                  >
                    <option value="" selected disabled>months</option>
                    {generateOptions(0, 11, "months")}
                  </CustomDropDown>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-2">
            <CustomLabel htmlFor='status'>Status</CustomLabel>
            <CustomDropDown
              id="status"
              name="status"
              value={state?.status}
              onChange={(e) => {
                setState((prev) => ({ ...prev, status: e.target.value }))
              }}
            >
              {optionsFromAPI(null, status, 'statusname')}
            </CustomDropDown>
            <div className="my-3">
              <CustomLabel htmlFor='availability'>Availability</CustomLabel>
              <CustomDropDown
                id="availability"
                name="availability"
                value={state?.joiningtime}
                onChange={(e) => {
                  setState((prev) => ({ ...prev, joiningtime: e.target.value }))
                }}
              >
                {availabilityOptions.map((opt) => <option value={opt.value} selected={opt.isSelected}>{opt.label}</option>)}
              </CustomDropDown>
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor='vendor' required>Vendor</CustomLabel>
              <CustomDropDown
                id="vendor"
                name="vendor"
                value={state?.vendor_id || "6628affe6924a4f65ddbc5fb"}
                onChange={(e) => {
                  setState((prev) => ({ ...prev, vendor_id: e.target.value }))
                }}
              >
                {optionsFromAPI("Choose vendor", vendor, 'vendorname')}
              </CustomDropDown>
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor='assigned'>Candidate assigned</CustomLabel>
              <CustomDropDown
                id="assigned"
                name="assigned"
                value={state?.candidateAssigned}
                onChange={(e) => {
                  setState((prev) => ({ ...prev, candidateAssigned: e.target.value }))
                }}
              >
                {optionsFromAPI("Choose assignee", subAdmin, 'name')}
              </CustomDropDown>
            </div>
            <div className="mb-3">
              <CustomLabel>Aadhar <small className="font-normal">(allowed .jpg, .png and .pdf)</small></CustomLabel>
              <CustomInput
                type="file"
                name="aadhar"
                accept='image/*, .pdf'
                onChange={handleFileChange}
              />
              <ErrorState err={error.aadhar} errMsg={fileSizeError} />
              {state?.aadhar && <div className="text-xs">Selected file: <span className="text-green-600 ">{aadharFileName()}</span>
              </div>}
            </div>
            <div className="mb-3">
              <CustomLabel subHeading={'(please select any one)'}>Resume options</CustomLabel>
              <div className="flex justify-around">
                <div className="flext items-center justify-center">
                  <label htmlFor="upload" className="text-[13px] mr-[5px]"> Upload</label>
                  <input type="radio" id="upload" value="upload" name='resume_option' checked={resumeOption === 'upload'} onChange={handleOptionChange} />
                </div>
                <div className="flext items-center justify-center">
                  <label htmlFor="drive" className="text-[13px] mr-[5px]">Link</label>
                  <input type="radio" id="drive" value="drive" name='resume_option' checked={resumeOption === 'drive'} onChange={handleOptionChange} />
                </div>
              </div>
              {resumeOption === 'upload' &&
                <>
                  <FileDragAndDrop onUpload={handleResumeDoc} supportedFiles={".pdf, .docx, .doc"} />
                  <ErrorState err={error.resume} errMsg={fileSizeError} />
                  {state?.resume && state.resume !== 'null' && (
                    <div className="text-xs mt-2">
                      Selected file: <span className="text-green-600 ">{resumeFileName()}</span>
                    </div>
                  )}
                </>
                // <div className="flex justify-center items-center mt-4">
                //   <div className="w-full flex flex-col">
                //     <div className="border border-gray-300 shadow-sm rounded-md text-sm text-gray-400 cursor-pointer text-center"
                //       onClick={() => inputRef.current.click()}>
                //       <UPLOAD_ICON className={'mx-[auto]'} />
                //       <p>allowed: .docx, .doc and .pdf</p>
                //       <input
                //         ref={inputRef}
                //         type="file"
                //         accept=".pdf, .docx, .doc"
                //         name="resume"
                //         className="hidden"
                //         onChange={handleFileChange}
                //       />
                //     </div>

                //   </div>
                // </div>
              }
              {resumeOption === 'drive' && <div className="mt-4">
                <CustomInput
                  id="resumelink"
                  type="text"
                  name="resumelink"
                  value={state?.resumelink}
                  onChange={handleChange}
                />
                <ErrorState err={error.resumelink} errMsg={"Please enter a valid url"} />
              </div>}
            </div>
            
          </div>
        </div>
        {!!isUpdateCandidate &&
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 px-2">
              <div className="my-2">
                <CustomLabel htmlFor="positiontype">Add Comments</CustomLabel>
                <textarea
                  type="text"
                  id="positiontype"
                  name="positiontype"
                  placeholder="Enter your comments here"
                  className="w-full border py-1 px-3 mb-1 border-gray-300 shadow-sm rounded-md  mt-1 focus-visible:border-[#fb923c] h-44 resize-none focus-visible:outline-none focus-visible:shadow-focusshadow placholder:text-slate-400 text-sm "
                  value={state?.comment}
                  onChange={(e) => {
                    setState((prev) => ({ ...prev, comment: e.target.value }))
                  }}
                />
              </div>
              <div className="flex justify-start items-center">
                <input
                  type="checkbox"
                  className="border-gray-300 rounded h-[14px] w-[14px]  "
                  checked={state?.isSendEmail}
                  onChange={() => {
                    setState((prev) => ({ ...prev, isSendEmail: !prev.isSendEmail }))
                  }}
                />
                <h6 className="text-gray-500 font-medium leading-none text-[14px] ml-2">
                  Send email to vendor
                </h6>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-2">
              <div className="my-2">
                <CustomLabel>Added Comments</CustomLabel>
                <div className="border border-[#dfdfdf] p-2 rounded-lg">
                  <div className="comments_wraper h-40 max-h-40 3xl:max-h-40 3xl:h-40 4xl:max-h-64 4xl:h-64 rounded-lg overflow-y-scroll">
                    {state?.comments?.length > 0 ?
                      <>
                        {state?.comments.map((cmt, index) => (
                          <div
                            key={index}
                            className="flex rounded-lg bg-[#00000005] w-full py-2 px-2 mb-2 flex-col justify-between border-2 border-[#e1e1e163]">
                            <div className="flex justify-start">
                              <p className="text-[#4B4B4B] text-[14px] italic font-normal">{cmt?.comment || "No comments"}</p>
                              <FiTrash
                                className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[26px]"
                                onClick={() => { setDeleteBtn(true); setDeleteBtnId(cmt?._id); }} />
                            </div>
                            <div className="flex justify-end ml-auto">
                              <p className="text-[#4B4B4B] text-[12px] font-bold ml-auto">({cmt?.createdBy?.name || "No name"})</p>
                              <p className="text-[#4B4B4B] text-[12px] font-bold ml-auto">
                                {convertISODateToFormattedDate(cmt?.createdAt) || "No date"}
                              </p>
                            </div>
                          </div>
                        ))}
                      </> :
                      <div className="h-full flex justify-center items-center">
                        <span className="text-[#6D7175] text-[14px]">No comments yet...</span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>}
      </AddFormLayout>
      {deleteBtn && (
        <DeleteModal
          // deleteApi={deleteCommentApi}
          deleteId={deleteBtnId}
        />
      )}
      {deleteBtn && (
        <DeleteCommentModal
          deleteApi={deleteCommentApi}
          vendorid={params.vendorId}
          deleteId={deleteBtnId}
          candidateId={params.candidateId}
        />
      )}
    </>
  );
};

export default AddCandidate;
