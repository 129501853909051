import React, { useContext, useState } from 'react';
import { DataContext } from '../../context/Datastate';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    padding: '20px',
    gap: '20px',
    flexWrap: 'wrap',
  },
  box: {
    width: '30%',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    transition: 'transform 0.3s ease',
    color: '#fff',
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  box1: {
    backgroundColor: '#f8b400',
  },
  box2: {
    backgroundColor: '#1d72b8',
  },
  box3: {
    backgroundColor: '#28a745',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '2rem',
    color: '#333',
  },
  button: {
    backgroundColor: '#fff',
    color: '#333',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '1rem',
    transition: 'background-color 0.3s ease',
    marginTop: '10px',
    opacity: '1',
  },
  disabledButton: {
    backgroundColor: '#ccc',
    cursor: 'not-allowed',
    opacity: '0.6',
  },
};

const ThreeBoxes = () => {
  const { sendEmailtoAll } = useContext(DataContext);
  const {sendAllJobToVendor}= useContext(DataContext);
  const {sendAllCandidateToVendor}= useContext(DataContext)
  const [loading, setLoading] = useState({
    box1: false,
    box2: false,
    box3: false,
  });

  const handleClick = async (boxName) => {
    const boxKey = `box${boxName}`;
  
    // Set the loading state for the clicked box
    setLoading((prev) => ({ ...prev, [boxKey]: true }));
  
    try {
      let response;
  
      // Handle different box actions
      if (boxName === 3) {
        response = await sendEmailtoAll();
      } else if (boxName === 2) {
        response = await sendAllJobToVendor();
      } else if (boxName === 1) {
        sendAllCandidateToVendor()
        // Handle action for box 1, if needed
      } else {
        console.error("Something went wrong");
      }
  
      // Check if the response status code is 200
      if (response && response.statusCode === 200) {
        console.log('Email sent successfully');
        // Stop loading only after success
      } else {
        console.error('Error: Did not receive a status code of 200');
      }
  
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      // Ensure loading stops only if statusCode is 200 or after 5 seconds
      setTimeout(() => {
        setLoading((prev) => ({ ...prev, [boxKey]: false }));
      }, 5000); // Disable for 5 seconds (5000 ms)
    }
  };
  

  return (
    <div>
      <h1 style={styles.header}>Manage Your Emails</h1>
      <div style={styles.container}>
        <div style={{ ...styles.box, ...styles.box1 }}>
          <p>Send emails to all vendors.</p>
          <button
            style={loading.box1 ? { ...styles.button, ...styles.disabledButton } : styles.button}
            onClick={() => handleClick(1)}
            disabled={loading.box1} // Disable button when loading
          >
            {loading.box1 ? 'Sending...' : 'Send'}
          </button>
        </div>
        <div style={{ ...styles.box, ...styles.box2 }}>
          <p>Send all job to Vendor.</p>
          <button
            style={loading.box2 ? { ...styles.button, ...styles.disabledButton } : styles.button}
            onClick={() => handleClick(2)}
            disabled={loading.box2} // Disable button when loading
          >
            {loading.box2 ? 'Sending...' : 'Send'}
          </button>
        </div>
        <div style={{ ...styles.box, ...styles.box3 }}>
          <p>Send Festival email to all.</p>
          <button
            style={loading.box3 ? { ...styles.button, ...styles.disabledButton } : styles.button}
            onClick={() => handleClick(3)}
            disabled={loading.box3} // Disable button when loading
          >
            {loading.box3 ? 'Sending...' : 'Send'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThreeBoxes;
