import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/Datastate";
import Select from "react-select";
import AddFormLayout from "../Layout/AddFormLayout";

const AddVendor = () => {
  const {
    AddVendorApi,
    vendor,
    disableSave,
    allTechnologyApi,
    technology,
    city,
    allCityApi,
  } = useContext(DataContext);
  const [count, setCount] = useState(0);
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  const [websiteError, setWebsiteError] = useState(false);
  const [phoneFormatTest1, setPhoneFormatTest1] = useState(false);
  const [password, setPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    vendorname: "",
    website: "https://",
    address: "",
    contactpersonname: "",
    contactpersonphone: "",
    alternatepersonname: "",
    alternatepersonphone: "",
    technology: [],
    location: [],
    email: "",
    password: "",
    isSendEmail: false,
  });
  {
  }
  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const validateEmail = () => {
    if (!validEmail.test(state.email) && state.email !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  const validatePassword = () => {
    if (state.password === "") {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };
  // const validPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+\[\]{}|;:'",.<>?]).{8,}$/);
  //   const validatePassword = () => {
  //     if (!validPassword.test(state.password) && state.password !== "") {
  //       setPasswordError(true);
  //     } else {
  //       setPasswordError(false);
  //     }
  //   };
  const validUrl = new RegExp(
    /^(https?|http):\/\/[^\s/$?#].[^\s]*\.[a-zA-Z]{2,}$/
  );

  // const validUrl = new RegExp(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/);
  const validateUrl = () => {
    if (!validUrl.test(state.website) && state.website !== "") {
      setWebsiteError(true);
    } else {
      setWebsiteError(false);
    }
  };

  const validatePhoneno1 = (phoneData1) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData1.match(phoneno)) {
      return setPhoneFormatTest1(false);
    } else if (phoneData1 == "") {
      return setPhoneFormatTest1(false);
    } else {
      return setPhoneFormatTest1(true);
    }
  };
  const validatePhoneno = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setPhoneFormatTest(false);
    } else if (phoneData == "") {
      return setPhoneFormatTest(false);
    } else {
      return setPhoneFormatTest(true);
    }
  };
  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  const buttonDisabled =
    !phoneFormatTest &&
    !emailError &&
    !passwordError &&
    !phoneFormatTest1 &&
    // !phoneFormatTest1 &&
    state.vendorname !== "" &&
    state.website !== "" &&
    state.address !== "" &&
    state.contactpersonname !== "" &&
    state.contactpersonphone !== "" &&
    // state.alternatepersonname !== "" &&
    // state.alternatepersonphone !== "" &&
    state.email !== "" &&
    state.password !== "";

  const handleSubmit = () => {
    if (state.website === "https://") {
      state.website = "";
    }
    if (!disableSave) AddVendorApi(state);
  };

  useEffect(() => {
    // getVendorById();
    // getCountryApi();
    vendor?.filter((element) => {
      return;
    });
    allTechnologyApi();
    allCityApi();
  }, []);

  const isFormValid = () => {

  };
  return (
    <>
      <AddFormLayout
        pageTitle={`Vendor info`}
        heading={'Details'}
        navigationPath={"/vendors/list"}
        loadingState={loading}
        buttonDisabled={!isFormValid()}
        onFormSubmit={handleSubmit}
      >
        <div className="flex space-x-10 ">
          <div className="w-1/3 space-y-3 ">
            <div className="">
              <div>
                <label
                  htmlFor="first-name"
                  className="text-sm px-1 font-semibold "
                >
                  Vendor Name *
                </label>
                <input
                  type="text"
                  id="Vendor-name"
                  name="Vendorname"
                  placeholder="Enter your Vendor name"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={state.vendorname}
                  onChange={(e) => {
                    setState({
                      ...state,
                      vendorname: toTitleCase(e.target.value),
                    });
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor="address"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Address *
                </label>
                <input
                  type="text"
                  name="address"
                  placeholder="Enter your address"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={state.address}
                  onChange={(e) => {
                    setState({ ...state, address: e.target.value });
                  }}
                />
              </div>

              <div>
                <label
                  htmlFor="website"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Website * <small>(https://website.com)</small>
                </label>
                <input
                  id="website"
                  type="text"
                  name="website"
                  placeholder="https://website.com"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={state.website}
                  onChange={(e) => {
                    setState({ ...state, website: e.target.value });
                  }}
                  onKeyUp={(e) => validateUrl(e.target.value)}
                />
                {websiteError && (
                  <span className="text-left text-red-600 text-sm">
                    Please enter a valid url
                  </span>
                )}
              </div>
              <div className="">
                <label
                  htmlFor="technology"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Add Technologies
                </label>
                <Select
                  className="text-sm"
                  id="technology"
                  name="technology"
                  placeholder="Choose technology"
                  isMulti // Add the isMulti prop to allow multiple selections
                  options={technology
                    ?.filter((v) => v?.isActive)
                    ?.map((v) => ({
                      value: v?._id,
                      label: v?.technologyname,
                      id: v._id,
                    }))}
                  value={state.technology.map((loc) => ({
                    value: loc,
                    label: technology.find(t => t._id === loc)?.technologyname,
                    id: loc,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    console.log(selectedOptions, "selectedValues");
                    setState({ ...state, technology: selectedValues });
                  }}
                />
              </div>
              <div className="">
                <label
                  className=" text-sm  font-semibold pb-2"
                  htmlFor="credientials"
                >
                  Credentials
                </label>
                <div className=" mt-[10px] flex items-start space-x-3 ">
                  <input
                    type="checkbox"
                    className="border-gray-300 rounded h-5 w-5"
                    value={state.isSendEmail}
                    onChange={(e) => {
                      setState({ ...state, isSendEmail: !state.isSendEmail });
                    }}
                  />

                  <div className="flex flex-col">
                    <h1 className=" font-medium leading-none">
                      Send credentials to vendor
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/3 pt-14 ">
            <div>
              <label
                htmlFor="alternate person-name"
                className="pt-2 text-sm px-1 font-semibold pb-1"
              >
                Alternate Person Name
              </label>
              <input
                id="alternate person-name"
                type="text"
                name="personname"
                placeholder="Enter your alternate person name"
                className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                value={state.alternatepersonname}
                onChange={(e) => {
                  const input = e.target.value;
                  const filteredInput = input.replace(/[^a-zA-Z\s]/g, "");
                  setState({
                    ...state,
                    alternatepersonname: toTitleCase(filteredInput),
                  });
                }}
              />
            </div>
            <div>
              <label
                htmlFor="person-name"
                className="pt-2 text-sm px-1 font-semibold pb-1"
              >
                Contact Person Name *
              </label>
              <input
                id="person-name"
                type="text"
                name="personname"
                placeholder="Enter your contact person name"
                className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                value={state.contactpersonname}
                // onChange={(e) => {
                //   setState({
                //     ...state,
                //     contactpersonname: toTitleCase(e.target.value),
                //   });
                // }}
                onChange={(e) => {
                  const input = e.target.value;
                  const filteredInput = input.replace(/[^a-zA-Z\s]/g, "");
                  setState({
                    ...state,
                    contactpersonname: toTitleCase(filteredInput),
                  });
                }}
              />
            </div>
            <div className="pb-2">
              <label
                htmlFor="phone"
                className=" text-sm px-1 font-semibold pb-1"
              >
                Contact Person Phone *
              </label>
              <input
                type="text"
                maxLength={10}
                pattern="[0-9]{10}"
                id="phone"
                name="phone"
                placeholder="Enter your contact person phone"
                className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9"
                value={state.contactpersonphone}
                onChange={(e) => {
                  setState({
                    ...state,
                    contactpersonphone: e.target.value,
                  });
                }}
                onKeyUp={() => validatePhoneno(state.contactpersonphone)}
              />
              {phoneFormatTest ? (
                <span className="text-red-600 text-sm">
                  Please enter a valid 10 digit Number.
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="">
              <label
                htmlFor="location"
                className="pt-2 text-sm px-1 font-semibold pb-1"
              >
                Location
              </label>
              <Select
                className="text-sm"
                id="location"
                name="location"
                placeholder="Choose location"
                isMulti // Add the isMulti prop to allow multiple selections
                options={city
                  ?.filter((v) => v?.isActive)
                  ?.map((v) => ({
                    value: v?.cityname,
                    label: v?.cityname,
                  }))}
                value={state.location.map((loc) => ({
                  value: loc,
                  label: loc,
                }))}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map(
                    (option) => option.value
                  );
                  setState({ ...state, location: selectedValues });
                }}
              />
            </div>
          </div>
          <div className="w-1/3 pt-14 ">
            <div className="pb-2">
              <label
                htmlFor="phone"
                className=" text-sm px-1 font-semibold pb-1"
              >
                Alternate Person Phone
              </label>
              <input
                type="text"
                maxLength={10}
                pattern="[0-9]{10}"
                id="phone"
                name="phone"
                placeholder="Enter your alternate person phone"
                className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9"
                value={state.alternatepersonphone}
                onChange={(e) => {
                  setState({
                    ...state,
                    alternatepersonphone: e.target.value,
                  });
                }}
                onKeyUp={() => validatePhoneno1(state.alternatepersonphone)}
              />
              {phoneFormatTest1 ? (
                <span className="text-red-600 text-sm">
                  Please enter a valid 10 digit Number.
                </span>
              ) : (
                <></>
              )}
            </div>
            <div>
              <label
                htmlFor="email"
                className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
              >
                Email *
              </label>
              <input
                type="text"
                id="email"
                name="email"
                autoComplete="off"
                placeholder="Enter your email"
                className={`w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9 ${emailError ? "" : "mb-4"
                  } `}
                value={state.email}
                onChange={(e) => {
                  setState({ ...state, email: e.target.value.trim() });
                }}
                onKeyUp={() => validateEmail()}
              />
              {emailError && (
                <span className="text-left text-red-600 text-sm ">
                  Please enter a valid Email
                </span>
              )}
            </div>
            <div className="pb-2">
              <label
                htmlFor="password"
                className="text-sm px-1 font-semibold  relative block"
              >
                Password *
                <input
                  type={password === true ? "text" : "password"}
                  id="password"
                  maxLength={16}
                  autoComplete="new-password"
                  name="password"
                  placeholder="Enter your password"
                  className="w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={state.password}
                  onChange={(e) => {
                    setState({ ...state, password: e.target.value.trim() });
                  }}
                  onKeyUp={() => validatePassword()}
                />
                <span
                  className="absolute top-7 right-0 flex items-center pr-2 cursor-pointer"
                  onClick={() => setPassword(!password)}
                >
                  {password === true ? (
                    <FaRegEye className="h-5 w-5 fill-theme-color" />
                  ) : (
                    <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                  )}
                </span>
              </label>
              {passwordError && (
                <small className="text-left text-red-600">
                  Enter Password
                </small>
              )}
            </div>
          </div>
        </div>
      </AddFormLayout>
    </>
  );
};

export default AddVendor;
