
import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { DataContext } from "../../../context/Datastate";
import { ToastContainer } from "react-toastify";

const AddStatusModal = () => {
 
  const {
    addstatusModal,
    setAddStatusModal,
    addStatusApi,
    // getJobApi,
    status,
    disableSave,
  } = useContext(DataContext);
  const [count, setCount] = useState(0);
 
  const [state, setState] = useState({
    statusname: "",
  });

  const buttonDisabled =
    state.statusname !== "" ;

  const handleSubmit = () => {
    // if (!disableSave)
    addStatusApi(state);
  };
  const handleClose = () =>{
    setAddStatusModal(false);
  }

  function toTitleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }
  useEffect(() => {
    
    status?.filter((element) => {
      return;
    });
  }, []);
 
  return (
    <>
    <div className="absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4 w-full h-screen bg-[#00000062] z-[5]">
        <div className="fixed top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4">
          <div className="bg-white p-5 w-[400px] rounded z-6">
   
    <div className="flex w-full ">
      <div className="w-full px-2 py-2">
        {/* <div className="flex">
          <div className="">
            <h1 className=" text-xl hover:bg-orange-400xl ">Add Technology </h1>
          </div>
        </div> */}
        <div className="flex space-x-10">
          <div className=" space-y-3">
            <h2 className="font-semibold text-lg">Status Details</h2>
            <hr />
            <div className="">
              <div>
                <label
                  htmlFor="title"
                  className="text-sm px-1 font-semibold "
                >
                  Status Name*
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Enter your status name"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={state.statusname}
                  onChange={(e) => {
                    setState({ ...state, statusname: toTitleCase(e.target.value )});
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4">
           
            <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded" onClick={()=> handleClose()}>
              Cancel
            </button>
         
          <button
            type="button"
            disabled={!buttonDisabled}
            className="bg-theme-color disabled:opacity-50 px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3"
            onClick={() => {
              handleSubmit();
            }}>
            Save
          </button>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};
export default AddStatusModal;

