import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { DataContext } from "../../context/Datastate";
import Select from "react-select";

const UpdateVendor = () => {
  const {
    // getVendorApi,
    vendor,
    updateVendorApi,
    city,
    allCityApi,
    allTechnologyApi,
    technology,
    // countryList,
    // getCountryApi,
    disableSave,
  } = useContext(DataContext);
  // const location = useLocation();
  const [count, setCount] = useState(0);
  const [editVendor, setEditVendor] = useState({});
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  const [websiteError, setWebsiteError] = useState(false);
  const [phoneFormatTest1, setPhoneFormatTest1] = useState(false);
  const { id } = useParams();
  const [password, setPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleUpdate = (e) => {
    const { hotVendor, ...updatedVendor } = editVendor;
    if (!disableSave) updateVendorApi(updatedVendor, id);
  };

  const [ndaError, setNdaError] = useState("");
  const [ndaFileName, setNdaFileName] = useState("");

  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const validateEmail = () => {
    if (!validEmail.test(editVendor.email) && editVendor.email !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const validatePassword = () => {
    if (editVendor.password === "") {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };
  // const validPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+\[\]{}|;:'",.<>?]).{8,}$/);
  // const validatePassword = () => {
  //   if (!validPassword.test(editVendor.password) && editVendor.password !== "") {
  //     setPasswordError(true);
  //   } else {
  //     setPasswordError(false);
  //   }
  // };
  const validUrl = new RegExp(
    /^(https?|http):\/\/[^\s/$?#].[^\s]*\.[a-zA-Z]{2,}$/
  );

  // const validUrl = new RegExp(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/);
  const validateUrl = () => {
    if (!validUrl.test(editVendor.website) && editVendor.website !== "") {
      setWebsiteError(true);
    } else {
      setWebsiteError(false);
    }
  };

  const validatePhoneno1 = (phoneData1) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData1.match(phoneno)) {
      return setPhoneFormatTest1(false);
    } else if (phoneData1 == "") {
      return setPhoneFormatTest1(false);
    } else {
      return setPhoneFormatTest1(true);
    }
  };
  const validatePhoneno = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setPhoneFormatTest(false);
    } else if (phoneData == "") {
      return setPhoneFormatTest(false);
    } else {
      return setPhoneFormatTest(true);
    }
  };

  const handleNdaFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setNdaError("Please select an Aadhar file.");
      return;
    }
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ];
    if (!allowedTypes.includes(file.type)) {
      setNdaError(
        "Invalid file type. Please select a PDF, JPG, JPEG, or PNG file."
      );
      setNdaFileName("");
      setEditVendor({ ...editVendor, upload: "" });
      return;
    }

    if (file.size > 1024 * 1024) {
      setNdaError("File size should be less than 1 MB.");
      setNdaFileName("");
      setEditVendor({ ...editVendor, aadhar: "" });
      return;
    }

    setNdaError("");
    setEditVendor({ ...editVendor, aadhar: file });
    setNdaFileName(file.name);
  };

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }
  const buttonDisabled =
  !phoneFormatTest &&
  !emailError &&
  !passwordError &&
  !phoneFormatTest1 &&
    editVendor.vendorname !== "" &&
    editVendor.website !== "" &&
    editVendor.address !== "" &&
    editVendor.contactpersonname !== "" &&
    editVendor.contactpersonphone !== "" &&
    // editVendor.alternatepersonname !== "" &&
    // editVendor.alternatepersonphone !== "" &&
    editVendor.email !== "" &&
    editVendor.password !== "";

  useEffect(() => {
    const newArr = vendor.filter((element) => {
      return element?._id === id;
    });
    allCityApi();
    allTechnologyApi();
    setEditVendor({ ...newArr[0] });
  }, []);

  return (
    <>
      <div className="flex w-full bg-white">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex">
            <Link to={"/vendors/list"}>
              <div className="w-8 ">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-1">
              <h1 className=" text-xl hover:bg-orange-400xl px-2">
                Vendor Info{" "}
              </h1>
            </div>
          </div>
          <div className="flex space-x-10">
            <div className="w-1/3 space-y-3">
              <h2 className="font-semibold text-lg">Vendor Details</h2>
              <hr />
              <div className="">
                <div>
                  <label
                    htmlFor="Vendor-name"
                    className="text-sm px-1 font-semibold "
                  >
                    Vendor Name *
                  </label>
                  <input
                    type="text"
                    id="Vendor-name"
                    name="Vendorname"
                    placeholder="Enter your Vendor name"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editVendor.vendorname}
                    onChange={(e) => {
                      setEditVendor({
                        ...editVendor,
                        vendorname: toTitleCase(e.target.value),
                      });
                    }}
                  />
                   {editVendor.vendorname === "" ? (
                    <span className="text-red-600 text-xs">
                      Please enter vendor name
                    </span>
                  ) : null}
                </div>
                {/* <div>
                  <label
                    htmlFor="last-name"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Last name *
                  </label>
                  <input
                    id="last-name"
                    type="text"
                    name="lastname"
                    placeholder="Enter your last name"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editVendor.lastname}
                    onChange={(e) => {
                      setEditVendor({ ...editVendor, lastname: e.target.value });
                    }}
                  />
                </div> */}

                <div>
                  <label
                    htmlFor="address"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Address *
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Enter your address"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editVendor.address}
                    onChange={(e) => {
                      setEditVendor({ ...editVendor, address: e.target.value });
                    }}
                  />
                  {editVendor.address === "" ? (
                    <span className="text-red-600 text-xs">
                      Please enter address
                    </span>
                  ) : null}
                </div>

                <div>
                  <label
                    htmlFor="website"
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                    Website * <small>(https://website.com)</small>
                  </label>
                  <input
                    id="website"
                    type="text"
                    name="website"
                    placeholder="www.website.com"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editVendor?.website}
                    onChange={(e) => {
                      setEditVendor({ ...editVendor, website: e.target.value });
                    }}
                    onKeyUp={(e) => validateUrl(e.target.value)}
                  />
                  {websiteError && (
                    <span className="text-left text-red-600 text-sm">
                      Please enter a valid url
                    </span>
                  )}
                  {editVendor.website === "" ? (
                    <span className="text-red-600 text-xs">
                      Please enter website
                    </span>
                  ) : null}
                </div>
               
                <div>
                  <label
                    htmlFor="person-name"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Contact Person Name *
                  </label>
                  <input
                    id="person-name"
                    type="text"
                    name="personname"
                    placeholder="Enter your contact person name"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editVendor.contactpersonname}
                    // onChange={(e) => {
                    //   setEditVendor({
                    //     ...editVendor,
                    //     contactpersonname: toTitleCase(e.target.value),
                    //   });
                    // }}
                    onChange={(e) => {
                      const input = e.target.value;
                      const filteredInput = input.replace(/[^a-zA-Z\s]/g, ''); 
                      setEditVendor({ ...editVendor, contactpersonname: toTitleCase(filteredInput) });
                    }} 
                  />
                  {editVendor.contactpersonname === "" ? (
                    <span className="text-red-600 text-xs">
                      Please enter contact person name
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="w-1/3 pt-14">
              <div className="pb-2">
                <label
                  htmlFor="phone"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Contact Person Phone *
                </label>
                <input
                  type="text"
                  maxLength={10}
                  pattern="[0-9]{10}"
                  id="phone"
                  name="phone"
                  placeholder="Enter your contact person phone"
                  className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9"
                  value={editVendor.contactpersonphone}
                  onChange={(e) => {
                    setEditVendor({
                      ...editVendor,
                      contactpersonphone: e.target.value,
                    });
                  }}
                  onKeyUp={() => validatePhoneno(editVendor.contactpersonphone)}
                />
                {phoneFormatTest ? (
                  <span className="text-red-600 text-sm">
                    Please enter a valid 10 digit Number.
                  </span>
                ) : (
                  <></>
                )}
                {editVendor.contactpersonphone === "" ? (
                    <span className="text-red-600 text-xs">
                      Please enter contact person phone
                    </span>
                  ) : null}
              </div>
              <div>
                <label
                  htmlFor="alternate person-name"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Alternate Person Name
                </label>
                <input
                  id="alternate person-name"
                  type="text"
                  name="personname"
                  placeholder="Enter your alternate person name"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={editVendor.alternatepersonname}
                  // onChange={(e) => {
                  //   setEditVendor({
                  //     ...editVendor,
                  //     alternatepersonname: toTitleCase(e.target.value),
                  //   });
                  // }}
                  onChange={(e) => {
                    const input = e.target.value;
                    const filteredInput = input.replace(/[^a-zA-Z\s]/g, ''); 
                    setEditVendor({ ...editVendor, alternatepersonname: toTitleCase(filteredInput) });
                  }} 
                />
              </div>
              <div className="pb-2">
                <label
                  htmlFor="alternate phone"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Alternate Person Phone
                </label>
                <input
                  type="text"
                  maxLength={10}
                  pattern="[0-9]{10}"
                  id="alternate phone"
                  name="alternate phone"
                  placeholder="Enter your alternate person phone"
                  className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9"
                  value={editVendor.alternatepersonphone}
                  onChange={(e) => {
                    setEditVendor({
                      ...editVendor,
                      alternatepersonphone: e.target.value,
                    });
                  }}
                  onKeyUp={() =>
                    validatePhoneno1(editVendor.alternatepersonphone)
                  }
                />
                {phoneFormatTest1 ? (
                  <span className="text-red-600 text-sm">
                    Please enter a valid 10 digit Number.
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="technology"
                  className="text-sm px-1 font-semibold pb-1"
                >
                  Add Technologies
                </label>
                <Select
                  id="technology"
                  name="technology"
                  placeholder="Choose technology"
                  isMulti // Add the isMulti prop to allow multiple selections
                  options={technology
                    ?.filter((v) => v?.isActive)
                    ?.map((v) => ({
                      value: v?._id,
                      label: v?.technologyname,
                      id: v._id,
                    }))}
                    value={editVendor.technology?.map((loc) => {
                      const tech = technology.find(t => t._id === loc);
                      return {
                        value: loc,
                        label: tech?.technologyname || loc?.technologyname, // Add conditional check here
                        id: loc,
                      };
                    })}
                  // value={editVendor.technology?.map((loc) => ({
                  //   value: loc,
                  //     label: technology.find(t => t._id === loc)?.technologyname,
                  //     // label:technology.map((item)=> {return(item?.technology?._id)}),
                  //   //   label: loc.technologyname,
                  //   //   // label:technology.find((loc) => loc._id === locId)?.locnologyname || locId,
                  //     id: loc,
                  // }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value  
                    );
                    setEditVendor({ ...editVendor, technology: selectedValues });
                  }}
                />
              </div>
            </div>
            <div className="w-1/3 pt-14">
              <div>
                <label
                  htmlFor="email"
                  className="text-sm px-1 font-semibold pb-1 text-[#202223]"
                >
                  Email *
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  className={`w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9 ${
                    emailError ? "" : "mb-4"
                  } `}
                  value={editVendor.email}
                  onChange={(e) => {
                    setEditVendor({ ...editVendor, email: e.target.value });
                  }}
                  onKeyUp={() => validateEmail()}
                />
                {emailError && (
                  <span className="text-left text-red-600 text-sm ">
                    Please enter a valid Email
                  </span>
                )}
                {editVendor.email === "" ? (
                    <span className="text-red-600 text-xs">
                      Please enter your email
                    </span>
                  ) : null}
              </div>
              <div className="">
                <label
                  htmlFor="password"
                  className="text-sm px-1 font-semibold pb-1 relative block"
                >
                  Password *
                  <input
                    type={password === true ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Enter your password"
                    className="w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editVendor.password}
                    onChange={(e) => {
                      setEditVendor({
                        ...editVendor,
                        password: e.target.value,
                      });
                    }}
                    onKeyUp={() => validatePassword()}
                  />
                  {/* <span className="text-small text-gray-600">Password should be atleast 8-16 characters long,at least one uppercase letter,at least one lowercase letter,at least one digit,at least one special character from the set</span> */}
                  <span
                    className="absolute top-7 right-0 flex items-center pr-2 cursor-pointer"
                    onClick={() => setPassword(!password)}
                  >
                    {password === true ? (
                      <FaRegEye className="h-5 w-5 fill-theme-color" />
                    ) : (
                      <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                    )}
                  </span>
                </label>
              </div>

              {passwordError && (
                <small className="text-left text-red-600">Enter Password</small>
              )}
              <div className="pt-2">
                <label
                  htmlFor="nda file"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  NDA File
                </label>
                <input
                  type="file"
                  name="nda file"
                  placeholder="Enter your nda file"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  onChange={handleNdaFileChange}
                />
                {ndaError && (
                  <span className="text-red-600 text-xs">{ndaError}</span>
                )}
                {ndaFileName && (
                  <span className="text-green-600 text-xs">
                    Selected file: {ndaFileName}
                  </span>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="location"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Location
                </label>
                <Select
                  id="location"
                  name="location"
                  placeholder="Choose location"
                  isMulti // Add the isMulti prop to allow multiple selections
                  options={city
                    ?.filter((v) => v?.isActive)
                    ?.map((v) => ({
                      value: v?.cityname,
                      label: v?.cityname,
                    }))}
                  value={editVendor.location?.map((loc) => ({
                    value: loc,
                    label: loc,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setEditVendor({ ...editVendor, location: selectedValues });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="">
            <Link to="/vendors/list">
              <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>
            <button
              type="button"
              disabled={!buttonDisabled}
              className="bg-theme-color disabled:opacity-50 px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3"
              onClick={() => {
                handleUpdate();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateVendor;
