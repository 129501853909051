import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddFormLayout from "../Layout/AddFormLayout";
import { CustomDropDown, CustomInput, CustomLabel } from "../atoms/Utility";
import fetchUtil, { BASE_URL } from "../../utils/fetch.util";
import { ADD_JOB, GET_ALL_CITIES, GET_ALL_CLIENTS, GET_ALL_SUB_ADMIN, GET_ALL_TECHNOLOGIES, GET_JOB_BY_ID, UPDATE_JOB_BY_ID } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";
import { copyTextToClipboard, filteredData, toTitleCase } from "../../utils";
import Loader from "../Loader/Loader";
import { COPY_ICON } from "../Assets/icons/icons";

const initialState = {
  jobtitle: '',
  positiontype: '',
  assignedTo: '',
  worknature: '',
  rate: '',
  client: "",
  location: [],
  priority: '',
  positions: '',
  technology: '',
  totalExp: { years: "", months: "" },
  isSendEmail: false,
  budget: "",
  isSendEmailHotVendor:false
};
const positionTypeOptions = [
  { label: 'Full-time', value: 'Full-Time' },
  { label: 'Part-time', value: 'Part-Time' },
  { label: 'Contractual', value: 'Contractual' },
];
const AddJob = () => {
  const [count, setCount] = useState(0);
  const [jd, setJd] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const [state, setState] = useState(initialState);
  const [apiData, setApiData] = useState({
    subAdmin: [],
    client: [],
    technology: [],
    city: [],
  })

  const handleSubmit = () => {
    if (params.jobID) {
      fetchUtil.PUT(`${UPDATE_JOB_BY_ID}/${params.jobID}`, state)
        .then((response) => {
          console.log(response)
        })
        .catch(err => console.error(err));
    }
    else {
      fetchUtil.POST(ADD_JOB, state)
        .then(response => {
          console.log(response);
          toast.success(response.message);
          setTimeout(() => {
            navigate("/jobs/list");
          }, 1000);
        })
        .catch(err => console.error(err));
    }

  };

  const fetchData = async () => {
    try {
      const [assignees, clients, locations, technologies] = await Promise.all([
        fetchUtil.GET(GET_ALL_SUB_ADMIN),
        fetchUtil.GET(GET_ALL_CLIENTS),
        fetchUtil.GET(GET_ALL_CITIES),
        fetchUtil.GET(GET_ALL_TECHNOLOGIES),
      ]);
      setApiData((prev) => ({ ...prev, subAdmin: assignees.result, client: filteredData(clients.result), city: locations.result, technology: filteredData(technologies.result) }));
    } catch (error) {
      console.error(error);
    }
  }

  const setDataToState = (data) => {
    if (data) {
      for (let key in data) {
        setState((prev) => ({ ...prev, [key]: data[key] }));
      }
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (params.jobID) {
      setLoading(true);
      fetchUtil.GET(`${GET_JOB_BY_ID}/${params.jobID}`)
        .then((response) => {
          setDataToState(response.result);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    }
  }, [params.jobID]);

  const handleChange = (e) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const optionsFromAPI = (firstOption, options, value) => {
    return (
      <>
        {!!firstOption && <option value="" selected disabled> {firstOption} </option>}
        {options?.map((v) => {
          return (<option key={v?._id} value={v?._id}> {v[value]} </option>)
        })}
      </>
    )
  }

  const isFormValid = () => {
    return state?.jobtitle?.trim() !== "" && state?.assignedTo !== "" && state?.worknature !== "" && state.client !== "" && state?.priority !== "" && state.technology !== "";
  }
  const job_url = `https://user.getdeveloper.in/apply/${params.jobID}`;

  if (loading) {
    return <Loader />
  }
  else
    return (
      <>
        <AddFormLayout
          pageTitle={`Job info`}
          heading={'Details'}
          navigationPath={"/jobs/list"}
          loadingState={loading}
          buttonDisabled={!isFormValid()}
          onFormSubmit={handleSubmit}
        >
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 px-2">
              <CustomLabel htmlFor='title' required>Job title</CustomLabel>
              <CustomInput type='text' id='title' name='jobtitle'
                placeholder="Please enter job title" value={state?.jobtitle}
                onChange={(e) => {
                  setState((prev) => ({ ...prev, jobtitle: toTitleCase(e.target.value.replace(/[^a-zA-Z\s]/g, '')) }))
                }}
              />
              <div className="my-3">
                <CustomLabel htmlFor='positiontype'>Position Type</CustomLabel>
                <CustomDropDown
                  id="positiontype"
                  name="positiontype"
                  value={state?.positiontype}
                  onChange={handleChange}
                >
                  <option value={''} selected disabled>Choose position type</option>
                  {positionTypeOptions.map((opt) => <option value={opt.value}>{opt.label}</option>)}
                </CustomDropDown>
              </div>
              <div className="mb-3">
                <CustomLabel htmlFor='assignedTo' required>Assigned to</CustomLabel>
                <CustomDropDown
                  id="assignedTo"
                  name="assignedTo"
                  value={state?.assignedTo}
                  onChange={handleChange}
                >
                  {optionsFromAPI("Choose assignee", apiData?.subAdmin, 'name')}
                </CustomDropDown>
              </div>

              <CustomLabel htmlFor='assignedTo' required>Work nature</CustomLabel>
              <div className="flex justify-around mt-1">
                <div className="flex items-center justify-center">
                  <input type="radio" id="wfh" value="work from home" name='worknature' checked={state?.worknature === 'work from home'} onChange={handleChange} />
                  <label htmlFor="wfh" className="text-[13px] ml-[5px]">WFH</label>
                </div>
                <div className="flex items-center justify-center">
                  <input type="radio" id="wfo" value="work from office" name='worknature' checked={state?.worknature === 'work from office'} onChange={handleChange} />
                  <label htmlFor="wfo" className="text-[13px] ml-[5px]">WFO</label>
                </div>
                <div className="flex items-center justify-center">
                  <input type="radio" id="hybrid" value="hybrid" name='worknature' checked={state?.worknature === 'hybrid'} onChange={handleChange} />
                  <label htmlFor="hybrid" className="text-[13px] ml-[5px]">Hybrid</label>
                </div>
              </div>
              {!!params.jobID && state?.isActive &&
                <div className="mb-3" style={{"marginTop":"8px"}}>
                  <CustomLabel>Job URL</CustomLabel>
                  <div className="flex">
                    <div className="w-[80%]">
                      <input className="w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md rounded-r-none  focus-visible:border-[#fb923c] focus-visible:outline-none focus-visible:shadow-focusshadow placholder:text-slate-400 text-sm h-9" value={job_url} disabled />
                    </div>
                    <div className="w-[20%]">
                      <button className="bg-theme-color w-full text-center flex items-center justify-center text-white transition ease-in-out delay-150 duration-300 py-[.5rem] rounded-md rounded-l-none font-bold cursor-pointer" onClick={() => copyTextToClipboard(job_url)}><COPY_ICON className={'w-5 h-5'} /></button>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="w-full md:w-1/3 px-2">
              <CustomLabel htmlFor='rate'>Rate</CustomLabel>
              <CustomInput type='text' id='rate' name='rate'
                placeholder="Please enter rate" value={state?.rate}
                onChange={handleChange}
              />
              <div className="my-3">
                <CustomLabel htmlFor='client' required>Client</CustomLabel>
                <CustomDropDown
                  id="client"
                  name="client"
                  value={state?.client}
                  onChange={handleChange}
                >
                  {optionsFromAPI("Choose client", apiData?.client, 'name')}
                </CustomDropDown>
              </div>
              <div className="mb-3">
                <CustomLabel htmlFor='location'>Location</CustomLabel>
                <Select
                  className="text-sm"
                  id="location"
                  name="location"
                  placeholder="Choose location"
                  isMulti // Add the isMulti prop to allow multiple selections
                  options={apiData?.city
                    ?.filter((v) => v?.isActive)
                    ?.map((v) => ({
                      value: v?.cityname,
                      label: v?.cityname,
                    }))}
                  value={state.location.map((loc) => ({
                    value: loc,
                    label: loc,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setState({ ...state, location: selectedValues });
                  }}
                />
              </div>

              {/* <div className="my-3">
                <CustomLabel htmlFor='technology' required>Technology</CustomLabel>
                <Select 
                  id="technology"
                  name="technology"
                  placeholder="choose technology"
                  options={apiData?.technology
                    ?.map((v) => ({
                      value: v?._id, // Set _id as value
                      label: v?.technologyname,
                    }))}
                    value={state?.technology}
                    onChange={(selectedOption) => {
                      setState({ ...state, technology: selectedOption.value }); // Update with selected _id
                    }}
                  // value={state?.technology}
                  // onChange={handleChange}
                
                  // {optionsFromAPI("Choose technology", apiData?.technology, 'technologyname')}
               
                />
              </div> */}

              <CustomLabel required>Priority</CustomLabel>
              <div className="flex justify-around mt-1">
                <div className="flex items-center justify-center">
                  <input type="radio" id="low" value="low" name='priority' checked={state?.priority === 'low'} onChange={handleChange} />
                  <label htmlFor="low" className="text-[13px] ml-[5px]">Low</label>
                </div>
                <div className="flex items-center justify-center">
                  <input type="radio" id="medium" value="medium" name='priority' checked={state?.priority === 'medium'} onChange={handleChange} />
                  <label htmlFor="medium" className="text-[13px] ml-[5px]">Medium</label>
                </div>
                <div className="flex items-center justify-center">
                  <input type="radio" id="high" value="high" name='priority' checked={state?.priority === 'high'} onChange={handleChange} />
                  <label htmlFor="high" className="text-[13px] ml-[5px]">High</label>
                </div>
                <div className="flex items-center justify-center">
                  <input type="radio" id="hot" value="hot" name='priority' checked={state?.priority === 'hot'} onChange={handleChange} />
                  <label htmlFor="hot" className="text-[13px] ml-[5px]">Hot</label>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-2">
              <CustomLabel htmlFor='positions'>Position</CustomLabel>
              <CustomInput type='text' id='positions' name='jobtitle'
                placeholder="Enter the number of positions" value={state?.positions}
                onChange={(e) => {
                  setState((prev) => ({ ...prev, positions: toTitleCase(e.target.value) }))
                }}
              />
              <div className="my-3">
                <CustomLabel htmlFor='technology' required>Technology</CustomLabel>
                <CustomDropDown
                  id="technology"
                  name="technology"
                  isMulti
                  value={state?.technology}
                  onChange={handleChange}
                >
                  {optionsFromAPI("Choose technology", apiData?.technology, 'technologyname')}
                </CustomDropDown>
              </div>
              <div className="mb-3">
                <CustomLabel>Total experience</CustomLabel>
                <div className="flex w-full justify-between">
                  <div className="w-1/2 pr-2">
                    <CustomDropDown
                      id='total_year'
                      value={state?.totalExp?.years}
                      onChange={(e) => {
                        setState((prev) => ({ ...prev, totalExp: { ...prev.totalExp, years: e.target.value } }))
                      }}
                    >
                      <option value="" selected disabled>years</option>
                      {generateOptions(0, 30, "year")}
                    </CustomDropDown>
                  </div>
                  <div className="w-1/2 pl-2">
                    <CustomDropDown
                      id='total_month'
                      value={state?.totalExp?.months ?? 0}
                      onChange={(e) => {
                        setState((prev) => ({ ...prev, totalExp: { ...prev.totalExp, months: e.target.value } }))
                      }}
                    >
                      <option value="" selected disabled>months</option>
                     
                      {generateOptions(0, 11, "months")}
                    </CustomDropDown>

                  </div>



                </div>
                <div className="mb-3">

                </div>
                <CustomLabel htmlFor="positions">Budget</CustomLabel>
                <CustomInput
                  type="text"
                  id="positions"
                  name="jobtitle"
                  placeholder="Enter the Budget"
                  value={state?.budget}
                  onChange={(e) => {
                    setState((prev) => ({ ...prev, budget: e.target.value }));
                  }}

                />

              </div>

              {/* {!!params.jobID && state?.isActive &&
                <div className="mb-3">
                  <CustomLabel>Job URL</CustomLabel>
                  <div className="flex">
                    <div className="w-[80%]">
                      <input className="w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md rounded-r-none  focus-visible:border-[#fb923c] focus-visible:outline-none focus-visible:shadow-focusshadow placholder:text-slate-400 text-sm h-9" value={job_url} disabled />
                    </div>
                    <div className="w-[20%]">
                      <button className="bg-theme-color w-full text-center flex items-center justify-center text-white transition ease-in-out delay-150 duration-300 py-[.5rem] rounded-md rounded-l-none font-bold cursor-pointer" onClick={() => copyTextToClipboard(job_url)}><COPY_ICON className={'w-5 h-5'} /></button>
                    </div>
                  </div>
                </div>
              } */}
            </div>
          </div>
          <div className="w-full md:w-2/3 mt-3">
            <CustomLabel>Job Description</CustomLabel>
            <div className="addjd relative z-index-8 ">
              <ReactQuill
                theme="snow"
                value={state?.jobdescription}
                maxLength={4000}
                onChange={(value) => {
                  const words = value.trim().split(/\s+/);
                  if (words.length <= 400) {
                    // Limit the word count to 400 words
                    setState({ ...state, jobdescription: value });
                    setCount(words.length);
                  }
                }}
              />
            </div>
            <div className="flex justify-between item-center mt-[50px] ">
              <span className="text-xs text-gray-500 ">
                Provide some details about the Job.
              </span>
              <span className="bottom-2 text-xs">{count}/4000</span>
            </div>
          </div>
          <div className="">
          <div className="mt-[10px] flex items-start space-x-6 mb-10">
  <div className="flex items-center">
    <input
      type="checkbox"
      className="border-gray-300 rounded h-4 w-4 mr-2"
      value={state.isSendEmail}
      onChange={(e) => {
        setState({ ...state, isSendEmail: !state.isSendEmail });
      }}
    />
    <div className="flex flex-col">
      <h5 className="font-medium leading-none text-medium">
        Send email to all vendors
      </h5>
    </div>
  </div>

  <div className="flex items-center">
    <input
      type="checkbox"
      className="border-gray-300 rounded h-4 w-4 mr-2"
      value={state.isSendEmailHotVendor}
      onChange={(e) => {
        setState({ ...state, isSendEmailHotVendor: !state.isSendEmailHotVendor });
      }}
    />
    <div className="flex flex-col">
      <h5 className="font-medium leading-none text-medium">
        Send email to all Hot vendors
      </h5>
    </div>
  </div>
</div>

            
          </div>
          
        </AddFormLayout>
      </>
    );
};

export default AddJob;
