import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import JobList from './JobList';
import AddJob from './AddJob';
import UpdateJob from './UpdateJob';
import Candidates from '../Candidates/Candidates';
import LogsList from './LogsList';

const Jobs = () => {
  const initialStateOfFilters = {
    worknature: '',
    location: '',
    priority: '',
    technology: '',
    client: '',
    isActive: true,
  }
  const { pathname } = useLocation();
  const [filters, setFilters] = useState(initialStateOfFilters);

  if (pathname.includes('list')) {
    return <JobList filters={filters} setFilters={setFilters} initialStateOfFilters={initialStateOfFilters} />
  }

  if (pathname.includes('add') || pathname.includes('update')) {
    return <AddJob />
  }

  if (pathname.includes('logs')) {
    return <LogsList />
  }
}

export default Jobs;
