import { toast } from "react-toastify";

export const isEmptyObject = (objectName) => {
  return JSON.stringify(objectName) === "{}";
};

export const checkNameFormat = (name) => {
  const regex = /^[a-zA-Z][a-zA-Z. ]*$/;
  // /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
  return regex.test(name.trim());
};
export const isPhoneValid = (phone) => {
  const regexForIndianMobile = /^[6-9]\d{9}$/;
  return regexForIndianMobile.test(phone);
};

export const isEmailValid = (email) => {
  const regexForEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regexForEmail.test(email);
};

export const isURLVaid = (url) => {
  const regexForURL =
    /^(https?|http):\/\/[^\s/$?#]+(\.[^\s/$?#]+)*\.[^\s$]{2,}$/;
  return regexForURL.test(url);
};

export const isPANValid = (pan) => {
  const regexForPAN = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  return regexForPAN.test(pan);
};

export const isPFValid = (pf) => {
  const regexForPF =
    /^[A-Z]{2}[\s\/]?[A-Z]{3}[\s\/]?[0-9]{7}[\s\/]?[0-9]{3}[\s\/]?[0-9]{7}$/;
  return regexForPF.test(pf);
};

export function convertISODateToFormattedDate(isoDateString) {
  const date = new Date(isoDateString);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const formattedDate = (date) => {
  // Extract year, month, and day from the date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because month index starts from 0
  const day = String(date.getDate()).padStart(2, "0");

  // Format the date as "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const generateOptions = (start, end) => {
  const options = [];
  for (let i = start; i <= end; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  return options;
};

export function countNonEmptyValues(obj) {
  let count = 0;
  for (let key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== "" &&
      obj[key] !== false
    ) {
      count++;
    }
  }
  return count;
}

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const filteredData = (responseData) => {
  return responseData.filter((item) => item.isActive === true);
};

export const mergeYearAndMonth = (data) => {
  if (data) return `${data?.years}.${data?.months}`;
  return "0.0";
};

export const copyTextToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => toast.success("Text copied to clipboard"))
    .catch((err) => console.error("Failed to copy", err));
};

export const truncateText = (text) => {
  const maxLength = 10;
  if (text?.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};
