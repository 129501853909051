import React, { useContext, useEffect, useState } from "react";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import nodata from "../Assets/nodata.png";
import { useLocation, useParams } from "react-router-dom";
import CustomTable from "../atoms/CustomTable";
import { logsTableHeader } from "./logsTableHeader";
import { isURLVaid, mergeYearAndMonth } from "../../utils";
import fetchUtil, { BASE_URL } from "../../utils/fetch.util";
import { BackButton } from "../atoms/Utility";
import { JOB_CANDIDATES } from "../../constants/apiEndPoints";
import {DataContext} from '../../context/Datastate'
import { toast } from "react-toastify";
const LogsList = () => {
  const limit = 100;
  const firstResultCount = 1;

  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [candidatesData, setCandidatesData] = useState({
    pageCount: '',
    totalCount: '',
    list: []
  });

  const fetchCandidates = (apiEndPoint, query) => {
    console.log(apiEndPoint)
    setLoading(true);
    fetchUtil.GET(apiEndPoint, query)
      .then((response) => {
        setCandidatesData((prev) => ({
          ...prev,
          pageCount: response.pageCount,
          totalCount: response.totalCount,
          list: response.result,
        }))
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    console.log("mounted")
    fetchCandidates(`${JOB_CANDIDATES}/${params.id}`);
  }, []);

  const handlePageClick = (e) => {
    queryParams.page = e.selected + 1;
    fetchCandidates(`${JOB_CANDIDATES}/${params.id}`, queryParams);
  }

  let queryParams = {
    page: firstResultCount,
    limit: limit,
  }

  const renderURL = (link, name) => {
    if (link) return <a href={link} className="text-theme-color underline" target="_blank" rel="noopener noreferrer">{name}</a>
  }
  const renderFilePath = (filePath, name) => {
    return <a href={BASE_URL + filePath} className="text-theme-color underline" target="_blank" rel="noopener noreferrer">{name}</a>
  }
  const handleResume = (item) => {
    if (item.resumelink && isURLVaid(item.resumelink)) {
      return renderURL(item.resumelink, 'Resume');
    } else if (item.resume && (item.resume !== 'null' || item.resume !== 'undefined')) {
      return renderFilePath(item.resume, 'Resume');
    } else return;
  };
  let adminToken = JSON.parse(localStorage.getItem("adminToken"));
  const modifiedData = candidatesData.list.length > 0 && candidatesData.list?.map((item) => ({
    actions: <span
      className="text-theme-color hover:underline text-sm cursor-pointer"
      onClick={() => {
      //  DataContext.adminApplyJobs()
        console.log("++++++=",(item._id))
        const myHeaders = new Headers();
        myHeaders.append("token", adminToken);

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow"
        };
        fetch(`${BASE_URL}/job/jobApplyByAdmin?candidateId=${item._id}&jobId=${params.id}`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
           if(result.statusCode==200){
            toast.success(result.message);
           }
           else if (result.statusCode==208){
            console.log("gff")
            toast.error(result.message)
           }
           else{
            toast.error(result.message)
           }
          }
          )
          .catch((error) => console.error(error));
      }}
    >
      Apply Now
    </span>,
    ...item,
    primarytechnology: item.primarytechnology?.technologyname,
    relevantExp: mergeYearAndMonth(item?.relevantExp),
    resume: handleResume(item),
    linkedinprofile: renderURL(item.linkedinprofile, 'LinkedIn'),
    //actions

  }))
  return (
    <>
      <div className="flex w-full">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
          <div className="pt-3 ">
            <div className="flex items-center">
              <BackButton path={-1} />
              <h1 className="text-xl text-[#202223] font-semibold">
                Candidates
              </h1>
            </div>
          </div>
          {/*---------  list--------- */}
          <CustomTable data={modifiedData} columns={logsTableHeader} totalPageCount={candidatesData.pageCount} totalCount={candidatesData.totalCount} handlePageClick={handlePageClick} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default LogsList;
