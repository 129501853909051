import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import { DataContext } from "../../../context/Datastate";
import GlobalLoader from "../../GlobalLoader/GlobalLoader";
import DeleteModal from "../../DeleteModal/DeleteModal";
import Pagination from "../../Pagination/Pagination";
import Loader from "../../Loader/Loader";
import NoData from "../../NoData/NoData";
import EnableModal from "../../EnableDisableModal/EnableModal";

const ClientList = () => {
  const {
    setClient,
    client,
    loader,
    globalLoader,
    deleteBtn,
    setDeleteBtn,
    setDeleteBtnId,
    deleteBtnId,
    deleteClientApi,
    searchClientApi,
    clientPaginationApi,
    totalCount,
    pageCount,
    ClientEnableApi,
   
  } = useContext(DataContext);

  const limit = 100;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const [enableClientId, setEnableClientId] = useState("");
  const [enableModal, setEnableModal] = useState(false);
  const [clientItem, setClientItem] = useState({});

  useEffect(() => {
    clientPaginationApi(1, limit);
    // ClientEnableApi();
    // AllCountApi();
  }, []);

  const onKeyUpHandler = (e) => {
    const inputValue = e.target.value;

    // Update search input state
    setSearchInput(inputValue);

    // Check if the length of the input is at least 4 characters or it's empty
    if (inputValue.length >= 4 || inputValue.length === 0) {
      searchClientApi(inputValue);
    }
  };

  // const onKeyUpHandler = (e) => {
  //   searchClientApi(e.target.value);
  // };

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }
  
    const sortedData = [...client].sort((a, b) => {
      const valueA = String(a[property]); // Convert to string
      const valueB = String(b[property]); // Convert to string
  
      const comparison = valueA.localeCompare(valueB);
      return isAscending ? comparison : -comparison;
    });
  
    setClient(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };

  const handleClientDisabled = (item) => {
    ClientEnableApi(item?._id);
    setClientItem(item);
    setEnableModal(false);
  };
  return (
    <>
      {deleteBtn ? (
        <DeleteModal deleteApi={deleteClientApi} deleteId={deleteBtnId} />
      ) : (
        ""
      )}
      {enableModal ? (
        <EnableModal
          item={clientItem}
          setEnableModal={setEnableModal}
          apiFunction={handleClientDisabled}
        />
      ) : null}
      <div className="flex w-full">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
        <div className="pt-3 flex justify-between items-start">
            <h1 className="text-xl text-[#202223] font-semibold">
              Client List
            </h1>
            <div className=" flex justify-between space-x-2  items-start flex-wrap">
              <div className="flex gap-1">
                <Link to="/add_client">
                  <button className="border shadow-btnshd bg-theme-color text-white   px-4 py-2 rounded text-sm">
                    Add New Client
                  </button>
                </Link>

                {/* <button className="border border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                  Export XLS
                </button>
                <Link to="/import_speak">
                  <button className="border border-[#BABFC3]   px-4 py-2 text-[#202223] font-medium bg-white  rounded  text-sm">
                    Import XLS
                  </button>
                </Link> */}
              </div>
              <div className="float-right flex">
                <FaSearch className="search-icon relative top-[0.7rem] left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div>
          </div>
          {/*---------  list--------- */}
          {/* {globalLoader ? (
            <GlobalLoader />
          ) : ( */}
          {loader ? <Loader/> : client.length > 0 ? (
            <>
            <div className="h-[75vh] overflow-y-auto">
              <table className="w-full table-auto border-collapse bg-white shadow-md rounded-lg  ">
                <thead className="cursor-pointer sticky top-0 z-[4]">
                  <tr className="text-left bg-theme-light text-theme-color text-xs font-normal ">
                    <th className=" px-4 py-2 flex items-center relative">
                      <div className="flex items-center gap-4">
                        <p>Name</p>
                        <div className="">
                          <span className="">
                            <FaSortUp
                              className={`top-[30%]  ${getSortArrowClassName(
                                "name",
                                "asc"
                              )}`}
                              onClick={() => sortData("name")}
                            />
                            <FaSortDown
                              className={`mt-[-8px] ${getSortArrowClassName(
                                "name",
                                "desc"
                              )}`}
                              onClick={() => sortData("name")}
                            />
                          </span>
                        </div>
                      </div>
                    </th>
                    <th className=" px-4 py-2 relative">
                      <div className="flex items-center gap-4">
                        <p>Website
</p>
                        <div className="">
                          <span className="">
                            <FaSortUp
                              className={`${getSortArrowClassName(
                                "website",
                                "asc"
                              )}`}
                              onClick={() => sortData("website")}
                            />
                            <FaSortDown
                              className={`mt-[-8px] ${getSortArrowClassName(
                                "website",
                                "desc"
                              )}`}
                              onClick={() => sortData("website")}
                            />
                          </span>
                        </div>
                      </div>
                    </th>
                    <th className=" px-4 py-2 relative">
                      <div className="flex items-center gap-4">
                        <p>Contact Person Name</p>
                        <div className="">
                          <span className="">
                            <FaSortUp
                              className={`top-[30%]  ${getSortArrowClassName(
                                "contactpersonname",
                                "asc"
                              )}`}
                              onClick={() => sortData("contactpersonname")}
                            />
                            <FaSortDown
                              className={`mt-[-8px] ${getSortArrowClassName(
                                "contactpersonname",
                                "desc"
                              )}`}
                              onClick={() => sortData("contactpersonname")}
                            />
                          </span>
                        </div>
                      </div>
                    </th>
                    <th className=" px-4 py-2 relative">
                      <div className="flex items-center gap-4">
                        <p>Phone Number</p>
                        <div className="">
                          <span className="">
                            <FaSortUp
                              className={`top-[30%]  ${getSortArrowClassName(
                                "phonenumber",
                                "asc"
                              )}`}
                              onClick={() => sortData("phonenumber")}
                            />
                            <FaSortDown
                              className={`mt-[-8px] ${getSortArrowClassName(
                                "phonenumber",
                                "desc"
                              )}`}
                              onClick={() => sortData("phonenumber")}
                            />
                          </span>
                        </div>
                      </div>
                    </th>
                    <th className=" px-4 py-2 relative">
                      <div className="flex items-center gap-4">
                        <p>Email</p>
                        <div className="">
                          <span className="">
                            <FaSortUp
                              className={`${getSortArrowClassName(
                                "email",
                                "asc"
                              )}`}
                              onClick={() => sortData("email")}
                            />
                            <FaSortDown
                              className={`mt-[-8px] ${getSortArrowClassName(
                                "email",
                                "desc"
                              )}`}
                              onClick={() => sortData("email")}
                            />
                          </span>
                        </div>
                      </div>
                    </th>
                    <th className=" px-4 py-2 relative">
                      <div className="flex items-center gap-4">
                        <p>Address</p>
                        <div className="">
                          <span className="">
                            <FaSortUp
                              className={`${getSortArrowClassName(
                                "address",
                                "asc"
                              )}`}
                              onClick={() => sortData("address")}
                            />
                            <FaSortDown
                              className={`mt-[-8px] ${getSortArrowClassName(
                                "address",
                                "desc"
                              )}`}
                              onClick={() => sortData("address")}
                            />
                          </span>
                        </div>
                      </div>
                    </th>

                    <th className=" px-4 py-2 text-center rounded-tr-lg">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                  {client?.map((item) => (
                    <tr className="border-t text-left text-[14px] text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  cursor-pointer">
                      <td className="px-4 py-2">{item?.name}</td>
                      <td className="px-4 py-2">{item?.website}</td>
                      <td className="px-4 py-2">{item?.contactpersonname}</td>
                      <td className="px-4 py-2">{item?.phonenumber}</td>
                      <td className="px-4 py-2">{item?.email}</td>
                      <td className="px-4 py-2">{item?.address}</td>
                      <td className="px-4 py-2">
                        <div className="flex items-center  justify-center  gap-2 text-black ">
                          <Link to={`/update_client/${item?._id}`}>
                            <FiEdit2 className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] " />
                          </Link>
                          <FiTrash
                            className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                            onClick={() => {
                              setDeleteBtn(true);
                              setDeleteBtnId(item?._id);
                            }}
                          />
                          <span>
                            <label class="flex items-center cursor-pointer">
                              <div class="relative" title="Active">
                                <input
                                  type="checkbox"
                                  className="sr-only dot"
                                  checked={item?.isActive}
                                  onClick={() => {
                                    setClientItem(item);
                                    setEnableModal(true);
                                  }}
                                />
                                <div class="w-[24px] h-[11px] bg-gray-400 rounded-full shadow-inner"></div>
                                <div class="dot vend_act absolute w-[18px] h-[18px] bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                              </div>
                            </label>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="w-5/6 flex justify-between items-center absolute bottom-0 ">
            <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${
              pageCount === 1 ? totalCount : secondResultCount
            } of ${totalCount} results`}</span>
            <Pagination
              firstResultCount={firstResultCount}
              setFirstResultCount={setFirstResultCount}
              secondResultCount={secondResultCount}
              setSecondResultCount={setSecondResultCount}
              limit={limit}
              api={clientPaginationApi}
            />
          </div>
            </>

): null }
           
           
{(!loader && !client || client.length == 0) && <NoData/>}
          
        </div>
      </div>
    </>
  );
};

export default ClientList;
