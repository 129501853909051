import React, { useEffect, useRef, useState } from 'react'
// import { IMAGE_ICON } from './icons'
import { toast } from 'react-toastify';
import { UPLOAD_ICON } from '../Assets/icons/icons';

const FileDragAndDrop = ({ onUpload, supportedFiles, receivedFile }) => {
  const inputRef = useRef(null);
  const dndRef = useRef(null);

  const handleInputFile = (e) => {
    const files = e.target.files;
    if (files && files.length) {
      onUpload(files);
    }
  };
  const handleBoxClick = () => {
    inputRef.current.click();
  }

  useEffect(() => {
    const instance = dndRef.current;
    instance.addEventListener('dragover', handleDragOver);
    instance.addEventListener('drop', handleDrop);

    return () => {
      instance.removeEventListener('dragover', handleDragOver);
      instance.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      if (files[0].type.includes('pdf') || files[0].type.includes('document') || files[0].type.includes('docs') || files[0].type.includes('docx')) {
        onUpload(files);
      }
      else { toast.error("Please drop supported files only") }
    }
  };

  return (
    <>

      <div className="flex justify-center items-center mt-4">
        <div className="w-full flex flex-col">
          <div ref={dndRef} className="border border-gray-300 shadow-sm rounded-md text-sm h-[6rem] text-gray-400 cursor-pointer text-center"
            onClick={handleBoxClick}>
            <UPLOAD_ICON className={'mx-[auto] my-4'} />
            <p>allowed: .docx, .doc and .pdf</p>
            <input type='file' accept={supportedFiles} ref={inputRef} className='hidden' onChange={handleInputFile} />
          </div>

        </div>
      </div>

      {/* <div ref={dndRef} className='border-dotted border-2 border-[#A8A8A0] w-[50%] h-[240px] flex justify-center items-center cursor-pointer' onClick={handleBoxClick}>
        <input type='file' accept={supportedFiles} ref={inputRef} className='hidden' onChange={handleInputFile} />
        {
          !receivedFile &&
          <div className='flex flex-col justify-center items-center'>

            <p className='text-[14px] leading-[20px] mb-[12px]'>Click to upload or drag-drop here</p>
            <p className='text-[11px] leading-[16px]'>Supports {supportedFiles}</p>
          </div>
        }
      </div> */}
    </>
  )
}

export default React.memo(FileDragAndDrop)
