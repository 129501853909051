import React, { useContext, useEffect, useState,useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown, FaPencilAlt } from "react-icons/fa";
import { FiTrash, FiEdit2, FiEye } from "react-icons/fi";
import { DataContext } from "../../context/Datastate";
import Pagination from "../Pagination/Pagination";
import Loader from "../Loader/Loader";
import NoData from "../NoData/NoData";

const InhouseJobs = () => {
  const limit = 100;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [searchInput, setSearchInput] = useState("");
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const {
    loader,
    inHouseJob,
    inHouseJobPaginationApi,
    searchInHouseApi,
    setInHouseJob,
    pageCount,
    totalCount,
  } = useContext(DataContext);

  const onKeyUpHandler = (e) => {
    const inputValue = e.target.value;
    // Update search input state
    setSearchInput(inputValue);
    // Check if the length of the input is at least 4 characters or it's empty
    if (inputValue.length >= 4 || inputValue.length === 0) {
        searchInHouseApi(inputValue);
    }
  };

  const validateUrl = (link) => {
    const validUrlPattern =
      /^(https?|http):\/\/[^\s/$?#]+(\.[^\s/$?#]+)*\.[^\s$]{2,}$/;

    return validUrlPattern.test(link);
  };

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...inHouseJob].sort((a, b) => {
      const valueA = String(a[property]); // Convert to string
      const valueB = String(b[property]); // Convert to string

      const comparison = valueA.localeCompare(valueB);
      return isAscending ? comparison : -comparison;
    });

    setInHouseJob(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };

  useEffect(() => {
    inHouseJobPaginationApi(1, limit);
  }, []);

  return (
    <>
      <div className="flex w-full">
        <div className="w-full pl-[0.75rem] pr-[0.75rem] py-2 overflow-y-scroll h-screen space-y-4">
          <div className="pt-3 ">
            <div className="flex justify-between items-center">
              <h1 className="text-xl text-[#202223] font-semibold">
                In House Job List
              </h1>
              <div className="float-right flex ">
                <FaSearch className="search-icon relative top-[0.7rem] left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div>
            {/* <div className="flex space-x-2 justify-between pt-2 items-start ">
              <div className="float-right flex ">
                <FaSearch className="search-icon relative top-[0.7rem] left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div> */}
          </div>

          {/*--------- table list--------- */}
          {loader ? (
            <Loader />
          ) : inHouseJob.length > 0 ? (
            <>
              <div className="h-[80vh] relative overflow-y-auto">
                <table className="w-full table-auto relative bg-white border-collapse shadow-md rounded-lg  ">
                <thead className="cursor-pointer sticky top-0 z-[4]">
                    <tr className="text-left bg-theme-light text-theme-color text-xs font-normal ">
                      <th className=" px-4 py-2 flex items-center relative">
                        <div className="flex items-center gap-4">
                          <p>Job Title</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "jobtitle",
                                  "asc"
                                )}`}
                                onClick={() => sortData("jobtitle")}
                              />
                              <FaSortDown
                                className={`mt-[-8px] ${getSortArrowClassName(
                                  "jobtitle",
                                  "desc"
                                )}`}
                                onClick={() => sortData("jobtitle")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 py-2 relative">
                        <div className="flex items-center gap-4">
                          <p>Experience</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`${getSortArrowClassName(
                                  "totalExp",
                                  "asc"
                                )}`}
                                onClick={() => sortData("totalExp")}
                              />
                              <FaSortDown
                                className={`mt-[-8px] ${getSortArrowClassName(
                                  "totalExp",
                                  "desc"
                                )}`}
                                onClick={() => sortData("totalExp")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 py-2 relative">
                        <div className="flex items-center gap-4">
                          <p>Position</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "positions",
                                  "asc"
                                )}`}
                                onClick={() => sortData("positions")}
                              />
                              <FaSortDown
                                className={`mt-[-8px] ${getSortArrowClassName(
                                  "positions",
                                  "desc"
                                )}`}
                                onClick={() => sortData("positions")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 py-2 relative">
                        <div className="flex items-center gap-4">
                          <p>Work Nature</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "worknature",
                                  "asc"
                                )}`}
                                onClick={() => sortData("worknature")}
                              />
                              <FaSortDown
                                className={`mt-[-8px] ${getSortArrowClassName(
                                  "worknature",
                                  "desc"
                                )}`}
                                onClick={() => sortData("worknature")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 py-2 relative">
                        <div className="flex items-center gap-4">
                          <p>Location</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`${getSortArrowClassName(
                                  "location",
                                  "asc"
                                )}`}
                                onClick={() => sortData("location")}
                              />
                              <FaSortDown
                                className={`mt-[-8px] ${getSortArrowClassName(
                                  "location",
                                  "desc"
                                )}`}
                                onClick={() => sortData("location")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 py-2 relative">
                        <div className="flex items-center gap-4">
                          <p>Rate</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`${getSortArrowClassName(
                                  "rate",
                                  "asc"
                                )}`}
                                onClick={() => sortData("rate")}
                              />
                              <FaSortDown
                                className={`mt-[-8px] ${getSortArrowClassName(
                                  "rate",
                                  "desc"
                                )}`}
                                onClick={() => sortData("rate")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 py-2 relative">
                        <div className="flex items-center gap-4">
                          <p>Assigned To</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`${getSortArrowClassName(
                                  "assignedTo?.name",
                                  "asc"
                                )}`}
                                onClick={() => sortData("assignedTo?.name")}
                              />
                              <FaSortDown
                                className={`mt-[-8px] ${getSortArrowClassName(
                                  "assignedTo?.name",
                                  "desc"
                                )}`}
                                onClick={() => sortData("assignedTo?.name")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 py-2 relative">
                        <div className="flex items-center gap-4">
                          <p>Priority</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`${getSortArrowClassName(
                                  "priority",
                                  "asc"
                                )}`}
                                onClick={() => sortData("priority")}
                              />
                              <FaSortDown
                                className={`mt-[-8px] ${getSortArrowClassName(
                                  "priority",
                                  "desc"
                                )}`}
                                onClick={() => sortData("priority")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-2 py-2 relative">
                        <div className="flex items-center gap-4">
                          <p>Client</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`${getSortArrowClassName(
                                  "client?.name",
                                  "asc"
                                )}`}
                                onClick={() => sortData("client?.name")}
                              />
                              <FaSortDown
                                className={`mt-[-8px] ${getSortArrowClassName(
                                  "client?.name",
                                  "desc"
                                )}`}
                                onClick={() => sortData("client?.name")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      {/* <th className=" px-4 py-2 relative">
                        <div className="flex items-center gap-4">
                          <p>Logs</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`${getSortArrowClassName(
                                  "logs",
                                  "asc"
                                )}`}
                                onClick={() => sortData("logs")}
                              />
                              <FaSortDown
                                className={`mt-[-8px] ${getSortArrowClassName(
                                  "logs",
                                  "desc"
                                )}`}
                                onClick={() => sortData("logs")}
                              />
                            </span>
                          </div>
                        </div>
                      </th> */}

                      {/* <th className=" px-4 py-2 text-center rounded-tr-lg">
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB] overflow-y-scroll">
                    {inHouseJob?.map((item) => (
                      <tr className="border-t text-left text-[14px] text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  cursor-pointer">
                      <td className="px-4 py-2">{item?.jobtitle}</td>
                      {/* <td className="px-4 py-2">{item?.exp_min_max}</td> */}
                      <td className="px-8 py-2">
                        {item.hasOwnProperty("totalExp")
                          ? `${item?.totalExp?.years}. ${item?.totalExp?.months}`
                          : "0.0"}
                      </td>
                      <td className="px-8 py-2">{item?.positions}</td>
                      <td className="px-4 py-2">{item?.worknature}</td>
                      <td className="px-4 py-2">{`${item?.location}`}</td>
                      <td className="px-4 py-2">{item?.rate}</td>
                      <td className="px-4 py-2">{item?.assignedTo?.name}</td>
                      <td className="px-4 py-2">{item?.priority}</td>
                      <td className="pl-1 pr-2 py-2 ">{item?.client?.name}</td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-between items-center ">
                <span className="font-[150] text-sm mt-2 ">{`showing ${firstResultCount} to ${
                  pageCount === 1 ? totalCount : secondResultCount
                } of ${totalCount && totalCount } results`}</span>
                <Pagination
                  firstResultCount={firstResultCount}
                  setFirstResultCount={setFirstResultCount}
                  secondResultCount={secondResultCount}
                  setSecondResultCount={setSecondResultCount}
                  limit={limit}
                  api={inHouseJobPaginationApi}
                />
              </div>
            </>
          ) : null}
          {((!loader && !inHouseJob) ||
            inHouseJob.length == 0) && <NoData />}
          <div>
            <span className="font-[150px"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InhouseJobs;
